import http from '../request'



export const getList = (params={})=>http.get('/wechat/wxRestaurant/list',params)

export const getDetail = (id='')=>http.get('/wechat/wxRestaurant/'+id)



export const searchList = (name='') => http.get(`/wechat/wxRestaurant/list?name=${name}`)
export const indexList = (location='') => http.get(`/wechat/wxRestaurant/list?location=${location}`)