<template>
	<div id="app">
		<router-view></router-view>
	</div>
</template>

<script>
import {getLogin} from "@/common/api";
import config from "@/common/config";

export default {
	name: 'App',
	components: {},
	mounted() {

		if (!window.localStorage.getItem('code')) { // 如果缓存localStorage中没有微信openId，则需用code去后台获取
			// this.getCode()
		}
	},
	created() {
		window.onload = function () {
			document.addEventListener('touchstart', function (event) {
				if (event.touches.length > 1) {
					event.preventDefault()
				}
			})
			document.addEventListener('gesturestart', function (event) {
				event.preventDefault()
			})
		}
	},
	methods: {
		getCode() {
			this.code = ''
			const local = 'http://chambermp.bjyulinkeji.com/prod-api/gzh/weixin/user/login'; // 获取页面url ${encodeURIComponent(local)}
			const appid = 'wx6d91f97f04766009';
			this.code = this.getUrlCode().code // 截取code
			if (this.code == null || this.code === '') { // 如果没有code，则去请求
				window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
			} else {
				localStorage.setItem('code', this.code)
				getLogin({
					code: this.code,
					wxConfigId: config.wxConfigId
				}).then(res => {
					console.log(res)
					localStorage.setItem('Authorization', res.data.data)
				})
			}
		},
		getUrlCode() { // 截取url中的code方法
			const url = location.search;
			this.winUrl = url
			const theRequest = new Object();
			if (url.indexOf("?") != -1) {
				const str = url.substr(1);
				const strs = str.split("&");
				for (let i = 0; i < strs.length; i++) {
					theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
				}
			}
			return theRequest
		}
	}
}
</script>

<style>
#app {
	font-family: Avenir, Helvetica, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	text-align: center;
	color: #2c3e50;
}

.el-collapse-item__header {
	background-color: #F0F1F2 !important;
	padding: 0 0.26rem;
	font-size: 0.32rem !important;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600 !important;
	color: #333333 !important;
}

.el-icon-arrow-right {
	content: "\e790" !important;
}

.el-icon-arrow-right:before {
	content: "\e790" !important;
}

input:disabled {
	-webkit-text-fill-color: #000000 !important;
}
</style>
