<template>
	<div class="page">
		<div class="page-box">
			<el-collapse v-model="activeName" accordion>
				<el-collapse-item title="社团申请人企业资料" name="1" style="background: #F0F1F2;">
					<div class="form">
						<el-form ref="society" :model="society" :rules="societyRules" :hide-required-asterisk="true"
								 label-width="1.5rem">
							<el-form-item label="会员类型">
								<el-input disabled placeholder="会员"></el-input>
							</el-form-item>
							<el-form-item label="会员类别" prop="category">
								<el-select v-model="person.category" style="width: 5.45rem">
									<el-option label="独立法人" value="A"></el-option>
									<el-option label="合伙人" value="B"></el-option>
									<el-option label="在职人员" value="C"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="企业名称" prop="companyName">
								<el-input v-model="society.companyName" placeholder="请填写企业名称"></el-input>
							</el-form-item>
							<el-form-item label="通讯地址" prop="companyAddress">
								<el-input v-model="society.companyAddress" placeholder="请填写通讯地址"></el-input>
							</el-form-item>
							<el-form-item label="主营业务" prop="mainBusiness">
								<el-input v-model="society.mainBusiness" placeholder="请填写主营业务"></el-input>
							</el-form-item>
							<el-form-item label="企业法人" prop="legalPerson">
								<el-input v-model="society.legalPerson" placeholder="请填写企业法人"></el-input>
							</el-form-item>
							<el-form-item label="注册资金" prop="registeredCapital">
								<el-input v-model="society.registeredCapital" placeholder="请填写注册资金"></el-input>
							</el-form-item>
							<el-form-item label="邮政编码" prop="postalCode">
								<el-input v-model.number="society.postalCode" placeholder="请填写邮政编码"></el-input>
							</el-form-item>
							<el-form-item label="诚信记录">
								<div>
									<div>
										企业及本人在三年内是否有不诚信行为或违法记录
									</div>
									<div class="vote-radio">
										<el-radio v-model="society.isNoSincerity" :label="1">是</el-radio>
										<el-radio v-model="society.isNoSincerity" :label="0">否</el-radio>
									</div>
								</div>
							</el-form-item>
						</el-form>
					</div>
				</el-collapse-item>
				<el-collapse-item title="个人资料" name="2" style="margin-top: 0.4rem">
					<div class="form">
						<el-form ref="person" :model="person" :rules="personRules" label-width="1.5rem"
								 :hide-required-asterisk="true">
							<el-form-item label="姓名" prop="nickName">
								<el-input v-model="person.nickName" placeholder="请填写姓名"></el-input>
							</el-form-item>
							<el-form-item label="性别" prop="sex">
								<el-select v-model="person.sex" placeholder="请选择性别" style="width:5.45rem">
									<el-option label="男" value="1"></el-option>
									<el-option label="女" value="2"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="籍贯" prop="hometown">
								<el-select v-model="person.hometown" placeholder="请选择籍贯" style="width:5.45rem">
									<el-option label="贵州省/贵阳市" value="贵州省/贵阳市"></el-option>
									<el-option label="贵州省/六盘水市" value="贵州省/六盘水市"></el-option>
									<el-option label="贵州省/遵义市" value="贵州省/遵义市"></el-option>
									<el-option label="贵州省/安顺市" value="贵州省/安顺市"></el-option>
									<el-option label="贵州省/铜仁市" value="贵州省/铜仁市"></el-option>
									<el-option label="贵州省/毕节市" value="贵州省/毕节市"></el-option>
									<el-option label="贵州省/黔东南苗族侗族自治州"
											   value="贵州省/黔东南苗族侗族自治州"></el-option>
									<el-option label="贵州省/黔南布依族苗族自治州"
											   value="贵州省/黔南布依族苗族自治州"></el-option>
									<el-option label="贵州省/黔西南布依族苗族自治州"
											   value="贵州省/黔西南布依族苗族自治州"></el-option>
									<el-option label="其他" value="其他"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="生日" prop="birthday">
								<el-date-picker size="small"
												type="date"
												value-format="yyyy-MM-dd" placeholder="请选择日期"
												v-model="person.birthday"
												style="width:5.45rem"></el-date-picker>
							</el-form-item>
							<el-form-item label="证件号码" prop="idCard">
								<el-input v-model="person.idCard" placeholder="请填写证件号码"></el-input>
							</el-form-item>
							<el-form-item label="政治面貌" prop="politicalStatus">
								<el-select v-model="person.politicalStatus" placeholder="请选择政治面貌"
										   style="width:5.45rem">
									<el-option label="党员" value="党员"></el-option>
									<el-option label="预备党员" value="预备党员"></el-option>
									<el-option label="群众" value="群众"></el-option>
									<el-option label="其他" value="其他"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="工作单位" prop="employer">
								<el-input v-model="person.employer" placeholder="请填写工作单位"></el-input>
							</el-form-item>
							<el-form-item label="职务" prop="jobTitle">
								<el-input v-model="person.jobTitle" placeholder="请填写职务"></el-input>
							</el-form-item>
							<el-form-item label="城市" prop="city">
								<el-cascader
									style="width: 5.45rem"
									size="large"
									:options="options"
									v-model="citySelectedOptions"
									@change="cityHandleChange"
								>
								</el-cascader>
							</el-form-item>
						</el-form>
					</div>
				</el-collapse-item>
				<el-collapse-item title="通讯资料" name="3" style="margin-top: 0.4rem">
					<div class="form">
						<el-form ref="contact" :model="person" :rules="personRules" label-width="1.5rem"
								 :hide-required-asterisk="true">
							<el-form-item label="联系人" prop="nickName">
								<el-input v-model="person.nickName" placeholder="请填写联系人"></el-input>
							</el-form-item>
							<el-form-item label="手机" prop="phone">
								<el-input v-model="person.phone" placeholder="请填写手机" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label="传真号码">
								<el-input v-model="person.faxNumber" placeholder="选填"></el-input>
							</el-form-item>
							<el-form-item label="电子邮箱" prop="email">
								<el-input v-model="person.email" placeholder="请填写电子邮箱"></el-input>
							</el-form-item>
							<el-form-item label="其他方式">
								<el-input v-model="person.otherContact" placeholder="选填"></el-input>
							</el-form-item>
							<el-form-item label="备注">
								<el-input v-model="person.remarks" placeholder="选填" type="textarea"></el-input>
							</el-form-item>
						</el-form>
					</div>
				</el-collapse-item>
				<el-collapse-item title="证照资料" name="4" style="margin-top: 0.4rem">
					<div class="upload-box">
						<div class="enterprise">
							<el-upload
								class="avatar-uploader"
								:action="uploadImgUrl"
								:show-file-list="false"
								:on-error="uploadError"
								:on-success="handleUploadSuccess"
								:before-upload="beforeAvatarUpload"
								:on-preview="handlePictureCardPreview">
								<img v-if="companyImage" :src="companyImage" style="width: 2rem;height: 2rem">
								<img v-else :src="imageUp" style="width: 2rem;height: 2rem"/>
							</el-upload>
							<div class="enterprise-title">
								企业营业执照复印件
							</div>
						</div>
						<div class="line"></div>
						<div class="enterprise">
							<div class="idImage">
								<el-upload
									class="avatar-uploader"
									:action="uploadImgUrl"
									:show-file-list="false"
									:on-error="uploadError"
									:on-success="idCardFrontUploadSuccess"
									:before-upload="beforeAvatarUpload"
									:on-preview="handlePictureCardPreview">
									<img v-if="idCardImageFront" :src="idCardImageFront"
										 style="width: 2rem;height: 2rem">
									<img v-else :src="idImageF" style="width: 2rem;height: 2rem"/>
								</el-upload>

								<el-upload
									class="avatar-uploader"
									:action="uploadImgUrl"
									:show-file-list="false"
									:on-error="uploadError"
									:on-success="idCardBackUploadSuccess"
									:before-upload="beforeAvatarUpload"
									:on-preview="handlePictureCardPreview">
									<img v-if="idCardImageBack" :src="idCardImageBack" style="width: 2rem;height: 2rem">
									<img v-else :src="idImageB" style="width: 2rem;height: 2rem"/>
								</el-upload>
							</div>
							<div class="enterprise-title">
								申请人身份证复印件
							</div>
						</div>
						<div class="line"></div>
						<div class="enterprise">
							<el-upload
								class="avatar-uploader"
								:auto-upload="false"
								:on-change='changeUpload'
								:action="uploadImgUrl"
								:show-file-list="false"
								:on-success="photoUploadSuccess"
								:on-error="uploadError"
								:before-upload="beforeAvatarUpload"
								:on-preview="handlePictureCardPreview">
								<img v-if="photo" :src="photo" style="width: 2rem;height: 2rem">
								<img v-else :src="imageUp" style="width: 2rem;height: 2rem"/>
							</el-upload>
							<div class="enterprise-title">
								申请人一寸证件照
							</div>
						</div>
						<!-- vueCropper 剪裁图片实现-->
						<el-dialog title="图片剪裁" :visible.sync="cropperDialog" append-to-body>
							<div class="cropper-content">
								<div class="cropper" style="text-align:center">
									<vueCropper
										ref="cropper"
										:img="option.img"
										:outputSize="option.size"
										:outputType="option.outputType"
										:info="true"
										:full="option.full"
										:canMove="option.canMove"
										:canMoveBox="option.canMoveBox"
										:original="option.original"
										:autoCrop="option.autoCrop"
										:fixed="option.fixed"
										:fixedNumber="option.fixedNumber"
										:centerBox="option.centerBox"
										:infoTrue="option.infoTrue"
										:fixedBox="option.fixedBox"
									></vueCropper>
								</div>
							</div>
							<div slot="footer" class="dialog-footer">
								<el-button @click="cropperDialog = false">取 消</el-button>
								<el-button type="primary" @click="finish" :loading="loading">确认</el-button>
							</div>
						</el-dialog>
					</div>
				</el-collapse-item>
			</el-collapse>
			<div class="button-apply" @click="societyForm">
				<div class="button-text-apply">
					<div style="">
						下一步
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import uploadUrl from '../../../common/uploadUrl.js'
import {provinceAndCityData, CodeToText} from "element-china-area-data";
import {uploadImage} from "@/common/api";

export default {

	data() {
		const checkphone = (rule, value, callback) => {
			if (value == "") {
				callback(new Error("请输入手机号"));
			} else if (!this.isCellPhone(value)) {//引入methods中封装的检查手机格式的方法
				callback(new Error("请输入正确的手机号!"));
			} else {
				callback();
			}
		};
		const validateID = (rule, idcode, callback) => {
			// 加权因子
			var weight_factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
			// 校验码
			var check_code = ['1', '0', 'X', '9', '8', '7', '6', '5', '4', '3', '2'];

			var code = idcode + "";
			var last = idcode[17];//最后一位

			var seventeen = code.substring(0, 17);

			// ISO 7064:1983.MOD 11-2
			// 判断最后一位校验码是否正确
			var arr = seventeen.split("");
			var len = arr.length;
			var num = 0;
			for (var i = 0; i < len; i++) {
				num = num + arr[i] * weight_factor[i];
			}

			// 获取余数
			var resisue = num % 11;
			var last_no = check_code[resisue];

			// 格式的正则
			// 正则思路
			/*
			第一位不可能是0
			第二位到第六位可以是0-9
			第七位到第十位是年份，所以七八位为19或者20
			十一位和十二位是月份，这两位是01-12之间的数值
			十三位和十四位是日期，是从01-31之间的数值
			十五，十六，十七都是数字0-9
			十八位可能是数字0-9，也可能是X
			*/
			var idcard_patter = /^[1-9][0-9]{5}([1][9][0-9]{2}|[2][0][0|1][0-9])([0][1-9]|[1][0|1|2])([0][1-9]|[1|2][0-9]|[3][0|1])[0-9]{3}([0-9]|[X])$/;

			// 判断格式是否正确
			var format = idcard_patter.test(idcode);

			// 返回验证结果，校验码和格式同时正确才算是合法的身份证号码
			// return last === last_no && format ? true : false;
			if (last === last_no && format) {

				callback();
			} else {
				callback(new Error('请输入正确的身份证号码'));
			}
		};
		const checkemail = (rule, value, callback) => {
			if (!this.isEmail(value)) {
				callback(new Error('邮箱格式错误'))
			} else {
				callback()
			}
		};
		return {
			option: {
				img: '', // 裁剪图片的地址
				info: true, // 裁剪框的大小信息
				outputSize: 0.8, // 裁剪生成图片的质量
				outputType: 'png', // 裁剪生成图片的格式
				canScale: true, // 图片是否允许滚轮缩放
				autoCrop: true, // 是否默认生成截图框
				autoCropWidth: 200, // 默认生成截图框宽度
				autoCropHeight: 253, // 默认生成截图框高度
				fixedBox: true, // 固定截图框大小 不允许改变
				fixed: true, // 是否开启截图框宽高固定比例
				fixedNumber: [2, 2.53], // 截图框的宽高比例
				full: true, // 是否输出原图比例的截图
				canMoveBox: true, // 截图框能否拖动
				original: false, // 上传图片按照原始比例渲染
				centerBox: true, // 截图框是否被限制在图片里面
				infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
			},
			picsList: [],  //页面显示的数组
			// 防止重复提交
			cropperDialog: false,
			options: provinceAndCityData,
			citySelectedOptions: [],
			isNoSincerity: 0,
			character: 1,
			photo: '',
			idCardImageFront: '',
			idCardImageBack: '',
			imageUp: require('../../../static/upload/3.png'),
			idImageF: require('../../../static/upload/1.png'),
			idImageB: require('../../../static/upload/2.png'),
			dialogImageUrl: '',
			dialogVisible: false,
			companyImage: '',
			uploadImgUrl: uploadUrl.uploadUrl,
			activeName: '1',
			wxConfigId: 0,
			wxUserId: 0,
			society: {
				businessLicense: "",
				companyAddress: "",
				companyName: "",
				createTime: "",
				legalPerson: "",
				mainBusiness: "",
				postalCode: "",
				registeredCapital: "",
				isNoSincerity: 0,

			},
			person: {
				category: 'A',
				birthday: "",
				city: "",
				country: "",
				createTime: "",
				email: "",
				employer: "",
				faxNumber: "",
				headimgUrl: "",
				hometown: "",
				idCard: "",
				idCardPhoto1: "",
				idCardPhoto2: "",
				jobTitle: "",
				nickName: "",
				otherContact: "",
				phone: "",
				photo: "",
				politicalStatus: '',
				province: "",
				remarks: "",
				sex: "",
			},
			societyRules: {
				companyName: [
					{required: true, message: '请输入公司名称', trigger: 'blur'},
				],
				companyAddress: [
					{required: true, message: '请输入通讯地址', trigger: 'blur'}
				],
				mainBusiness: [
					{required: true, message: '请输入主营业务', trigger: 'blur'}
				],
				legalPerson: [
					{required: true, message: '请输入企业法人', trigger: 'blur'}
				],
				registeredCapital: [
					{required: true, message: '请输入注册资金', trigger: 'blur'}
				],
				postalCode: [
					{required: true, type: 'number', min: '6', max: '6', message: '邮政编码格式不正确', trigger: 'blur'}
				]
			},
			personRules: {
				nickName: [
					{required: true, message: '请输入姓名', trigger: 'blur'},
				],
				sex: [
					{required: true, message: '请选择性别', trigger: 'change'},
				],
				birthday: [
					{type: 'string', required: true, message: '请选择日期', trigger: 'change'}
				],
				hometown: [
					{required: true, message: '请输入籍贯', trigger: 'blur'},
				],
				idCard: [{required: true, validator: validateID, trigger: "blur"}],
				politicalStatus: [
					{required: true, message: '请输入政治面貌', trigger: 'blur'},
				],
				employer: [
					{required: true, message: '请输入工作单位', trigger: 'blur'},
				],
				jobTitle: [
					{required: true, message: '请输入职务', trigger: 'blur'},
				],
				city: [
					{required: true, message: '请输入城市', trigger: 'blur'},
				],
				phone: [{required: true, validator: checkphone, trigger: "blur"}],
				email: [{required: true, validator: checkemail, trigger: "blur"}],
			},
			loading: null,
		}
	},
	created() {
		this.initData()
	},

	methods: {
		// 上传按钮   限制图片大小
		changeUpload(file, fileList) {
			const isLt5M = file.size / 1024 / 1024 < 5
			if (!isLt5M) {
				this.$message.error('上传文件大小不能超过 5MB!')
				return false
			}
			this.fileinfo = file
			// 上传成功后将图片地址赋值给裁剪框显示图片
			this.$nextTick(() => {
				this.option.img = window.URL.createObjectURL(file.raw)
				this.cropperDialog = true
			})
		},
		// 点击裁剪，这一步是可以拿到处理后的地址
		finish() {
			this.$refs.cropper.getCropBlob((data) => {
				var fileName = 'goods' + this.fileinfo.uid
				this.loading = true
				let formdata = new FormData()
				formdata.append('file', data)
				uploadImage(formdata).then(res => {
					if (res.data.code === 200) {
						console.log(res.data.data, 'res.data')
						this.photo = res.data.data.url;
						this.person.photo = res.data.data.url;
						this.loading = false
						this.cropperDialog = false
						console.log(this.photo, this.person.photo, 'person.photo')
					} else {
						this.loading = false
						this.cropperDialog = false
					}
				}).catch(err => {
					this.loading = false
					this.cropperDialog = false
				}).finally(() => {
					this.loading = false
					this.cropperDialog = false
				})
			})
		},
		cityHandleChange() {
			let loc = "";
			for (let i = 0; i < this.citySelectedOptions.length; i++) {
				loc += CodeToText[this.citySelectedOptions[i]] + '/';
			}
			console.log(loc.slice(0, loc.length - 1))
			this.person.city = loc.slice(0, loc.length - 1)
		},
		//检查手机号
		isCellPhone(val) {
			if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
				return false;
			} else {
				return true;
			}
		},
		//检查邮箱
		isEmail(val) {
			return /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+((.[a-zA-Z0-9_-]{2,3}){1,2})$/.test(val)
		},
		initData() {
			let memberDataStr = localStorage.getItem('memberData');
			if (memberDataStr) {
				const memberData = JSON.parse(memberDataStr)
				this.character = memberData.character
				this.person = memberData.person
				this.society = memberData.society
				this.companyImage = memberData.society.businessLicense
				this.idCardImageFront = memberData.person.idCardPhoto1
				this.idCardImageBack = memberData.person.idCardPhoto2
				this.photo = memberData.person.photo
			}
		},
		societyForm() {
			this.$refs['society'].validate((valid) => {
				if (valid) {
					this.personForm();
				} else {
					this.$toast("社团申请人企业资料填写不完整", {
						durtaion: 200
					});
					return false;
				}
			});
		},
		personForm() {
			this.$refs['person'].validate((valid) => {
				if (valid) {
					this.contactForm();
				} else {
					this.$toast("个人资料填写不完整", {
						durtaion: 200
					});
					return false;
				}
			});
		},
		contactForm() {
			this.$refs['contact'].validate((valid) => {
				if (valid) {
					this.uploadForm();
				} else {
					this.$toast("通讯资料填写不完整", {
						durtaion: 200
					});
					return false;
				}
			});
		},
		uploadForm() {
			if (this.society.businessLicense == "" || !this.society.businessLicense) {
				this.$toast("请上传企业营业执照", {
					durtaion: 200
				});
				return;
			}
			if (this.person.idCardPhoto1 == "" || !this.person.idCardPhoto1) {
				this.$toast("请上传身份证正面", {
					durtaion: 200
				});
				return;
			}
			if (this.person.idCardPhoto2 == "" || !this.person.idCardPhoto2) {
				this.$toast("请上传身份证反面", {
					durtaion: 200
				});
				return;
			}
			if (this.person.photo == "" || !this.person.photo) {
				this.$toast("请上传证件照", {
					durtaion: 200
				});
				return;
			}
			this.onSubmit();
		},
		onSubmit() {
			// 将信息进行缓存
			const memberData = {
				character: this.character,
				person: this.person,
				society: this.society,
				wxConfigId: this.wxConfigId
			}
			//将信息填写进缓存
			localStorage.setItem('memberData', JSON.stringify(memberData))
			this.$router.push({path: '/stamp'})
		},
		beforeAvatarUpload(file) {
			const isIMG = file.type.indexOf("image/") !== -1;
			const isLtM = file.size / 1024 / 1024 < 3;
			if (!isIMG) {
				this.$toast("只能上传图片文件", {
					durtaion: 200
				});
			}
			if (!isLtM) {
				this.$toast("上传图片大小不能超过 3MB", {
					durtaion: 200
				});
			}
			// if (isLtM && isIMG) {
			// 	this.loading = this.$loading({
			// 		lock: true,
			// 		text: '文件上传中，请稍后',
			// 		spinner: 'el-icon-loading',
			// 		background: 'rgba(0, 0, 0, 0.7)'
			// 	});
			// }
			return isLtM && isIMG;
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
		handleUploadSuccess(res) {
			this.$emit("input", res.data.url);
			this.companyImage = res.data.url;
			this.society.businessLicense = res.data.url;
			this.loading.close();
		},
		idCardFrontUploadSuccess(res) {
			this.idCardImageFront = res.data.url;
			this.person.idCardPhoto1 = res.data.url;
			this.loading.close();
		},
		idCardBackUploadSuccess(res) {
			this.idCardImageBack = res.data.url;
			this.person.idCardPhoto2 = res.data.url;
			this.loading.close();
		},
		photoUploadSuccess(res) {

			this.loading.close();
		},
		uploadError() {
			this.loading.close();
		}
	}
}
</script>

<style scoped>
.cropper-content {

}

.cropper {
	width: auto;
	height: 300px;
}

.page {
	height: 100vh;
	overflow: scroll;
}

.form {
	padding: 0.26rem;
}

.enterprise {
	margin: 0.3rem 0;
	display: flex;
	align-items: center;
}

.enterprise-title {
	font-size: 0.28rem;
	margin-left: 0.3rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
}

.idImage {
	display: flex;
	flex-direction: column;
}

.line {
	height: 0.01rem;
	background-color: #E6E6E6;
}

.button-apply {
	margin: 0.5rem 0;
	bottom: 1rem;
	width: 100%;
	display: flex;
	justify-content: center;
}

.button-text-apply {
	width: 6.5rem;
	height: 1rem;
	background-color: #DDB06C;
	font-size: 0.36rem;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 600;
	color: #FFFFFF;
	border-radius: 1rem;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
}
</style>
