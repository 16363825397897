<template>
  <div class="container">
    <div class="background">
      <img src="../../../assets/instant/countdown/countDownBack.png" style="width: 100%;height: 13.34rem"/>
    </div>
    <div class="decorate">
      <img src="../../../assets/instant/countdown/decorate.png" style="width: 7.5rem;height: 4.63rem;"/>
    </div>
    <div class="logo">
      <img src="../../../assets/instant/countdown/logo.png" style="width: 0.81rem;height: 0.81rem;"/>
    </div>
    <div class="date">
      09.19
    </div>
    <div class="title">
      <img src="../../../assets/instant/countdown/title.png" style="width: 5.73rem;height: 4.16rem;"/>
      <div class="title_countdown">
        倒计时
      </div>
    </div>
    <div class="watch">
      <img src="../../../assets/instant/countdown/watch.png" style="width: 4.54rem;height: 2.89rem;"/>
    </div>
    <div class="count_down">
      <countdownday/>
    </div>
    <div class="submit" @click="submit">
      <img src="../../../assets/instant/countdown/submit.png" style="width: 2.97rem;height: 0.76rem;"/>
    </div>
    <div class="time">
      活动时间：2021年9月19下午14点30分
    </div>
    <div class="address">
      活动地址：北京市贵州大厦遵义厅
    </div>
  </div>
</template>

<script>
import countdownday from "@/pages/activity/components/countdownday";
import {getOnlineActivity} from "@/common/api";

export default {
  name: "reciprocal",
  components: {
    countdownday
  },
  data() {
    return {
      time: ''
    }
  },
  created(key, value) {
    this.initConfig()
    const id = '17'
    localStorage.setItem('activityId', id)
  },
  methods: {
    initConfig() {
      let wxId = this.getQueryString('wxConfigId')
      sessionStorage.setItem('wxConfigId', wxId)
      if (wxId) {
        this.wxConfigId = wxId
      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    submit() {
      this.$router.push({
        path: '/activityDetail?wxConfigId=6&id=17',
        // query: {
        //   wxConfigId: 6,
        //   id:7
        // }
      })
    }
  }
}
</script>

<style scoped>
.container {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: auto;
  background: #C01913;
}

.background {
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%
}

.logo {
  position: absolute;
  z-index: 99;
  width: 0.81rem;
  height: 0.81rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.38rem;
}

.decorate {
  position: absolute;
  z-index: 99;
  width: 7.5rem;
  height: 4.63rem;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0.38rem;
}

.date {
  font-size: 0.7rem;
  font-family: Impact;
  font-weight: 400;
  color: #FFFCA9;
  position: absolute;
  z-index: 99;
  top: 1.53rem;
}

.title {
  width: 5.73rem;
  height: 4.16rem;
  position: absolute;
  z-index: 99;
  top: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.title_countdown {
  background-image: url("../../../assets/instant/countdown/tag_back.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: absolute;
  z-index: 999;
  height: 0.58rem;
  width: 2.21rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.36rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFBA3;
  letter-spacing: 0.1rem;
  top: 3.3rem;
}

.watch {
  position: absolute;
  z-index: 99;
  top: 6.65rem;
}

.count_down {
  background-image: url("../../../assets/instant/countdown/tag_back2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  position: absolute;
  z-index: 99;
  font-size: 0.46rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFBA3;
  top: 9.78rem;
  width: 5.66rem;
  height: 0.83rem;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.05rem;
}

.submit {
  position: absolute;
  z-index: 99;
  top: 10.7rem;
  width: 2.97rem;
  height: 0.76rem;
}

.time {
  position: absolute;
  z-index: 99;
  top: 11.92rem;
  font-size: 0.36rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFBA3;
}

.address {
  position: absolute;
  z-index: 99;
  top: 12.5rem;
  font-size: 0.36rem;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFBA3;
}
</style>
