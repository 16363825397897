<template>
  <div class="leader-page">
    <div class="scrollTop" @click="toTop">
      <span class="el-icon-arrow-up" style="color: #666666;">
      </span>
      <span style="font-size: 0.2rem;color: #666666;">顶部</span>
    </div>
    <div class="search" id="scrollTop">
      <el-input
          @input="getSearchList"
          placeholder="请输入关键词搜索"
          v-model="queryTitle">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
        <i slot="suffix" class=""></i>
      </el-input>
    </div>
    <div class="leader-list-box">
      <div class="leader-list-item">
        <div class="leader-item" v-for="(item,index) in leaderList" :key="index" @click="jumpDetail(index)">
          <div class="leader-item-user-info">
            <div class="leader-avatar">
              <el-image style="border-radius:0.04rem;width:1.06rem;height:1.06rem" :src="item.photo" lazy/>
            </div>
            <div class="leader-user">
              <div class="leader-user-info">
                <div class="leader-name">
                  <div style="min-width: 1rem;">{{ item.name }}</div>
                </div>
                 <div class="leader-identity">
                <el-tag style="margin-left:0.05rem;" v-for="(tag, inx) in getTags(item)" :key="inx" bg-color="#DDB06C"
                        mode="dark" size="small" type="warning">{{ tag }}
                </el-tag>
                 </div>
              </div>
              <div class="leader-company">
                <div class="leader-company-info">
                  <div class="leader-company-name">
                    {{ item.company }}
                  </div>
                  <div class="leader-company-position">
                    {{ item.companyPost }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div class="empty" v-else>-->
      <!--        暂无相关数据-->
      <!--      </div>-->
    </div>
  </div>
</template>

<script>
import {getLeaderList} from "@/common/api";
export default {
  data() {
    return {
      leaderList: [],
      appid: '',
      wxConfigId: '',
      pageSize: 1000,
      pageNum: 1,
      total: '',
      listLength: 0,
      searchMemberList: '',
      queryTitle: ''
    }
  },
  created() {
    this.initConfig();
  },
  computed: {
    getTags() {
      return (item) => {
        let arr = []
        if (item.post && item.post.indexOf(",") !== -1) {
          return item.post.split(",")
        } else {
          arr.push(item.post)
        }
        return arr
      }
    }
  },
  methods: {
    // 跳到顶端
    toTop() {
      window.location.href = "#scrollTop"
    },
    initConfig() {
      const wxId = this.getQueryString('wxConfigId')
      if (wxId) {
        this.wxConfigId = wxId
        this.getLeaderList()
      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    infinite() {
      setTimeout(() => {
        if (this.listLength >= this.total) {
          this.$refs.my_scroller.noDataText = "没有更多数据了"//更改上拉加载的文字
          this.$refs.my_scroller.finishInfinite(true);
        } else {
          this.pageNum = this.pageNum + 1;
          this.getLeaderList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 1500)
    },
    getSearchList() {
      this.leaderList = []
      this.pageSize = 1000
      this.pageNum = 1
      this.total = 0
      this.listLength = 0
      this.getLeaderList()
    },
    getLeaderList() {
      getLeaderList({
        wxConfigId: this.wxConfigId,
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        queryTitle: this.queryTitle
      }).then(res => {
        if (res.data.code === 200) {
          this.leaderList = this.leaderList.concat(res.data.rows);
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;
        }
      })
    },
    jumpDetail(index) {
      this.$router.push({
        path: '/leaderDetail', query: {
          leaderDetail: this.leaderList[index],
        }
      })
    }
  }
}
</script>

<style scoped>
.scrollTop {
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  border: 0.02rem solid #666666;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: #FFFFFF;
  font-size: 0.3rem;
}

.leader-page {
  height: 100vh;
  overflow: scroll;
}

.leader-list-box {
  padding: 0.32rem;
  display: flex;
}

.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}

.leader-list-item {
  width: 100%;
}

.leader-item {
  padding: 0.2rem 0 0 0;
  border-bottom: 0.01rem solid #E6E4E1;;
}

.leader-item-user-info {
  margin-left: 0.3rem;
  display: flex;
}

.leader-avatar {
  padding: 0.1rem;
}

.leader-user-info {
  display: flex;
  padding: 0.1rem;
}

.leader-name {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #333333;
}

.leader-identity {
  display: flex;
  flex-wrap: wrap;
}

.leader-company-info {
  display: flex;
  flex-wrap: wrap;
}

.leader-company-name {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 0.1rem;
}

.leader-company-position {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 0.1rem;
}

.line {
  height: 0.01rem;
  width: 100%;
  background-color: #E6E4E1;
}

.search {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  display: flex;
  align-items: center;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}

/deep/ .el-input__inner { /*或者 .s2>>>.el-input__inner  */
  border: 0;
  background-color: #F0F0F0;
  border-radius: 1.35rem; /*输入框圆角值*/
}

/deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus {
  border: 0;
  outline: 0;
}

/deep/ .el-avatar > img {
  display: inline !important;
}

</style>
