<template>
  <div class="issue-page">
    <div class="title">
      <div style="margin-left: 0.26rem;">
        <div>供需信息</div>
      </div>
    </div>
    <div class="form-box">

      <el-form ref="form" :model="form" :rules="rules" label-width="80px" :hide-required-asterisk="true">
        <el-form-item label="供需类型" prop="type">
          <el-select v-model="form.type" placeholder="请选择" style="width: 5.5rem">
            <el-option label="供" value="0"></el-option>
            <el-option label="需" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="企业名称" prop="companyName">
          <el-input v-model="form.companyName" placeholder="请填写企业名称"></el-input>
        </el-form-item>
        <el-form-item label="标题" prop="title">
          <el-input v-model="form.title" placeholder="请填写标题"></el-input>
        </el-form-item>
        <div style="display: flex; align-items: center;margin-left: 0.2rem">
          <div>
            <div style="font-size: 0.25rem">
              封面上传
            </div>
          </div>
          <div class="license">
            <div>
              <el-upload
                  class="avatar-uploader"
                  :action="uploadImgUrl"
                  :on-change='changeUpload'
                  :show-file-list="false"
                  :auto-upload="false"
                  :on-error="uploadError"
                  :on-success="bannerSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-preview="handlePictureCardPreview">
                <img v-if="bannerImageUrl" :src="bannerImageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
              <!-- vueCropper 剪裁图片实现-->
              <el-dialog title="图片剪裁" :visible.sync="cropperDialog" append-to-body>
                <div class="cropper-content">
                  <div class="cropper" style="text-align:center">
                    <vueCropper
                        ref="cropper"
                        :img="option.img"
                        :outputSize="option.size"
                        :outputType="option.outputType"
                        :info="true"
                        :full="option.full"
                        :canMove="option.canMove"
                        :canMoveBox="option.canMoveBox"
                        :original="option.original"
                        :autoCrop="option.autoCrop"
                        :fixed="option.fixed"
                        :fixedNumber="option.fixedNumber"
                        :centerBox="option.centerBox"
                        :infoTrue="option.infoTrue"
                        :fixedBox="option.fixedBox"
                    ></vueCropper>
                  </div>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button @click="dialogVisible = false">取 消</el-button>
                  <el-button type="primary" @click="finish" :loading="loading">确认</el-button>
                </div>
              </el-dialog>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
          </div>
        </div>
        <div style="display: flex; align-items: center;margin-left: 0.2rem">
          <div>
            <div style="font-size: 0.25rem">
              图片上传
            </div>
          </div>
          <div class="license">
            <div>
              <el-upload
                  :action=uploadImgUrl
                  :limit="10"
                  :on-exceed="uploadMax"
                  list-type="picture-card"
                  :on-success="uploadSuccess"
                  :on-error="uploadError"
                  :on-preview="handlePictureCardPreview"
                  :before-upload="beforeAvatarUpload"
                  :on-remove="handleRemove">
                <i class="el-icon-plus" ></i>
              </el-upload>
              <el-dialog :visible.sync="dialogVisible">
                <img width="100%" :src="dialogImageUrl" alt="">
              </el-dialog>
            </div>
            <div>
              <div>（支持多张上传）</div>
            </div>
          </div>
        </div>
        <el-form-item label="详细描述" prop="description">
          <el-input v-model="form.description" type="textarea" placeholder="请填写详细描述"></el-input>
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="form.phone" placeholder="请填写联系方式" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="企业地址" prop="address">
          <el-input v-model="form.address" placeholder="请填写企业地址"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" type="textarea" placeholder="选填"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-issue">
      <div class="submit-button-issue" @click="submitForm">
        <div>提交发布</div>
      </div>
    </div>
    <div class="tips">
      <div>说明：提交发布后经过商会审核通过的会显示在供需资源列表〜</div>
    </div>
  </div>
</template>

<script>
import uploadUrl from '../../../common/uploadUrl.js'
import {getDemand, uploadImage} from "@/common/api";
import config from "@/common/config";
export default {

  data() {
    const checkphone = (rule, value, callback) => {
      if (value == "") {
        callback(new Error("请输入手机号"));
      } else if (!this.isCellPhone(value)) {//引入methods中封装的检查手机格式的方法
        callback(new Error("请输入正确的手机号!"));
      } else {
        callback();
      }
    };
    return {
      option: {
        img: '', // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 0.8, // 裁剪生成图片的质量
        outputType: 'png', // 裁剪生成图片的格式
        canScale: true, // 图片是否允许滚轮缩放
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 414, // 默认生成截图框宽度
        autoCropHeight: 138, // 默认生成截图框高度
        fixedBox: true, // 固定截图框大小 不允许改变
        fixed: true, // 是否开启截图框宽高固定比例
        fixedNumber: [4.14, 1.38], // 截图框的宽高比例
        full: true, // 是否输出原图比例的截图
        canMoveBox: true, // 截图框能否拖动
        original: false, // 上传图片按照原始比例渲染
        centerBox: true, // 截图框是否被限制在图片里面
        infoTrue: true // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      picsList: [],  //页面显示的数组
      // 防止重复提交
      cropperDialog: false,
      imageUp: require('../../../static/upload/3.png'),
      bannerImageUrl:'',
      uploadImgUrl:uploadUrl.uploadUrl,
      dialogImageUrl: '',
      dialogVisible: false,
      form: {
        type:'',
        companyName:'',
        description:'',
        phone:'',
        address:'',
        remark:'',
        title:''
      },
      imageUrl: '',
      imageNum:0,
      wxConfigId:'',
      wxUserId:'',
      rules:{
        type: [
          { required: true, message: '请选择供需类型', trigger: 'change' },
        ],
        companyName:[
          { required: true, message: '请填写公司名称', trigger: 'blur' },
        ],
        title:[
          { required: true, message: '请填写标题', trigger: 'blur' },
        ],
        description:[
          { required: true, message: '请填写详细描述', trigger: 'blur' },
        ],
        phone: [{required: true, validator: checkphone, trigger: "blur"}],
        address:[
          { required: true, message: '请填写企业地址', trigger: 'blur' },
        ]
      },
      loading: false
    }
  },
  created() {
  },
  methods: {
    // 上传按钮   限制图片大小
    changeUpload(file, fileList) {
      const isLt5M = file.size / 1024 / 1024 < 5
      if (!isLt5M) {
        this.$message.error('上传文件大小不能超过 5MB!')
        return false
      }
      this.fileinfo = file
      // 上传成功后将图片地址赋值给裁剪框显示图片
      this.$nextTick(() => {
        this.option.img = window.URL.createObjectURL(file.raw)
        this.cropperDialog = true
      })
    },
    // 点击裁剪，这一步是可以拿到处理后的地址
    finish() {
      this.$refs.cropper.getCropBlob((data) => {
        var fileName = 'goods' + this.fileinfo.uid
        this.loading = true
        console.log(data, 'data')
        console.log(fileName, 'fileName ')
        let formdata = new FormData()
        formdata.append('file', data)
        uploadImage(formdata).then(res => {
          if (res.data.code === 200) {
            console.log(res.data.data, 'res.data')
            this.bannerImageUrl = res.data.data.url
            this.cropperDialog = false
          }
        })
      })
    },
    //检查手机号
    isCellPhone(val) {
      if (!/^1(3|4|5|6|7|8)\d{9}$/.test(val)) {
        return false;
      } else {
        return true;
      }
    },
    onSubmit(){
      if(this.imageUrl=='' || !this.imageUrl){
        this.$toast("请上传图片",{
          durtaion: 200
        })
        return;
      }
      getDemand({
        address: this.form.address,
        companyName: this.form.companyName,
        description: this.form.description,
        image: this.imageUrl,
        phone: this.form.phone,
        remake: this.form.remark,
        type: this.form.type,
        title: this.form.title,
        wxConfigId: config.wxConfigId,
        wxUserId:0,
        banner:this.bannerImageUrl
      }).then(res=>{
        if(res.data.code == 200){
          this.$router.push({path:'/issueSuccess'})
        }else {
          this.$toast(res.data.msg||"操作失败",{
            durtaion: 200
          })
        }
      })
    },
    beforeAvatarUpload(file) {
      const isIMG = file.type.indexOf("image/") !== -1;
      const isLtM = file.size / 1024 / 1024 < 3;
      if (!isIMG) {
        this.$toast("只能上传图片文件", {
          durtaion: 200
        });
      }
      if (!isLtM) {
        this.$toast("上传图片大小不能超过 3MB", {
          durtaion: 200
        });
      }
      if (isLtM && isIMG) {
        this.loading = this.$loading({
            lock: true,
            text: '文件上传中，请稍后',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
      }
      return isLtM && isIMG;
    },
    uploadMax(){
      this.$toast("最多只能上传10张图片", {
        durtaion: 200
      });
    },
    submitForm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          this.onSubmit()
        } else {
          return false;
        }
      });
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    uploadSuccess(res) {
      this.imageNum++;
      if (this.imageNum == 1) {
        this.imageUrl = res.data.url
      } else {
        this.imageUrl = this.imageUrl + ',' + res.data.url
      }
      this.loading.close();
    },
    bannerSuccess(res){
      this.loading.close();
    },
    uploadError(){
      this.loading.close();
    }
  }
}
</script>
<style scoped>
.cropper {
  width: auto;
  height: 300px;
}
.issue-page {
  height: 100vh;
  overflow: scroll;
}
/deep/ .el-upload-list--picture-card {
  margin: 0;
  display: grid !important;
  vertical-align: top;
}
.title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 0.78rem;
  background: #F0F1F2;
  align-items: center;
  margin-top: 0.32rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #333333;
}

.form-box {
  padding: 0.26rem;
}

.license {
  display: flex;
  align-items: center;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding: 0.3rem 0 0.3rem 0.4rem;
}

.submit-issue {
  width: 100%;
  display: flex;
  justify-content: center;
  letter-spacing: 0.1rem;
}

.submit-button-issue {
  width: 6.46rem;
  height: 0.98rem;
  background: #DDB06C;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #FFFFFF;
}

.tips {
  text-align: center;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 0.1rem;
  padding-bottom: 0.5rem;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 1.5rem;
  height: 1.5rem;
  line-height: 1.5rem;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
