<template>
	<div id="app">
		<van-nav-bar :title="title" left-arrow v-if="title!=='搜索'">
			<template #left>
        <span v-if="title !=='餐饮版图' ">
          <van-icon name="arrow-left" @click="goUp"/>
        </span>

				<span v-else>
          <van-icon name="location-o" size="18"/><span>{{ address }}</span>
        </span>

			</template>
			<template #right>
				<van-icon name="apps-o" @click="goList" v-if="title==='餐饮版图'"/>
			</template>
		</van-nav-bar>
		<router-view/>
	</div>
</template>
<script>

export default {
	data() {
		return {}
	},
	methods: {
		goUp() {
			this.$router.go(-1)
		},
		goList() {
			this.$router.push({
				path: '/diningList',
			})
		},

	},
	created() {

	},
	mounted() {

	},
	components: {},
	computed: {
		title() {
			return this.$store.state.title
		},
		address() {
			return this.$store.state.address
		},
	},
	watch: {},
}
</script>
<style>
#app {
	font-size: 16px;
}
</style>
