<template>
 <div >
  <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
  <van-swipe-item v-for="item in bgimgList" :key="item.id"> -->
    <img class="img" :src="bgimgList" alt="">
  <!-- </van-swipe-item> -->

<!-- </van-swipe> -->
<div class="info">
  <div class="business">
    营业时间：   {{shopInfo.businessHours}}
  </div>
  <div class="phone">
    联系电话：    {{shopInfo.phone}}
  </div>
  <div class="address">
    地址：   {{shopInfo.address}}
  </div>
  <div class="discount">
    优惠：  {{shopInfo.discount}}
  </div>
</div>
<div class="list">
  <div class="item" v-for="item in domainsList"
  :key="item.id">
    <div class="left">
      <img :src="item.logo" alt="">
    </div>
    <div class="right">
      <div class="title_header">
        {{item.name}}
      </div>
      <div class="price">
        <span>￥</span>
        <span>{{item.price}}</span>
      </div>
    </div>
  </div>
</div>
 </div>
</template>

<script>
  import {getDetail} from '@/common/mapApi/data'
export default {
 data () {
  return {
    id:this.$route.query.id,
    bgimgList:'',
    shopInfo:{},
    domainsList:[]
  }
 },
 methods: {

 },
 created () {
  this.$store.commit('upData',{type:'title',value:'餐厅详情'})
  getDetail(this.id).then(res=>{
    console.log(res);
    this.bgimgList=res.data.data.logo
    this.shopInfo=res.data.data
    this.domainsList=res.data.data.domains
  })
 },
 mounted () {

 },
 components: {

 },
 computed: {

 },
 watch: {

 },
}
</script>

<style  scoped>
  .img{
    width: 100%;
  }

  .info{
    width: 100%;
    height: 120px;
    padding: 5px 10px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
  }

  .info >div{
    font-size: 14px;
    color: #dfdcdf;

  }

  .info .discount{
    height: 50px;
    line-height: 50px;
    color:#fc833d;

  }

  .item{
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .item .left{
    width: 35%;
    height: 100%;
    padding: 10px;
  }
  .item .left img{
    width: 100%;
    height: 100%;
    border-radius: 5px
  }
  .right{
    width: 60%;
    height: 100%;
    padding: 20px 10px;
  }
  .right .title_header{
    width: 100%;
    font-size: 14px;
  }
  .right .price{
    color: red;

  }
  .right .price :nth-child(1){
    font-size: 4px
  }
  .right .price :nth-child(2){
    font-weight: 700;
  }
</style>
