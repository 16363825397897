<template>
  <div class="audit-page">
    <div class="audit-box">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="个人资料" name="2">
          <div class="form">
            <el-form ref="form" :model="wxUserInfo" label-width="1.5rem">
              <el-form-item label="姓名">
                <el-input disabled v-model="wxUserInfo.nickName" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-input disabled v-model="wxUserInfo.sex" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="籍贯">
                <el-input disabled v-model="wxUserInfo.hometown" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="证件号码">
                <el-input disabled v-model="wxUserInfo.idCard" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="政治面貌">
                <el-input disabled v-model="wxUserInfo.politicalStatus" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="工作单位">
                <el-input disabled v-model="wxUserInfo.employer" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="职务">
                <el-input disabled v-model="wxUserInfo.jobTitle" placeholder="未填写"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="社团申请人企业资料" name="1" style="margin-top: 0.4rem">
          <div class="form">
            <el-form ref="form" :model="company" label-width="1.5rem">
              <el-form-item label="会员类型">
                <el-input disabled value="会员"></el-input>
                <!-- <el-select disabled v-model="role" style="width: 5.5rem">
                  <el-option label="会员" value="1"></el-option>
                </el-select> -->
              </el-form-item>
              <el-form-item label="会员类别">
                <el-select v-model="wxUserInfo.category" disabled style="width: 5.45rem">
                  <el-option label="独立法人" value="A"></el-option>
                  <el-option label="合伙人" value="B"></el-option>
                  <el-option label="在职人员" value="C"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="企业名称">
                <el-input disabled v-model="company.companyName"></el-input>
              </el-form-item>
              <el-form-item label="通讯地址">
                <el-input disabled v-model="company.companyAddress"></el-input>
              </el-form-item>
              <el-form-item label="主营业务">
                <el-input disabled v-model="company.mainBusiness"></el-input>
              </el-form-item>
              <el-form-item label="企业法人">
                <el-input disabled v-model="company.legalPerson"></el-input>
              </el-form-item>
              <el-form-item label="注册资金">
                <el-input disabled v-model="company.registeredCapital"></el-input>
              </el-form-item>
              <el-form-item label="邮政编码">
                <el-input disabled v-model="company.postalCode"></el-input>
              </el-form-item>
              <el-form-item label="诚信记录">
                <div>
                  <div>
                    企业及本人在三年内是否有不诚信行为或违法记录
                  </div>
                  <div class="vote-radio">
                    <el-radio v-model="company.isNoSincerity" :label="1" disabled>是</el-radio>
                    <el-radio v-model="company.isNoSincerity" :label="0" disabled>否</el-radio>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="通讯资料" name="3" style="margin-top: 0.4rem">
          <div class="form">
            <el-form ref="form" :model="wxUserInfo" label-width="1.5rem">
              <el-form-item label="联系人">
                <el-input disabled v-model="wxUserInfo.nickName" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="手机">
                <el-input disabled v-model="wxUserInfo.phone" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="传真号码">
                <el-input disabled v-model="wxUserInfo.faxNumber" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="电子邮箱">
                <el-input disabled v-model="wxUserInfo.email" placeholder="未填写"></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input disabled v-model="wxUserInfo.remarks" placeholder="未填写" type="textarea"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="证照资料" name="4" style="margin-top: 0.4rem">
          <div class="upload-box">
            <div class="enterprise">
              <div>
                <!--                <img style="width: 2rem;height: 2rem" :src="company.businessLicense?company.businessLicense:'../../static/upload/3.png'" />-->
                <el-image
                    style="width: 2rem;height: 2rem"
                    :src="company.businessLicense?company.businessLicense:'../../static/upload/3.png'"
                    :preview-src-list="businessLicense">
                </el-image>
              </div>
              <div class="enterprise-title">
                企业营业执照复印件
              </div>
            </div>
            <div class="line"></div>
            <div class="enterprise">
              <div class="idImage">
                <!--                <img style="width: 2rem;height: 2rem;margin-bottom: 0.1rem" :src="wxUserInfo.idCardPhoto1?wxUserInfo.idCardPhoto1:'../../static/upload/1.png'" />-->
                <el-image
                    style="width: 2rem;height: 2rem;margin-bottom: 0.1rem"
                    :src="wxUserInfo.idCardPhoto1?wxUserInfo.idCardPhoto1:'../../static/upload/1.png'"
                    :preview-src-list="idCardPhoto1">
                </el-image>
                <!--                <img style="width: 2rem;height: 2rem;margin-top: 0.1rem" :src="wxUserInfo.idCardPhoto2?wxUserInfo.idCardPhoto2:'../../static/upload/1.png'" />-->
                <el-image
                    style="width: 2rem;height: 2rem;margin-top: 0.1rem"
                    :src="wxUserInfo.idCardPhoto2?wxUserInfo.idCardPhoto2:'../../static/upload/1.png'"
                    :preview-src-list="idCardPhoto2"
                >
                </el-image>
              </div>
              <div class="enterprise-title">
                申请人身份证复印件
              </div>
            </div>
            <div class="line"></div>
            <div class="enterprise">
              <div>
                <!--                <img  style="width: 2rem;height: 2rem" :src="wxUserInfo.photo?wxUserInfo.photo:'../../static/upload/3.png'" />-->
                <el-image
                    style="width: 2rem;height: 2rem"
                    :src="wxUserInfo.photo?wxUserInfo.photo:'../../static/upload/3.png'"
                    :preview-src-list="photo">
                </el-image>
              </div>
              <div class="enterprise-title">
                申请人一寸证件照
              </div>
            </div>
            <div class="enterprise">
              <div>
                <!--                <img style="width: 2rem;height: 2rem" :src="applicationForm" />-->
                <el-image
                    style="width: 2rem;height: 2rem"
                    :src="applicationForm[0]"
                    :preview-src-list="applicationForm"
                    >
                </el-image>
              </div>
              <div class="enterprise-title">
                申请人会员申请表
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="audit-submit">
      <div class="audit-submit-button">
        <div class="audit-submit-button-disagree" @click="dialogTableVisible = true;audit = 2;dialogTitle = '不同意'">不同意
        </div>
        <div class="audit-submit-button-agree" @click="dialogTableVisible = true;audit = 1;dialogTitle = '同意'">同意</div>
      </div>
    </div>
    <div class="audit-modal">
      <el-dialog :title="dialogTitle" style="font-weight: 700" center :visible.sync="dialogTableVisible">
        <div class="audit-modal-title">
          审批意见
        </div>
        <div class="audit-modal-content">
          <el-input type="textarea" placeholder="请输入秘书处审批意见" v-model="opinion"></el-input>
        </div>
        <div class="audit-modal-button" @click="onSubmit">
          提交
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getLogin, getMembershipApproval, getMemberShipDetail,} from "@/common/api";
import config from "@/common/config";
import wx from "jweixin-module"

export default {
  data: function () {
    return {
      imgList:[],
      idCardPhoto1: [],
      idCardPhoto2: [],
      businessLicense: [],
      applicationForm: [],
      photo: [],
      id: null,
      dialogTitle: '',
      audit: '',
      role: 1,
      opinion: '',
      councilId: '',
      auditStatus: '0',
      dialogTableVisible: false,
      activeName: ['1','2','3','4'],
      uuid: null,
      company: {
        character: '',
        companyName: '',
        address: '',
        mainBusiness: '',
        legalPerson: '',
        registeredFund: '',
        postalCode: '',
        isNoSincerity: ''
      },
      wxUserInfo: {
        nickName: '',
        sex: '',
        hometown: '',
        idCard: '',
        politicsStatus: '',
        employer: '',
        jobTitle: '',
        phone: '',
        faxNumber: '',
        email: '',
        remarks: ''
      }
    }
  },
  created() {
    this.uuid = this.getQueryString("uuid")
    this.id = this.getQueryString("id")
    getMemberShipDetail(this.id).then(res => {
      this.wxUserInfo = res.data.data.wxUserInfo;
      this.idCardPhoto1.push(res.data.data.wxUserInfo.idCardPhoto1)
      this.idCardPhoto2.push(res.data.data.wxUserInfo.idCardPhoto2)
      this.photo.push(res.data.data.wxUserInfo.photo)
      if (res.data.data.wxUserInfo.sex == 1) {
        this.wxUserInfo.sex = '男'
      }
      if (res.data.data.wxUserInfo.sex == 2) {
        this.wxUserInfo.sex = '女'
      }
      this.company = res.data.data.company;
      this.businessLicense.push(res.data.data.company.businessLicense)
      this.role = res.data.data.wxUser.wxCharacter;
      this.applicationForm.push(res.data.data.applicationForm)
    })
    this.initConfig();
  },
  methods: {
    seeBigPicture(url) {
      wx.previewImage({
        current: url, // 当前显示图片的http链接
        urls: [url] // 需要预览的图片http链接列表
      });
    },
    initConfig() {
      let wxId = this.getQueryString('wxConfigId')
      let appId = this.getQueryString('appid')
      localStorage.setItem('wxConfigId', wxId)
      localStorage.setItem('appid', appId)
      if (wxId) {
        this.wxConfigId = wxId
      }
      if (appId) {
        this.appid = appId
      }
      if (this.wxConfigId && this.appid) {
        this.getCode();
      }
    },
    //判断是否登录
    getCode() {
      const code = this.getUrlCode().code // 截取code
      const local = config.redirecturl + 'audit?wxConfigId=' + this.wxConfigId + '&appid=' + this.appid + '&id=' + this.id + '&uuid=' + this.uuid; // 获取页面url ${encodeURIComponent(local)}
      const appid = this.appid;
      const wxConfigId = this.wxConfigId
      if (!code) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        getLogin({
          code: code,
          wxConfigId: wxConfigId
        }).then(res => {
          if (res.data.code === 200) {
            sessionStorage.setItem('Authorization', res.data.data);
          }
        })
      }
    },
    onSubmit() {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍后',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)'
      });
      console.log(this.uuid)
      getMembershipApproval({
        audit: this.audit,
        membershipId: this.id,
        opinion: this.opinion,
        uuid: this.uuid
      }).then(res => {
        if (res.data.code == 200) {
          loading.close();
          this.$toast("提交成功", {
            durtaion: 200
          });
          this.dialogTableVisible = false;
        } else {
          loading.close();
          this.$toast(res.data.msg || "提交失败", {
            durtaion: 200
          });
          this.dialogTableVisible = false;
        }
      })
    },
    // 获取地址栏参数
    getQueryString(id) {
      var reg = new RegExp("(^|&)" + id + "=([^&]*)(&|$)", "i");
      var reg_rewrite = new RegExp("(^|/)" + id + "/([^/]*)(/|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      var q = window.location.pathname.substr(1).match(reg_rewrite);
      if (r != null) {
        return unescape(r[2]);
      } else if (q != null) {
        return unescape(q[2]);
      } else {
        return null;
      }
    },
  }
}
</script>
<style scoped>
.audit-page {
  height: 100vh;
  overflow: scroll;
}

.form {
  padding: 0.26rem;
}

.audit-submit-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 0.32rem;
  margin-bottom: 0.8rem;
  margin-top: 0.5rem;
}

.audit-modal-content {
  padding-top: 0.2rem;
}

.audit-submit-button-disagree {
  width: 1.9rem;
  height: 0.98rem;
  background: #F6F6F6;
  border-radius: 0.12rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  display: flex;
  justify-content: center;
  align-items: center;
}

.audit-submit-button-agree {
  width: 4.76rem;
  height: 0.98rem;
  background: #DDB06C;
  border-radius: 0.12rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 0.6rem;
}

.audit-modal-title {
  font-size: 0.28rem !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400 !important;
  color: #333333;
  padding-bottom: 0.1rem;
}

.audit-modal-button {
  width: 4.6rem;
  height: 0.92rem;
  background: #DDB06C;
  border-radius: 0.08rem;
  margin-top: 0.5rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-box {
  padding: 0.26rem;
}

.enterprise {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
}

.enterprise-title {
  font-size: 0.28rem;
  margin-left: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.idImage {
  display: flex;
  flex-direction: column;
}

.line {
  height: 0.01rem;
  background-color: #E6E6E6;
}

.el-dialog {
  width: 5.52rem;
  height: 5.73rem;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 0.3rem);
  max-width: calc(100% - 0.3rem);
}

.el-dialog__body {
  transform: translate3d(0, 0, 0);
  overflow: auto;
}

.el-dialog__header {
  padding: 0 0 0 0 !important;
  text-align: center;
  height: 0.8rem;
}

.el-dialog__footer {
  text-align: center !important;
  padding: 12px 20px 12px 20px !important;
  border-top: 1px solid #f4f4f4;
}

.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-radio__input.is-checked + .el-radio__label {
  font-size: 0.28rem !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400 !important;
  color: #333333 !important;
}

.textarea >>> .el-input__inner {
  background: #F0F0F0 !important;
  font-size: 0.28rem !important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400 !important;
  color: #333333 !important;
}
</style>
