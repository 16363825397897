<template>
  <div class="active-page">
    <div class="form-box">
      <el-form ref="form" :rules="rules" :model="form" label-width="80px" :hide-required-asterisk="true">
        <!--        <el-form-item label="企业名称"  prop="companyName">-->
        <!--          <el-input v-model="form.companyName"  placeholder="请填写"></el-input>-->
        <!--        </el-form-item>-->
        <el-form-item label="联系人" prop="contactPerson">
          <el-input v-model="form.contactPerson" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="phone">
          <el-input v-model="form.phone" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="报名人数">
          <el-input type="number" v-model="form.peopleNumber" placeholder="请填写"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input v-model="form.remark" placeholder="选填" type="textarea"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <div class="submit-active">
      <div class="submit-button-active" @click="submitForm('form')">
        报名
      </div>
    </div>
  </div>
</template>

<script>
import { getSignUp } from "@/common/api";
import config from "@/common/config";

export default {
  data() {
    return {
      form: {
        companyName: '0',
        contactPerson: '',
        phone: '',
        remark: '',
        activityId: '',
        peopleNumber:1
      },
      appid: '',
      wxConfigId: '',
      rules: {
        companyName: [
          {required: true, message: '请输入公司名称', trigger: 'blur'},
        ],
        contactPerson: [
          {required: true, message: '请输入联系人', trigger: 'blur'}
        ],
        phone: [
          {required: true, message: '请输入联系电话', trigger: 'blur'},
          {min: 11, max: 11, message: '联系电话输入有误', trigger: 'blur'}
        ],
      }
    }
  },
  created() {
    this.activityId = localStorage.getItem("activityId")
    this.initConfig()
  },
  destroyed() {
    // 页面关闭时移除
    localStorage.removeItem("activityId")
  },
  methods: {
    initConfig() {
      // let wxId =
      this.wxConfigId = this.$route.query.wxConfigId
      // // let appId = this.getQueryString('appid')
      // if (!wxId) {
      //   wxId = sessionStorage.getItem('wxConfigId')
      //   // appId = sessionStorage.getItem('appid')
      //   // sessionStorage.setItem('wxConfigId', wxId)
      //   // sessionStorage.setItem('appid', appId)
      //   if (wxId) {
      //     this.wxConfigId = wxId
      //   }
      //   // if (appId) {
      //   //   this.appid = appId
      //   // }
      // } else {
      //   this.wxConfigId = wxId
      //   // this.appid = appId
      // }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          this.onSubmit();
        } else {
          return false;
        }
      });
    },
    onSubmit() {
      if(this.form.peopleNumber <= 0 ){
        this.$toast('报名人数至少为1')
        return
      }
      getSignUp({
        activityId: this.activityId,
        companyName: this.form.companyName,
        contactPerson: this.form.contactPerson,
        phone: this.form.phone,
        remark: this.form.remark,
        peopleNumber:this.form.peopleNumber,
        wxConfigId: this.wxConfigId
      }).then(res => {
        if (res.data.code === 200) {
          this.$router.push({
            path: '/activitySuccess'
          })
        } else if (res.data.code === 401) {
          sessionStorage.removeItem('Authorization')
          this.$toast(res.data.msg || "报名失败，请联系管理员", {
            durtaion: 200
          });
        } else {
          this.$toast(res.data.msg || "报名失败，请联系管理员", {
            durtaion: 200
          });
        }
      })
    },
    getUrlCode() { // 截取url中的code方法
      const url = location.search;
      this.winUrl = url
      const theRequest = {};
      if (url.indexOf("?") !== -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #FFFFFF;
}

.form-box {
  padding: 0.4rem;
  margin-top: 0.2rem;
  height: auto;
}

.submit-active {
  width: 100%;
  display: flex;
  justify-content: center;
}

.submit-button-active {
  width: 86%;
  height: 1rem;
  background: #DDB06C;
  border-radius: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #FFFFFF;
  letter-spacing: 0.1rem;
}
</style>
