<template>
  <active-list title="最新活动" :type="1" routeStr="newestList"/>
</template>

<script>
import ActiveList from './components/ActiveList.vue';
export default {
  components: {
    ActiveList
  },

};
</script>
