<template>
  <span>{{ day + '天' + hour + '时' + minuteString + '分' + secondString + '秒' }}</span>
</template>

<script>
import {getOnlineActivity} from "@/common/api";
import moment from 'moment'
export default {
  data() {
    return {
      hour: '',
      minute: '',
      second: '',
      timer: '',
      day:'',
      remainTime:''
    }
  },
  created() {
    this.getActivityTime()
  },
  methods: {
    getActivityTime() {
      getOnlineActivity('Activity_date').then(res => {
        if (res.data.code === 200) {
          this.dateDiffer(res.data.data)
        }
      })
    },
    // 获取活动时间
    dateDiffer(startTime){
      let dateBegin = new Date();
      let dateEnd = moment(startTime).format('YYYY/MM/DD HH:mm:ss');
      dateEnd = new Date(dateEnd)
      dateEnd = dateEnd.getTime()
      const dateDiff = dateEnd - dateBegin.getTime() //时间差的毫秒数
      console.log(dateDiff,'dateDiff')
      this.time = dateDiff
      this.remainTime = dateDiff
      if (this.remainTime > 0) {
        this.day = Math.floor((this.remainTime / 86400000))
        this.hour = Math.floor((this.remainTime / 3600000) % 24)
        this.minute = Math.floor((this.remainTime / 60000) % 60)
        this.second = Math.floor(this.remainTime /1000 % 60)
        this.countDowm()
      }
    },
    countDowm() {
      var self = this
      clearInterval(this.timer)
      this.timer = setInterval(function() {
        if(self.day === 0) {
          if (self.hour === 0) {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59
              self.minute -= 1
            } else if (self.minute === 0 && self.second === 0) {
              self.second = 0
              self.$emit('countDowmEnd', true)
              clearInterval(self.timer)
            } else {
              self.second -= 1
            }
          }else {
            if (self.minute !== 0 && self.second === 0) {
              self.second = 59
              self.minute -= 1
            } else if (self.minute === 0 && self.second === 0) {
              self.hour -= 1
              self.minute = 59
              self.second = 59
            } else {
              self.second -= 1
            }
          }
        }else if(self.hour === 0){
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.day -= 1
            self.hour = 23
            self.minute = 59
            self.second = 59
          } else {
            self.second -= 1
          }
        }else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1
            self.minute = 59
            self.second = 59
          } else {
            self.second -= 1
          }
        }
      }, 1000)
    },
    formatNum(num) {
      return num < 10 ? '0' + num : '' + num
    }
  },
  computed: {
    hourString() {
      return this.formatNum(this.hour)
    },
    minuteString() {
      return this.formatNum(this.minute)
    },
    secondString() {
      return this.formatNum(this.second)
    }
  }
}
</script>
