<template>
  <div class="presidentVote-page">
    <div class="presidentVote-box">
      <div class="apply-title">
        {{ title }}
      </div>
      <div class="apply-content">
        {{ content }}
      </div>
    </div>
    <div class="vote-submit">
      <div class="vote-submit-button">
        <div class="vote-submit-button-vote" @click="dialogTableVisible = true">投票</div>
      </div>
    </div>
    <div class="vote-modal">
      <el-dialog title="投票" style="font-weight: 600" center :visible.sync="dialogTableVisible">
        <div class="vote-modal-title">
          审批意见
        </div>
        <div class="vote-radio">
          <el-radio v-model="radio" label="1">赞成</el-radio>
          <el-radio v-model="radio" label="2">不赞成</el-radio>
          <el-radio v-model="radio" label="3">弃权</el-radio>
        </div>
        <div class="vote-modal-title">
          审批意见
        </div>
        <div class="vote-modal-content">
          <el-input type="textarea" placeholder="请输入审批意见" v-model="opinion"></el-input>
        </div>
        <div class="vote-modal-button" @click="onSubmit">
          提交
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {getProposalCouncil, addProposalVote, getProposalCouncilToken, addProposalVoteToken} from "@/common/api";

export default {
  data() {
    return {
      id: null,
      uuid: null,
      councilId: '',
      role: '',
      opinion: '',
      radio: '1',
      dialogTableVisible: false,
      activeName: '1',
      applicationId: '',
      title: '',
      content: ''
    }
  },
  created() {
    this.uuid = this.getQueryString('uuid')
    this.applicationId = this.getQueryString('id')
    this.getProposalDetail()
  },
  methods: {
    getProposalDetail() {
      if (this.uuid) {
        getProposalCouncil(this.getQueryString('id')).then(res => {
          if (res.data.code === 200) {
            this.content = res.data.data.content
            this.title = res.data.data.title
          }
        })
      } else {
        getProposalCouncilToken(this.getQueryString('id')).then(res => {
          if (res.data.code === 200) {
            this.content = res.data.data.content
            this.title = res.data.data.title
          }
        })
      }
    },
    onSubmit() {
      if (this.uuid && sessionStorage.getItem('Authorization')) {
        addProposalVoteToken({
          aidit: this.radio,
          opinion: this.opinion,
          proposalId: this.applicationId,
          // uuid: this.uuid
        }).then(res => {
          if (res.data.code === 200) {
            this.$toast("提交成功", {
              durtaion: 3000
            });
            this.dialogTableVisible = false;
          } else {
            this.$toast(res.data.msg || "提交失败", {
              durtaion: 3000
            });
          }
        })
      } else {
        addProposalVote({
          aidit: this.radio,
          opinion: this.opinion,
          proposalId: this.applicationId,
          uuid: this.uuid
        }).then(res => {
          if (res.data.code === 200) {
            this.$toast("提交成功", {
              durtaion: 3000
            });
            this.dialogTableVisible = false;
          } else {
            this.$toast(res.data.msg || "提交失败", {
              durtaion: 3000
            });
          }
        })


      }
    },
// 获取地址栏参数
    getQueryString(id) {
      var reg = new RegExp("(^|&)" + id + "=([^&]*)(&|$)", "i");
      var reg_rewrite = new RegExp("(^|/)" + id + "/([^/]*)(/|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      var q = window.location.pathname.substr(1).match(reg_rewrite);
      if (r != null) {
        return unescape(r[2]);
      } else if (q != null) {
        return unescape(q[2]);
      } else {
        return null;
      }
    },
  }
}
</script>
<style scoped>
.apply-title {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC, serif;
  color: #333333;
  padding: 0.3rem;
}

.apply-content {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC, serif;
  color: #333333;
  margin-top: 0.2rem;
  padding: 0 0.3rem;
  line-height: 0.45rem;
}

.presidentVote-page {
  height: 100vh;
  overflow: scroll;
}

.presidentVote-box {

}

.form {
  padding: 0 0.26rem;
}

.vote-submit-button {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0.8rem;
}

.vote-submit-button-vote {
  width: 6rem;
  height: 0.98rem;
  background: #DDB06C;
  border-radius: 1rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-box {
  padding: 0.26rem;
}

.enterprise {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
}

.enterprise-title {
  font-size: 0.28rem;
  margin-left: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.idImage {
  display: flex;
  flex-direction: column;
}

.line {
  height: 0.01rem;
  background-color: #E6E6E6;
}

.custom-class {
  width: 5.52rem !important;
  min-width: 5.52rem !important;
}

.vote-modal-title {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding-bottom: 0.1rem;
  padding-top: 0.15rem;

}

.vote-radio {
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}

/deep/ .el-input__inner {
  border: 0;
  background-color: #F0F0F0;
}

/deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus {
  border: 0;
  outline: 0;
}

.textarea >>> .el-input__inner {
  background: #F0F0F0 !important;
  font-size: 0.28rem !important;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400 !important;
  color: #333333 !important;

}

.vote-modal-button {
  /*width: 4.6rem;*/
  height: 0.92rem;
  background: #DDB06C;
  border-radius: 0.08rem;
  margin-top: 0.5rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC, serif;
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}

.el-dialog {
  width: 5.52rem;
  height: 7.05rem;
  margin: 0 !important;
  display: flex;
  flex-direction: column;
  max-height: calc(100% - 0.3rem);
  max-width: calc(100% - 0.3rem);
  border-radius: 0.16rem;
}

.el-dialog__body {
  transform: translate3d(0, 0, 0);
  overflow: auto;
}

.el-dialog__header {
  padding: 0 0 0 0 !important;
  text-align: center;
  height: 0.8rem;
}

.el-dialog__footer {
  text-align: center !important;
  padding: 12px 20px 12px 20px !important;
  border-top: 1px solid #f4f4f4;
}

.el-dialog__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.el-radio__input.is-checked .el-radio__inner {
  border-color: #DDB06C;
  background-color: #DDB06C;
}

.el-radio__inner:hover {
  border-color: #DDB06C;
}

.el-radio__input.is-checked + .el-radio__label {
  font-size: 0.28rem !important;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400 !important;
  color: #333333 !important;
}


</style>
