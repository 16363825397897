// AMap.js
// 高德map   https://webapi.amap.com/maps?v=1.4.11&key=你的高德地图的key
export default function MapLoader() {
	return new Promise((resolve, reject) => {
		if (window.AMap) {
			resolve(window.AMap)
		} else {

			let script = document.createElement('script')
			script.type = 'text/javascript'
			// script.async = true
			script.src =
				'https://webapi.amap.com/maps?v=1.4.11&callback=initAMap&key=10becea6bf672fcbbe57220cc7aa2393'
			script.onerror = reject
			document.head.appendChild(script)
		}
		window.initAMap = () => {
			resolve(window.AMap)
		}
	})
}


export function getUrlCode() { // 截取url中的code方法
	const url = location.search;
	const theRequest = {};
	if (url.indexOf("?") !== -1) {
		const str = url.substr(1);
		const strs = str.split("&");
		for (let i = 0; i < strs.length; i++) {
			theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
		}
	}
	return theRequest
}



