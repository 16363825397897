<template>
  <div class="partner-page">
    <scroller  :on-infinite="infinite" ref="my_scroller" :noDataText="''">
    <div class="partner-list-box" v-if="partnerList.length > 0">
      <div class="partner-item" v-for="(item,index) in partnerList" :key="index" @click="jumpDetail(item.url)">
        <div class="partner-avatar">
          <el-avatar size="large" :src="item.logo"></el-avatar>
        </div>
        <div class="partner-name">
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
<!--    <div class="empty" v-else>-->
<!--      暂无相关数据-->
<!--    </div>-->
    </scroller>
  </div>
</template>

<script>
import {getChainList} from "@/common/api";

export default {
  data() {
    return {
      partnerList: [],
      pageSize:8,
      pageNum: 1,
      total: '',
      listLength: 0,
      title:''
    }
  },
  created() {
    this.initConfig()
    this.getChainList();
    if(this.getQueryString('title')){
      document.title = '地级商会'
    }
  },
  methods: {
    initConfig(){
        let wxId = localStorage.getItem('wxConfigId')
        let appId = localStorage.getItem('appid')
        if (!wxId || !appId) {
            wxId = this.getQueryString('wxConfigId')
            appId = this.getQueryString('appid')
          localStorage.setItem('wxConfigId', wxId)
          localStorage.setItem('appid', appId)
            if (wxId) {
                this.wxConfigId = wxId
            }
            if (appId) {
                this.appid = appId
            }
        }else{
            this.wxConfigId = wxId
            this.appid = appId
        }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    infinite() {
      setTimeout(() => {
        if(this.listLength >= this.total){
          this.$refs.my_scroller.noDataText=""//更改上拉加载的文字
          this.$refs.my_scroller.finishInfinite(true);
        }else{
          this.pageNum = this.pageNum + 1;
          this.getChainList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 1500)
    },
    getChainList() {
      getChainList({
        wxConfigId: this.wxConfigId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if (res.data.code === 200) {
          this.partnerList = this.partnerList.concat(res.data.rows);
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;
        }
      })
    },
    jumpDetail(url) {
      window.location.href = url
    }
  }
}
</script>

<style scoped>
.partner-page {
  width: 100%;
  min-height: 100vh;
  height: 100%;
  overflow: scroll;
  background-color: #f0f0f0;
}
.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}
.partner-list-box {
  padding: 0.42rem;
}

.partner-item {
  margin-top: 0.32rem;
  width: 6.66rem;
  height: 1.26rem;
  background: #FFFFFF;
  border-radius: 0.63rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.partner-avatar {
  margin-left: 0.8rem;
  margin-top: 0.1rem;
}

.partner-name {
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #333333;
  margin-left: 0.3rem;
}
</style>
