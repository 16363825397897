// 导入axios
import axios from 'axios';
// 进行一些全局配置
// 公共路由(网络请求地址)
// const baseUrl = 'http://192.168.0.100:8080';
const baseUrl = "http://chambermp.bjyulinkeji.com/prod-api"
// const baseUrl = "/api/prod-api"
axios.defaults.baseURL = baseUrl + '/gzh';
// 请求响应超时时间
axios.defaults.timeout = 300000;

// 封装自己的get/post方法
export default {
    gets: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios.get(path, {
                headers:{
                    'Content-Type': 'application/x-www-form-urlencoded',
                    'Authorization':sessionStorage.getItem('Authorization')
                },
                params: data,
            })
                .then(function(response) {
                    // 按需求来，这里我需要的是response.data，所以返回response.data，一般直接返回response
                    resolve(response);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    },
    get: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios.get(path, {
                params: data
            })
                .then(function(response) {
                    // 按需求来，这里我需要的是response.data，所以返回response.data，一般直接返回response
                    resolve(response);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    },
    posts: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios({
                url: path,
                method: 'post',
                data: data,
                headers: {
                    'Authorization':sessionStorage.getItem('Authorization')
                }
            }).then(function(response) {
                    resolve(response);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    },
    post: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios.post(path, data)
                .then(function(response) {
                    resolve(response);
                })
                .catch(function(error) {
                    reject(error);
                });
        });
    },
    postOrs: function(path = '', data = {}){
        return new Promise(function(resolve, reject) {
            if(sessionStorage.getItem('Authorization')){
                axios({
                    url: path,
                    method: 'post',
                    data: data,
                    headers: {
                        'Authorization':sessionStorage.getItem('Authorization')
                    }
                }).then(function(response) {
                        resolve(response);
                }).catch(function(error) {
                    reject(error);
                });
            }else{
                axios.post(path, data)
                .then(function(response) {
                    resolve(response);
                })
                .catch(function(error) {
                    reject(error);
                });
            }
        });
    },
    puts: function(path = '', data = {}) {
        return new Promise(function(resolve, reject) {
            axios({
                url: path,
                method: 'put',
                data: data,
                headers: {
                    'Authorization':sessionStorage.getItem('Authorization')
                }
            }).then(function(response) {
                resolve(response);
            })
                .catch(function(error) {
                    reject(error);
                });
        });
    }
};
