// 导入封装好的网络请求类工具
import Network from './request';

// 封装各种接口请求
//用户登录 /gzh/weixin/user/login
export const getLogin = (params = {}) => Network.post('/weixin/user/login', params)
//新闻动态
export const getNewsList = (params = {}) => Network.get('/wechat/news/list', params)
//获取新闻管理详细信息
export const getNewsDetail = (params) => Network.get('/wechat/news/' + params)
//查询商会领导列表
export const getLeaderList = (params = {}) => Network.get('/wechat/leader/list', params)
//获取商会领导详细信息
export const getLeaderDetail = (params) => Network.get('/wechat/leader/?' + params)
//新增活动报名
export const getSignUp = (params) => Network.post('/wechat/up', params)
//新增入会申请
export const getMemberShip = (params) => Network.post('/wechat/membership/applyVip', params)
//获取入会审批详细信息 /wechat/membership/{membershipId}
export const getMemberShipDetail = (params) => Network.get('/wechat/membership/' + params)
//入会审批
export const getMembershipApproval = (params) => Network.postOrs('/wechat/membership/approval', params)
//查询优秀会员列表
export const getMemberList = (params) => Network.get('/weixin/user/getVipList?pageNum=' + params.pageNum + '&pageSize=' + params.pageSize + '&wxConfigId=' + params.wxConfigId + '&nickName=' + params.nickName)
//查询友好商会列表
export const getChainList = (params) => Network.get('/wechat/chain/list', params)
//新增理事会申请
export const getCouncil = (params) => Network.posts('/wechat/council', params)
//获取微信用户详细信息
export const getUserInfo = (params) => Network.gets('/weixin/user/wxUserInfo/', params)
//新增供需
export const getDemand = (params) => Network.posts('/weixin/demand', params)
//获取理事会申请详细信息
export const getDemandDetail = (params) => Network.get('/wechat/council/' + params)
//秘书投票
export const getSecretaryApproval = (params) => Network.postOrs('/wechat/vote', params)
//会长投票
export const getPresidentApproval = (params) => Network.postOrs('/wechat/vote' + params)
//查询供需列表
export const getDemandList = (params) => Network.get('/weixin/demand/list', params)
//获取供需详细信息
export const getSupplyDemand = (params) => Network.get(`/weixin/demand/${params}`)
//查询活动列表
export const getActivityList = (params) => Network.get('/wechat/wxactivity/list', params)
//获取活动管理详细信息
export const getActivityDetail = (params) => Network.get('/wechat/wxactivity/' + params)
//获取会员中心详细信息
export const getWxUserInfo = (params) => Network.gets('/weixin/user/wxUserInfo', params)
//查询活动报名列表
export const getUserActivityList = (params) => Network.gets('/wechat/up/list', params)
//查询我发布的供需 /weixin/demand/meList
export const getDemandMeList = (params) => Network.gets('/weixin/demand/meList', params)
//下线供需
export const getDemandOffline = (params) => Network.puts('/weixin/demand', params)
//获取商会配置信息
export const getWechatConfig = (params) => Network.get('/wechat/commerceSetting/querycommerceSetting', params)
//公众号绑定
export const getUserBinding = (params) => Network.post('/weixin/user/userBinding', params)
//获取优秀会员列表
export const getExcellentList = (params) => Network.get('/wechat/member/list', params)
//获取优秀会员详细信息
export const getExcellentDetail = (params) => Network.get('/wechat/member/' + params)
//获取微信授权签名
export const getSignature = (params) => Network.posts('/weixin/user/getSignature', params)
export const getSignatureAll = (params) => Network.post('/weixin/user/permit/getSignature', params)
//报名签到
export const activitySignIn = (params) => Network.puts('/wechat/up/signIn/' + params)
//获取企业下载表
export const downMemberShip = (params) => Network.post('/wechat/membership/downloadFile', params)
//会员审核 理事投票列表
export const getMessageList = (params) => Network.gets('/wechat/message/list', params)
//新增商户入驻申请
export const getMerchantAdd = (params) => Network.posts('/wechat/merchant', params)

//查询商户列表
// export  const getMerchantList = (params) => Network.get('/wechat/merchant/list?pageNum='+ params.pageNum + '&pageSize='+ params.pageSize)
// 查询商户列表
export const getMerchantList = (params) => Network.get('/wechat/merchant/list', params)

//搜索 全部商会
export const SearchListByValue = (params) => Network.get('/wechat/merchant/list?searchValue=' + params.searchValue)
// 本商会
export const SearchListByTable = (params) => Network.get('/wechat/merchant/list?wxConfigId=' + params.wxConfigId)

//查询会员编号
export const getVipNumber = (params) => Network.gets('/wechat/wxConfig/list?wxConfigId=' + params)

// 秘书长审核理事申请
export const getExamineCouncil = (params) => Network.posts('/wechat/council/examineCouncil', params)

// 获取提案详情 uuid
export const getProposalCouncil = (params) => Network.get('/wechat/proposal/' + params)

// 新增提案投票  uuid
export const addProposalVote = (params) => Network.post('/wechat/proposalVote', params)

// 获取提案详情 token
export const getProposalCouncilToken = (params) => Network.gets('/wechat/proposal/' + params)

// 新增提案投票  token
export const addProposalVoteToken = (params) => Network.posts('/wechat/proposalVote', params)

// 上传图片
export  const uploadImage = (params) => Network.post('/common/mpUploadFile', params)

// 查询中秋活动相关内容
export const getOnlineActivity = (params) => Network.get('/wechat/wxConfig/getConfigInfo/'+ params)

// 查询中秋活动是否已经答题
export const getCheckAnswer = (params) => Network.get('/wechat/reward/getIsAnswer/'+ params)

// 查询中秋活动中奖内容
export const getReward = (params) => Network.get('/wechat/reward/list?type='+ params.type + '&rewardLevel=' + params.rewardLevel)

// 查询中秋活动签到
export const getSign  = (params) => Network.get('/wechat/up/signIn17?contactPerson='+ params.contactPerson)

// 查询中秋活动答题奖励
export const getAnswerReward  = (params) => Network.post('/wechat/reward/answer', params)

// 投票进度查询
export const getVotingProgress  = (params) => Network.gets('/wechat/vote/votingProgress', params)

// 投票百分比查询
export const getVotingPercentage = (params) => Network.post('/wechat/vote/votingPercentage', params)
