<template>
  <div class="binding-page">
    <div class="binding-title">
      身份证绑定或去申请会员
    </div>
    <div class="binding-body">
      <div class="binding-input">
        <div class="name">
          <el-input
              placeholder="请输入姓名"
              v-model="name">
          </el-input>
        </div>
        <div class="name" style="margin-top: 0.2rem">
          <el-input
              placeholder="请输入身份证号码"
              v-model="idCard">
          </el-input>
        </div>
      </div>
      <div class="binding-button">
        <div class="binding-button-binding">
          <el-button type="warning" :disabled="bindingStatus" @click="onSubmit"
                     style="width: 6.46rem;height: 0.98rem;border-radius: 0.46rem" round>绑定
          </el-button>
        </div>
        <div class="binding-button-apply">
          <el-button @click="goApply" :disabled="bindingStatus"
                     style="width: 6.46rem;height: 0.98rem;border-radius: 0.46rem" round>申请会员
          </el-button>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer-title">
        温馨提示
      </div>
      <div class="footer-detail">
        如果您已是商会会长、监事、理事、会员等，为了您能获取到更多会员权益，请输入姓名、身份证号码点击绑定，如果非商会成员，请点击申请会员。
      </div>
    </div>
  </div>
</template>

<script>
import {getUserBinding} from "@/common/api";
import config from "@/common/config";
import WeixinJSBridge from "weixin-js-sdk"
export default {
  data() {
    return {
      bindingStatus: false,
      bindingData: {},
      idCard: '',
      code: '',
      name: '',
      wxConfigId: '',
      appid: ''
    }
  },
  created() {
    this.initConfig()
  },
  mounted() {
    window.addEventListener("popstate", function (e) {
      this.close()
    }, false);
  },
  methods: {
    close() {
      if (typeof (WeixinJSBridge) != "undefined") {
        WeixinJSBridge.call('closeWindow');
      } else {
        if (navigator.userAgent.indexOf("MSIE") > 0) {
          if (navigator.userAgent.indexOf("MSIE 6.0") > 0) {
            window.opener = null;
            window.close();
          } else {
            window.open('', '_top');
            window.top.close();
          }
        } else if (navigator.userAgent.indexOf("Firefox") > 0) {
          window.location.href = 'about:blank ';
        } else {
          window.opener = null;
          window.open('', '_self', '');
          window.close();
        }
      }
    }

    ,
    initConfig() {
      let wxId = localStorage.getItem('wxConfigId')
      let appId = localStorage.getItem('appid')
      if (!wxId || !appId) {
        wxId = this.getQueryString('wxConfigId')
        appId = this.getQueryString('appid')
        localStorage.setItem('wxConfigId', wxId)
        localStorage.setItem('appid', appId)
        if (wxId) {
          this.wxConfigId = wxId
        }
        if (appId) {
          this.appid = appId
        }
      } else {
        this.wxConfigId = wxId
        this.appid = appId
      }
      if (this.wxConfigId && this.appid) {
        this.getCode(false)
      }
    }
    ,
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    }
    ,
    onSubmit() {
      if (!this.name) {
        this.$toast("姓名输入有误，请重新输入", {
          durtaion: 200
        });
        return
      }
      if (this.idCard.length != 18 || !this.idCard) {
        this.$toast("身份证输入有误，请重新输入", {
          durtaion: 200
        });
        return
      }
      getUserBinding({
        code: this.code,
        idCard: this.idCard,
        wxConfigId: this.wxConfigId
      }).then(res => {
        if (res.data.code === 200) {
          this.bindingStatus = true
          this.$toast("绑定成功！请重新进入公众号", {
            durtaion: 3000
          });
        } else if (res.data.code === 401) {
          this.$toast("信息填写有误", {
            durtaion: 2000
          });
          this.getCode(true)
        } else {
          this.$toast(res.data.msg || "绑定失败", {
            durtaion: 2000
          });
        }
      })
    }
    ,
    getCode(condition) {
      this.code = this.getUrlCode().code // 截取code
      const local = config.redirecturl + 'binding?wxConfigId=' + this.wxConfigId + '&appid=' + this.appid; // 获取页面url ${encodeURIComponent(local)}
      const appid = this.appid;
      if (condition) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        if (this.code == null || this.code === '') { // 如果没有code，则去请求
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
        }
      }
    }
    ,
    getUrlCode() { // 截取url中的code方法
      const url = location.search;
      this.winUrl = url
      const theRequest = new Object();
      if (url.indexOf("?") != -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    }
    ,
    goApply() {
      this.$router.push({
        path: '/apply',
        query: {
          wxConfigId: this.wxConfigId,
          appid: this.appid
        }
      })
    }
  }
}
</script>

<style scoped>
.binding-page {
  height: 100vh;
  overflow: scroll;
}

.binding-title {
  font-size: 0.44rem;
  font-weight: 600;
  color: #000000;
  padding: 0.8rem 0 0 0.42rem;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}

.binding-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 0.78rem;
}


.binding-input {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/deep/ .el-input__inner { /*或者 .s2>>>.el-input__inner  */
  border-radius: 0.46rem; /*输入框圆角值*/
}

.name {
  height: 0.92rem;;
  width: 6.46rem;
}

.footer {
  margin-top: 2.58rem;
  padding: 0.52rem;
}

.footer-title {
  font-size: 0.26rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.footer-detail {
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  margin-top: 0.24rem;
}
</style>
