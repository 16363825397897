<template>
  <div class="news-page">
    <scroller :on-infinite="infinite" ref="my_scroller" :noDataText="noDataText">
      <!-- <div class="news-title">
        {{ title }}
      </div> -->
      <div class="news-logo">
        <img :src="wechatConfig.logo" style="width: 0.5rem;height: 0.5rem;border-radius: 100rem"/>
        <div class="news-name" style="font-size: 0.25rem">
          {{ wechatConfig.wxName }}
        </div>
      </div>
      <div class="activity-body-banner" v-if="topList.length > 0">
        <slider ref="slider" :options="options">
          <!-- slideritem wrapped package with the components you need -->
          <slideritem v-for="(item,index) in topList" :key="index" @tap="jumpDetail(item.activityId)">
            <div class="slider-item">
              <div class="news-image" style="width: 100%;height: 2.5rem">
                <img :src="item.image" style="width: 100%;height: 2.5rem"/>
              </div>
              <div class="news-item-bg" style="width: 100%">
                <div
                    style="width: 55%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;margin-left: 0.1rem;color: #000000">
                  {{ item.title }}
                </div>
              </div>
            </div>
          </slideritem>
        </slider>
      </div>
      <div class="news-body-title">
        <div class="news-body-title-text">
          {{ title }}
        </div>
      </div>
      <div class="list-box-activity" v-if="activityList.length > 0">
        <div class="list-item-activity" v-for="(item,index) in activityList" :key="index"
             @click="jumpDetail(item.activityId)">
          <div class="item-activity">
            <div class="title-activity" style="width: 55%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
              {{ item.title }}
            </div>
            <img style="width:1rem;height:1rem" :src="item.image"/>
          </div>
        </div>
      </div>
      <!--    <div class="empty" v-else>-->
      <!--      <div>-->
      <!--        暂无{{ title }}-->
      <!--      </div>-->
      <!--    </div>-->
    </scroller>
  </div>
</template>

<script>
import {getActivityList, getWechatConfig} from "@/common/api";
import {slider, slideritem} from 'vue-concise-slider'

export default {
  name: "ActiveList",
  components: {
    slider,
    slideritem
  },
  data() {
    return {
      noDataText: '没有更多数据了',
      startX: 0,
      endX: 0,
      wechatConfig: '',
      activityList: [],
      topList: [],
      appid: '',
      wxConfigId: '',
      options: {
        pagination: false,
        centerSlides: false,
        thresholdDistance: 100,
        thresholdTime: 300,
        grabCursor: true,
        speed: 300
      },
      pageSize: 10,
      pageNum: 1,
      total: '',
      listLength: 0
    }
  },
  props: {
    type: {
      type: Number,
      default: 0
    },
    title: String,
    routeStr: String
  },
  created() {
    this.initConfig();
  },
  methods: {
    infinite() {
      setTimeout(() => {
        if (this.listLength >= this.total) {
          // this.$refs.my_scroller.noDataText="没有更多数据了"//更改上拉加载的文字
          this.$refs.my_scroller.finishInfinite(true);
        } else {
          this.pageNum = this.pageNum + 1;
          this.getList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 1500)
    },
    initConfig() {
      let wxId = this.getQueryString('wxConfigId')
      let appId = this.getQueryString('appid')
      localStorage.setItem('wxConfigId', wxId)
      localStorage.setItem('appid', appId)
      if (wxId) {
        this.wxConfigId = wxId
      }
      if (appId) {
        this.appid = appId
      }
      if (this.wxConfigId) {
        this.getConfig();
        this.getTopList();
        this.getList();
      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    getList() {
      getActivityList({ // 加载活动
        type: this.type,
        wxConfigId: this.wxConfigId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if (res.data.code === 200) {
          this.activityList = this.activityList.concat(res.data.rows);
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;
        }
      });
    },
    getTopList() { // 加载置顶活动
      getActivityList({
        type: this.type,
        wxConfigId: this.wxConfigId,
        isTop: 1
      }).then(res => {
        if (res.data.code === 200) {
          this.topList = res.data.rows;
        }
      });
    },
    jumpDetail(id) {
      // console.log(window.location.href.split('#')[0]);
      window.location.href = 'http://chambermp.bjyulinkeji.com/activityDetail?wxConfigId='+this.wxConfigId + '&id=' + id
      // this.$router.push({
      //   path: '/activityDetail', query: {
      //     wxConfigId: this.wxConfigId,
      //     id: id,
      //   }
      // })
    },
    // 加载配置信息
    getConfig() {
      getWechatConfig({
        wxConfigId: this.wxConfigId
      }).then(res => {
        if (res.data.code === 200) {
          this.wechatConfig = res.data.data.data
        }
      })
    }
  }
}
</script>


<style scoped>
.news-page {
  height: 100vh;
  overflow: scroll;
}

.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}

.news-title {
  font-size: 0.3rem;
  padding: 0.1rem 0.4rem;

}

.slider-item {
  display: flex;
  flex-direction: column;
}

.news-logo {
  display: flex;
  padding: 0.2rem;
  height: 0.5rem;
  align-items: center;
  margin-left: 0.2rem;
}

.news-name {
  font-size: 0.2rem;
  color: #169BD5;
  margin-left: 0.2rem;
}

.news-image {
  height: 1.8rem
}

.news-item-bg {
  background-color: #F2F2F2;
  height: 0.6rem;
  font-size: 0.2rem;
  display: flex;
  align-items: center;
  text-align: left;
}

.news-body-title {
  display: flex;
  justify-content: center;
  border-bottom: 0.01rem solid #F2F2F2;
  padding-bottom: 0.05rem;
}

.news-body-title-text {
  font-size: 0.3rem;
  color: #4ECE5B;
  padding-top: 0.3rem;
}

.news-body-title-text::after {
  content: '';
  width: 40%;
  height: 1px;
  display: block;
  margin: 0 auto;
  border-bottom: 0.05rem solid #4ECE5B;
  padding-bottom: 0.1rem;
}

.item-activity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 0;
}

.list-item-activity {
  border-bottom: 0.01rem solid #F2F2F2;
  padding: 0 0.3rem;
}

.title-activity {
  font-size: 0.3rem;
}

.search {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
}

.news-head {
  padding: 0.32rem;
}

.nav {
  display: flex;
  justify-content: space-between;
  color: #333333;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;

}

.active {
  border-bottom: 0.05rem solid #FF5740;
  padding-bottom: 0.1rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #333333;
}

.list-item-news {
  margin: 0 0.32rem;
  border-bottom: 0.01rem solid #E6E4E1;
}

.item-news {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

}

.title-news {
  margin-left: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

.scrolldiv {
  height: 70vh;
}
</style>
