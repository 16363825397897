<template>
  <active-list title="贵人春晚" :type="4" routeStr="guizhouList"/>
</template>

<script>
import ActiveList from './components/ActiveList.vue';
export default {
  components: {
    ActiveList
  }
};
</script>
