<template>
  <span>{{ minuteString + ':' + secondString }}</span>
</template>

<script>
export default {
  props: {
    remainTime: Number
  },
  data() {
    return {
      hour: '',
      minute: '',
      second: '',
      timer: ''
    }
  },
  mounted() {
    if (this.remainTime > 0) {
      this.hour = Math.floor((this.remainTime / 3600) % 24)
      this.minute = Math.floor((this.remainTime / 60) % 60)
      this.second = Math.floor(this.remainTime % 60)
      this.countDowm()
    }
  },
  methods: {
    setTimeToStorge(){
      localStorage.setItem('minute',this.minute)
      localStorage.setItem('second',this.second)
    },
    countDowmEnd(){
      clearInterval(this.timer)
      this.$emit('stop', this.minute);
    },
    countDowm() {
      var self = this
      clearInterval(this.timer)
      this.timer = setInterval(function() {
        if (self.hour === 0) {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
            self.setTimeToStorge()
          } else if (self.minute === 0 && self.second === 0) {
            self.second = 0
            self.$emit('countDowmEnd', true)
            clearInterval(self.timer)
          } else {
            self.second -= 1
            self.setTimeToStorge()
          }
        } else {
          if (self.minute !== 0 && self.second === 0) {
            self.second = 59
            self.minute -= 1
            self.setTimeToStorge()
          } else if (self.minute === 0 && self.second === 0) {
            self.hour -= 1
            self.minute = 59
            self.second = 59
            self.setTimeToStorge()
          } else {
            self.second -= 1
            self.setTimeToStorge()
          }
        }
      }, 1000)
    },
    formatNum(num) {
      return num < 10 ? '0' + num : '' + num
    }
  },
  computed: {
    hourString() {
      return this.formatNum(this.hour)
    },
    minuteString() {
      return this.formatNum(this.minute)
    },
    secondString() {
      return this.formatNum(this.second)
    }
  }
}
</script>
