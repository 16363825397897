<template>
  <div class="container">
    <div class="mask" v-if="maskShow">
      <div class="award">
        <div class="mask_image">
          <img src="../../../assets/instant/answer/award.png" style="height: 3.02rem;width: 5.38rem"/>
        </div>
        <div class="mask_box">
          <div class="mask_title">
            恭喜您答题完成！
          </div>
          <div class="mask_subTitle">
            共计{{ question.topic.length }}道题，总时长{{ consumeTimeNum }}分钟，答对{{ score }}道题！
          </div>
          <div class="mask_awardDetail">
            <div class="mask_awardDetail_title">
              {{ finalAward }}
            </div>
            <div class="mask_awardDetail_subtitle">
              可以通过该优惠券在线下店铺领取，领取地址
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="count">
      <span class="count_text" v-if="question.time">
        <countdown @countDowmEnd="countDowmEnd" @stop="consumeTime" ref="count" :remain-time="question.time"/>
      </span>
    </div>
    <div class="answer_container">
      <div class="answer_box">
        <div class="question_number">
          <div class="number_border" v-if="question.topic">
            <span class="question_number_text">{{ questionNumber }}/{{ question.topic.length }}</span>
          </div>
        </div>
        <div class="question_box" style="padding-top: 0.69rem;padding-left: 0.48rem;padding-right: 0.48rem">
          <div class="ques_title">
            <div class="ques_title_text" v-if="question.topic">{{ questionNumber }}、{{
                question.topic[questionNumber - 1].topic || ''
              }}
            </div>
          </div>
          <div class="ques_solution_box" style="padding-top: 0.29rem;position: relative" v-if="question.topic">
            <div class="solution_item" v-for="(item,index) in question.topic[questionNumber - 1].options" :key="index"
                 :style="{background:( chooseIndex === index?(chooseIndex === trueIndex?'#21AD37':'#D60100') :'#F5F4F7')}"
                 @click="chooseAnswer(index)">
              <span class="solution_item_text"
                    :style="{color:(chooseIndex === index ? '#FFFFFF':'#333333')}">{{ item.serial }}  {{
                  item.content
                }}
              <i v-if="chooseIndex === index && chooseIndex === trueIndex" class="el-icon-check"
                 style="position: absolute;right: 0.25rem;margin-top: 0.05rem"></i>
              <i v-if="chooseIndex === index && chooseIndex !== trueIndex" class="el-icon-close"
                 style="position: absolute;right: 0.25rem;margin-top: 0.05rem"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button_box">
      <div class="button" @click="nextQuestion">
        <span class="button_text" v-if="questionNumber < question.topic.length">下一题</span>
        <span class="button_text" v-else>提交</span>
      </div>
    </div>
  </div>
</template>

<script>
import countdown from "@/pages/activity/components/countdown";
import {getAnswerReward, getCheckAnswer, getOnlineActivity,} from "@/common/api";

export default {
  name: "answer",
  components: {
    countdown
  },
  data() {
    return {
      questionNumber: 1,
      question: {},
      questionBank: [],
      chooseIndex: null,
      trueIndex: null,
      score: 0,
      maskShow: false,
      finalAward: '',
      countTime: true,
      consumeTimeNum: 0,
      nextClick: false
    }
  },
  created() {
    this.getName()
    this.getQuestion()
  },
  methods: {
    getName() {
      const name = localStorage.getItem('name')
      this.getCheck(name)
    },
    getCheck(name) {
      getCheckAnswer(name).then(res => {
        if (res.data.code === 200) {
          this.nextClick = res.data.data
        }
      })
    },
    // 倒计时结束
    countDowmEnd(e) {
      if (e === true) {
        this.showAward()
      }
    },
    // 获取上次答题剩余时间
    getResidueTime() {
      if (localStorage.getItem('minute') && localStorage.getItem('second')) {
        const minute = parseInt(localStorage.getItem('minute'))
        const second = parseInt(localStorage.getItem('second'))
        return minute * 60 + second
      }
    },
    // 获取奖励显示奖励
    showAward(){
      getAnswerReward({
        answerNum: this.score,
        userName: localStorage.getItem('name')
      }).then(res => {
        if (res.data.code === 200) {
          console.log(res)
          if(res.data.data.isPrize === true){
            this.finalAward = res.data.data.prize
            this.maskShow = true
            this.$refs.count.countDowmEnd()
          }
        if(res.data.data.isPrize === false){
          this.$toast(res.data.data.prize)
        }
        }
      })
    },
    // 计算花费时间
    consumeTime(e) {
      this.consumeTimeNum = this.timeNormal - e
    },
    // 获取题库
    getQuestion() {
      getOnlineActivity('topic').then(res => {
        if (res.data.code === 200) {
          this.question = JSON.parse(res.data.data)
          this.timeNormal = this.question.time
          if (this.getResidueTime()) {
            this.question.time = this.getResidueTime()
          } else {
            this.question.time = this.question.time * 60
          }
        }
      })
    },
    nextQuestion() {
      if (this.nextClick === true) {
        this.$toast('您已经参与过了')
        return
      }
      if ( this.chooseIndex === null) {
       this.$toast('请选择答案！')
        return;
      }
      if (this.questionNumber < 10) {
        this.questionNumber += 1
        this.chooseIndex = null
        this.trueIndex = null
      } else {
        this.showAward()
      }
    },
    chooseAnswer(index) {
      if (this.chooseIndex !== null) {
        return
      }
      const arr = this.question.topic[this.questionNumber - 1].options
      this.chooseIndex = index
      if (arr[index].serial === this.question.topic[this.questionNumber - 1].answer) {
        console.log('回答正确')
        this.score += 1
        this.trueIndex = index
      } else {
        console.log('回答错误')
      }
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background: #9D0011;
  position: relative;
}

.mask {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  z-index: 999;
  padding-top: 1.81rem;
}

.count {
  display: flex;
  justify-content: center;
  padding-top: 0.14rem;
}

.count_text {
  font-size: 0.66rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 0.92rem;
}

.answer_container {
  display: flex;
  justify-content: center;
  padding-top: 0.76rem;
  position: relative;
}

.answer_box {
  width: 6.86rem;
  height: 7.78rem;
  background: #FFFFFF;
  border-radius: 0.21rem;
  display: flex;
  justify-content: center;
}

.question_number {
  position: absolute;
  width: 1.32rem;
  height: 1.32rem;
  top: 0.1rem;
  background: #FFFFFF;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.number_border {
  width: 0.98rem;
  height: 0.98rem;
  border: 0.08rem solid #9D0011;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.question_number_text {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #9D0011;
  line-height: 0.45rem
}

.button_box {
  display: flex;
  justify-content: center;
  position: absolute;
  bottom: 0.72rem;
  left: 0.42rem;
}

.button {
  width: 6.68rem;
  height: 1.09rem;
  background-image: url("../../../assets/button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button_text {
  font-size: 0.42rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #BD1314;
}

.ques_title_text {
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.solution_item {
  width: 5.9rem;
  height: 0.88rem;
  background: #F5F4F7;
  border-radius: 0.42rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem
}

.solution_item_true {
  width: 5.9rem;
  height: 0.88rem;
  background: #21AD37;
  border-radius: 0.42rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem
}

.solution_item_false {
  width: 5.9rem;
  height: 0.88rem;
  background: #D60100;
  border-radius: 0.42rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0.4rem
}

.solution_item_text {
  font-size: 0.34rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.mask_image {
  width: 5.38rem;
  height: 3.02rem;
  background: rgba(255, 85, 0, 0.3);
  position: absolute;
  z-index: 999;
}

.mask_box {
  width: 5.38rem;
  height: 5rem;
  background: linear-gradient(153deg, #FEE7D8 0%, #FEEBE4 54%, #FEEEFB 100%);
  border-radius: 0.31rem;
  margin-top: 2.07rem;
}

.mask_title {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #BD1314;
  padding-top: 0.9rem;
  text-align: center;
}

.mask_subTitle {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #BD1314;
  text-align: center;
  padding-top: 0.1rem;
}

.mask_awardDetail {
  width: 4.52rem;
  height: 2.61rem;
  background: #FFFBFB;
  box-shadow: 0 0.02rem 0.14rem 0 #F6E2EE;
  border-radius: 0.1rem;
  margin-top: 0.3rem;
  margin-left: 0.43rem;
}

.mask_awardDetail_title {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #BD1314;
  text-shadow: 0 0.02rem 0.14rem #F6E2EE;
  text-align: center;
  padding-top: 0.63rem;
}

.mask_awardDetail_subtitle {
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 400;
  color: #BD1314;
  text-shadow: 0 0.02rem 0.14rem #F6E2EE;
  text-align: center;
  padding: 0.23rem 0.56rem;
}
</style>
