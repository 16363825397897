<template>
  <div class="home">
    <div>
      <router-link to="/voteList">
        理事投票
      </router-link>
    </div>
    <div>
      <router-link to="/auditList">
        会员审核
      </router-link>
    </div>
    <div>
      <router-link to="/newestList">
        最新活动
      </router-link>
    </div>
    <div>
      <router-link to="/benefitList">
        公益活动
      </router-link>
    </div>
    <div>
      <router-link to="/farmerList">
        双标双引
      </router-link>
    </div>
    <div>
      <router-link to="/guizhouList">
        贵人活动
      </router-link>
    </div>
    <div>
      <router-link to="/activityDetail">
        活动详情
      </router-link>
    </div>
    <div>
      <router-link to="/activity">
        活动报名
      </router-link>
    </div>
    <div>
      <router-link to="/activitySuccess">
        报名成功
      </router-link>
    </div>
    <div>
      <router-link to="/center">
        会员中心
      </router-link>
    </div>
    <div>
      <router-link to="/apply">
        会员申请协议
      </router-link>
    </div>
    <div>
      <router-link to="/stamp">
        加盖公章
      </router-link>
    </div>
    <div>
      <router-link to="/applyMsg">
        会员申请
      </router-link>
    </div>
    <div>
      <router-link to="/council">
        加入理事会申请
      </router-link>
    </div>
    <div>
      <router-link to="/success">
        会员申请成功
      </router-link>
    </div>
    <div>
      <router-link to="/membersList">
        会员名录（部分）
      </router-link>
    </div>
    <div>
      <router-link to="/issue">
        会员自助
      </router-link>
    </div>
    <div>
      <router-link to="/issueSuccess">
        会员自助提交成功
      </router-link>
    </div>
    <div>
      <router-link to="/news">
        新闻动态
      </router-link>
    </div>
    <div>
      <router-link to="/leader">
        商会领导
      </router-link>
    </div>
    <div>
      <router-link to="/leaderDetail">
        领导详情
      </router-link>
    </div>
    <div>
      <router-link to="/audit">
        会员申请审核
      </router-link>
    </div>
    <div>
      <router-link to="/presidentVote">
        加入理事会投票
      </router-link>
    </div>
    <div>
      <router-link to="/partner">
        友好商会
      </router-link>
    </div>
    <div>
      <router-link to="/issueList">
        供需列表
      </router-link>
    </div>
    <div>
      <router-link to="/myIssue">
        我发布的供需
      </router-link>
    </div>
    <div>
      <router-link to="/issueDetail">
        供需详情
      </router-link>
    </div>
    <div>
      <router-link to="/myActivity">
        我报名的活动
      </router-link>
    </div>
    <div>
      <router-link to="/article">
        商会简介
      </router-link>
    </div>
    <div>
      <router-link to="/service">
        商会服务
      </router-link>
    </div>
    <div>
      <router-link to="/binding">
        绑定身份
      </router-link>
    </div>
    <div>
      <router-link to="/excellent">
        会员风采
      </router-link>
    </div>
    <div>
      <router-link to="/memberDetail">
        会员风采
      </router-link>
    </div>
    <div>
      <router-link to="/contact">
        联系商会
      </router-link>
    </div>
    <div>
      <router-link to="/mapIndex">
        商会服务地图
      </router-link>
    </div>
    <div>
      <router-link to="/settled">
        入驻申请
      </router-link>
    </div>
    <div>
      <router-link to='/memberIssue'>
        会员发布
      </router-link>
    </div>

  </div>
</template>

<script>
export default {
  name: "home"
}
</script>

<style scoped>
.home {
  height: 100vh;
  overflow: scroll;
  font-size: 0.5rem;
}
</style>
