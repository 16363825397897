<template>
  <div class="container">
    <div class="rules_box">
      <div class="title">
        <span>·</span>
        <span style="padding: 0 0.27rem">活动规则</span>
        <span>·</span>
      </div>
      <div class="rules_content">
        <div style="padding: 0.22rem">
          <div class="time">
            时间：2021年9月19日~7月30日
          </div>
          <div class="rules_title">
            规则
          </div>
          <div class="rules_content_item">
            <div class="dot">
              ·
            </div>
            <div class="content">
              可生成送给小金的专属生日海报，转发朋友圈，获得抽奖资格，赢取中国金币提供的限量版收藏海报；
            </div>
          </div>
          <div class="rules_content_item">
            <div class="dot">
              ·
            </div>
            <div class="content">
              活动期间，每天抽取10名幸运微友，次日公布中奖结果；
            </div>
          </div>
          <div class="rules_content_item">
            <div class="dot">
              ·
            </div>
            <div class="content">
              转发到朋友圈的海报，获得50个以上好友点赞，扫描二维码，加小金好友，进入“生日祝福微友群”，可同时参加群内抽奖；
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="agreement">
      <div class="radio" @click="agree">
          <div class="radio_true" v-if="show"></div>
      </div>
      <div class="agreement_text">
        同意答题规则
      </div>
    </div>
    <div class="start_button" @click="jump">
      <div class="start_button_text">
        开始答题
      </div>
    </div>
  </div>
</template>

<script>
import {getOnlineActivity} from "@/common/api";

export default {
  name: "rules",
  data() {
    return {
      show:false
    }
  },
  created() {
  },
  methods: {
    agree(){
      this.show = !this.show
    },
    jump(){
      if(this.show === false){
        this.$toast('请先同意答题规则！')
        return
      }
      this.$router.push('/answer')
    }
  }
}
</script>

<style scoped>
.container {
  width: 100%;
  height: 100vh;
  background: #B20100;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.rules_box {
  width: 6.86rem;
  height: 8.17rem;
  background: #D81223;
  border-radius: 0.2rem;
  position: absolute;
  top: 0.32rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  text-align: center;
  color: #FFFFFF;
  font-size: 0.48rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  padding: 0.28rem 0;
}

.rules_content {
  width: 6.22rem;
  height: 6.62rem;
  background: #FFFFFF;
  border-radius: 0.2rem;
}

.time {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-top: 0.23rem;
}

.rules_title {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #333333;
  margin-top: 0.46rem;
  margin-left: 0.26rem;
}

.rules_content_item {
  display: flex;
}

.content {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 0.4rem;
  margin-left: 0.16rem;
  margin-top: 0.3rem;
}

.dot {
  font-size: 1rem;
  color: #CE151E;
  margin-top: -0.23rem;
}
.agreement {
  position: absolute;
  top: 9.21rem;
  display: flex;
  align-items: center;
}
.radio {
  width: 0.32rem;
  height: 0.32rem;
  border: 0.02rem solid #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  margin-right: 0.16rem;
}
.radio_true {
  width: 0.16rem;
  height: 0.16rem;
  background: #FFFFFF;
  border-radius: 100%;
}
.agreement_text {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 0.2rem;
  margin-left: 0.16rem;
}
.start_button {
  width: 6.68rem;
  height: 1.09rem;
  background-image: url("../../../assets/button.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0.72rem;
  left: 0.42rem;
}
.start_button_text {
  font-size: 0.42rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #BD1314;
}
</style>
