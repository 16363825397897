<template>
  <div class="excellent-page">
    <!--    <scroller  :on-infinite="infinite" ref="my_scroller" :noDataText="''">-->
    <!--    <div class="excellent-title">-->
    <!--      会员风采-->
    <!--    </div>-->
    <div class="excellent-name">
      <img :src="wechatConfig.logo" style="width: 0.5rem;height: 0.5rem;border-radius: 100rem"/>
      <div class="excellent-name-text">
        {{wechatConfig.wxName}}
      </div>
    </div>
    <div class="excellent-topList">
      <slider ref="slider" :options="options">
        <!-- slideritem wrapped package with the components you need -->
        <slideritem v-for="(item,index) in topList" :key="index" @tap="jumpDetail(item.excellentMemberId)">
          <div class="slider-item">
            <div class="news-image" style="width: 100%;height: 2.5rem">
              <img :src="item.image" style="width: 100%;height: 2.5rem"/>
            </div>
            <div class="news-item-bg" style="width: 100%" >
              <div style="width: 55%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;margin-left: 0.1rem;color: #000000">
                {{ item.title }}
              </div>
            </div>
          </div>
        </slideritem>
      </slider>
      <!--      <el-carousel trigger="click" height="150px" :autoplay="false" v-if="topList.length > 0">-->
      <!--        <el-carousel-item v-for="(item,index) in topList" :key="index">-->
      <!--          <div class="news-image">-->
      <!--            <img :src="item.image" style="width: 100%;height: 1.8rem"/>-->
      <!--          </div>-->
      <!--          <div class="news-item-bg">-->
      <!--            <span-->
      <!--                style="width: 55%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;margin-left: 0.1rem">{{ item.title }}</span>-->
      <!--          </div>-->
      <!--        </el-carousel-item>-->
      <!--      </el-carousel>-->
    </div>
    <div class="news-body-title">
      <div class="news-body-title-text">
        会员风采
      </div>
    </div>
    <div class="list-box-activity">
      <div class="infinite-list-wrapper"  v-infinite-scroll="next" :infinite-scroll-disabled="listLoad" style="overflow:auto">
        <div class="list-item-activity" v-for="(item,index) in excellentList" :key="index" @click="jumpDetail(item.excellentMemberId)">
          <div class="item-activity">
            <div class="title-activity" style="width: 55%;overflow: hidden;text-overflow:ellipsis;white-space: nowrap;">
              <span>{{item.title}}</span>
            </div>
            <img style="width:1rem;height:1rem" :src="item.logo"/>
          </div>
        </div>
        <p class="isEmpty" v-if="listLoad">没有更多了</p>
      </div>
    </div>

    <!--    <div class="empty" v-else>-->
    <!--      <div>-->
    <!--        暂无公益活动-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    </scroller>-->
  </div>
</template>

<script>
import {getExcellentList,getWechatConfig} from "@/common/api";
import {slider, slideritem} from 'vue-concise-slider'
export default {
  components: {
    slider,
    slideritem
  },
  data() {
    return {
      excellentList: [],
      topList:[],
      wechatConfig:{},
      options: {
        pagination: false,
        centerSlides: false,
        thresholdDistance: 100, // Sliding distance threshold
        thresholdTime: 300, // Sliding time threshold decision
        grabCursor: true, // Scratch style
        speed: 300 // Sliding speed
        // timingFunction: 'ease', // Sliding mode
        // loop: false, // Infinite loop
        // autoplay: 0 // Auto play[ms]
      },
      pageSize:10,
      pageNum: 1,
      total: 0,
      listLength: 0,
      listLoad: true // 是否还有上啦加载
    };
  },
  mounted() {

  },
  created() {
    this.getWxConfigId();
    this.getTopList();
    this.initList();
    this.getConfig();
  },
  methods: {
    infinite() {
      setTimeout(() => {
        if(this.listLength >= this.total){
          this.$refs.my_scroller.noDataText="没有更多数据了"//更改上拉加载的文字
          this.$refs.my_scroller.finishInfinite(true);
        }else{
          this.pageNum = this.pageNum + 1;
          this.getList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 1500)
    },
    initList(){
      this.excellentList = []
      this.pageSize = 10
      this.pageNum = 1
      this.total = 0
      this.listLength = 0
      this.getList()
    },
    next(){
      this.pageNum = this.pageNum + 1
      this.getList()
    },
    getList(){
      getExcellentList({
        wxConfigId:this.wxConfigId,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if(res.data.code === 200){
          this.excellentList = this.excellentList.concat(res.data.rows);
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;
          if(this.total > this.listLength){
            this.listLoad = false
          } else {
            this.listLoad = true
          }
        }
      });
    },
    getTopList(){
      getExcellentList({
        isTop:1,
        wxConfigId:this.wxConfigId
      }).then(res => {
        this.topList = res.data.rows;
      });
    },
    jumpDetail(id){
      this.$router.push({
        path:'/memberDetail',query:{
          id:id,
          wechatConfig:this.wechatConfig
        }
      })
    },
    //获取wxConfigId
    getWxConfigId(){
      let configId = this.getQueryString('wxConfigId');
      localStorage.setItem('wxConfigId', configId);
      this.wxConfigId = configId
    },
    getQueryString(name){
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if(r!=null)return unescape(r[2]); return null;
    },
    getConfig(){
      getWechatConfig({
        wxConfigId:this.wxConfigId
      }).then(res=>{
        if(res.data.code===200){
          this.wechatConfig = res.data.data.data
        }
      })
    }
  }
};
</script>

<style scoped>
.excellent-title {
  font-size: 0.3rem;
  padding: 0.2rem;
}
.excellent-name {
  display: flex;
  margin-left: 0.2rem;
}
.excellent-name-text {
  font-size: 0.25rem;
  color: #169BD5;
  display: flex;
  align-items: center;
  margin-left: 0.1rem;
}
.excellent-topList {
  margin-top: 0.2rem;
}

.news-page {
  height: 100vh;
  overflow: scroll;
}
.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}
.news-title {
  font-size: 0.3rem;
  padding:0.1rem 0.4rem;

}
.news-logo {
  display: flex;
  padding: 0.2rem;
  height: 0.5rem;
  align-items: center;
  margin-left: 0.2rem;
}
.news-name {
  font-size: 0.2rem;
  color: #169BD5;
  margin-left: 0.2rem;
}
.slider-item {
  display: flex;
  flex-direction: column;
}
.news-image {
  height: 1.8rem
}
.news-item-bg {
  background-color: #F2F2F2;
  height: 0.6rem;
  font-size: 0.2rem;
  display: flex;
  align-items: center;
  text-align:left;
}
.news-body-title {
  display: flex;
  justify-content: center;
  border-bottom: 0.01rem solid #F2F2F2;
  padding-bottom: 0.05rem;
}
.news-body-title-text {
  font-size: 0.3rem;
  color: #4ECE5B;
  padding-top: 0.3rem;
}
.news-body-title-text::after {
  content: '';
  width: 40%;
  height: 1px;
  display: block;
  margin: 0 auto;
  border-bottom: 0.05rem solid #4ECE5B;
  padding-bottom: 0.1rem;
}
.item-activity {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.1rem 0;
}
.list-item-activity {
  border-bottom: 0.01rem solid #F2F2F2;
  padding: 0 0.3rem;
}
.title-activity {
  font-size: 0.3rem;
}
.search {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
}
.news-head {
  padding: 0.32rem;
}
.nav {
  display: flex;
  justify-content: space-between;
  color: #333333;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;

}

.active {
  border-bottom: 0.05rem solid #FF5740;
  padding-bottom: 0.1rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #333333;
}
.list-item-news {
  margin: 0 0.32rem;
  border-bottom: 0.01rem solid #E6E4E1;
}
.item-news {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

}

.title-news {
  margin-left: 0.1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

.scrolldiv {
  height: 70vh;
}

.isEmpty {
  color: rgb(170, 170, 170);
  text-align: center;
  font-size: 14px;
}
</style>
