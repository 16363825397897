<template>
  <div class="excellent-page">
    <div class="excellent-title">
      【会员风采】{{detail.title}}
    </div>
    <div class="excellent-name">
      <img :src="wechatConfig.logo" style="width: 0.5rem;height: 0.5rem;border-radius: 100rem"/>
      <div class="excellent-name-text">
        {{wechatConfig.wxName}}
      </div>
    </div>
    <div class="excellent-body-title">
      <img :src="detail.image" style="width: 100%;height: 2.5rem"/>
    </div>
    <div class="excellent-body-memberName">
      <img :src="detail.logo" style="width: 1.5rem;height: 1.5rem;margin-right:0.15rem" />
      {{detail.title}}
    </div>
    <div class="excellent-body-content">
      <div class="ql-editor" v-html="detail.content"></div>
    </div>
  </div>
</template>

<script>
import {getExcellentDetail} from "@/common/api";

export default {
  data() {
    return {
      logo:'',
      name:'',
      excellentList: [],
      topList:[],
      wechatConfig:{},
      detail:{}
    };
  },
  mounted() {

  },
  created() {
    this.wechatConfig = this.$route.query.wechatConfig
    this.getExcellentDetail(this.$route.query.id)
  },
  methods: {
    getExcellentDetail(id){
      getExcellentDetail(id).then(res=>{
        if(res.data.code === 200){
          this.detail = res.data.data
        }
      })
    }
  }
};
</script>

<style scoped>
.excellent-page{
  height: 100vh;
  overflow: scroll;
}
.excellent-title {
  font-size: 0.3rem;
  padding: 0.2rem;
}
.excellent-name {
  display: flex;
  margin-left: 0.3rem;
}
.excellent-name-text {
  font-size: 0.25rem;
  color: #169BD5;
  display: flex;
  align-items: center;
  margin-left: 0.1rem;
}
.excellent-body-title {
  margin-top: 0.2rem;
}
.excellent-body-memberName {
  font-size: 0.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 0.01rem solid #F2F2F2;
  padding: 0.1rem 0;
}
.excellent-body-content {
  padding: 0.2rem 0.5rem;
  font-size: 0.3rem;
}
</style>
