<template>
  <div class="presidentVote-page">
    <div class="presidentVote-box">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="个人资料" name="2" >
          <div class="form">
            <el-form ref="form" :model="wxUserInfo" label-width="1.5rem" >
              <el-form-item label="姓名" style="margin-top: 0.2rem">
                <el-input disabled v-model="wxUserInfo.nickName" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-input disabled v-model="wxUserInfo.sex" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="籍贯">
                <el-input disabled v-model="wxUserInfo.hometown" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="证件号码">
                <el-input disabled v-model="wxUserInfo.idCard" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="政治面貌">
                <el-input disabled v-model="wxUserInfo.politicalStatus" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="工作单位">
                <el-input disabled v-model="wxUserInfo.employer" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="职务">
                <el-input disabled v-model="wxUserInfo.jobTitle" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="个人履历">
                <el-input disabled v-model="wxUserInfo.personalProfile" placeholder="必填" type="textarea"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="社团申请人企业资料" name="1" style="margin-top: 0.4rem">
          <div class="form">
            <el-form ref="form" :model="company" label-width="1.5rem" >
              <el-form-item label="会员类型" style="margin-top: 0.2rem">
                <el-select disabled v-model="role" style="width: 5.45rem">
                  <el-option label="普通用户" value="0"></el-option>
                  <el-option label="会员" value="1"></el-option>
                  <el-option label="理事" value="2"></el-option>
                  <el-option label="监事" value="3"></el-option>
                  <el-option label="常务副会长" value="4"></el-option>
                  <el-option label="副会长" value="5"></el-option>
                  <el-option label="会长" value="6"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="会员类别">
                <el-select v-model="wxUserInfo.category"  disabled  style="width: 5.45rem">
                  <el-option label="独立法人" value="A"></el-option>
                  <el-option label="合伙人" value="B"></el-option>
                  <el-option label="在职人员" value="C"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="企业名称">
                <el-input disabled v-model="company.companyName"></el-input>
              </el-form-item>
              <el-form-item label="通讯地址">
                <el-input disabled v-model="company.companyAddress"></el-input>
              </el-form-item>
              <el-form-item label="主营业务">
                <el-input disabled v-model="company.mainBusiness"></el-input>
              </el-form-item>
              <el-form-item label="企业法人">
                <el-input disabled v-model="company.legalPerson"></el-input>
              </el-form-item>
              <el-form-item label="注册资金">
                <el-input disabled v-model="company.registeredCapital"></el-input>
              </el-form-item>
              <el-form-item label="邮政编码">
                <el-input disabled v-model="company.postalCode"></el-input>
              </el-form-item>
              <el-form-item label="诚信记录">
                <div>
                  <div>
                    企业及本人在三年内是否有不诚信行为或违法记录
                  </div>
                  <div class="vote-radio">
                    <el-radio v-model="company.isNoSincerity" :label="1" disabled>是</el-radio>
                    <el-radio v-model="company.isNoSincerity" :label="0" disabled>否</el-radio>
                  </div>
                </div>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="通讯资料" name="3" style="margin-top: 0.4rem">
          <div class="form">
            <el-form ref="form" :model="wxUserInfo" label-width="1.5rem" >
              <el-form-item label="联系人" style="margin-top: 0.2rem">
                <el-input disabled v-model="wxUserInfo.nickName" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="手机">
                <el-input disabled v-model="wxUserInfo.phone" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="传真号码">
                <el-input disabled v-model="wxUserInfo.faxNumber" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="电子邮箱">
                <el-input disabled v-model="wxUserInfo.email" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="备注">
                <el-input disabled v-model="wxUserInfo.remarks" placeholder="请填写" type="textarea"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="证照资料" name="4" style="margin-top: 0.4rem">
          <div class="upload-box">
            <div class="enterprise">
              <div>
                <img style="width: 2rem;height: 2rem" :src="company.businessLicense" />
              </div>
              <div class="enterprise-title">
                企业营业执照复印件
              </div>
            </div>
            <div class="line"></div>
            <div class="enterprise">
              <div class="idImage">
                <img v-preview="wxUserInfo.idCardPhoto1" style="width: 2rem;height: 2rem;margin-bottom: 0.1rem" :src="wxUserInfo.idCardPhoto1" />

                <img v-preview="wxUserInfo.idCardPhoto2" style="width: 2rem;height: 2rem;margin-top: 0.1rem" :src="wxUserInfo.idCardPhoto2" />
              </div>
              <div class="enterprise-title">
                申请人身份证复印件
              </div>
            </div>
            <div class="line"></div>
            <div class="enterprise">
              <div>
                <img v-preview="wxUserInfo.photo" style="width: 2rem;height: 2rem" :src="wxUserInfo.photo" />
              </div>
              <div class="enterprise-title">
                申请人一寸证件照
              </div>
            </div>
          </div>
        </el-collapse-item>
        <el-collapse-item title="投票进度" name="5" style="margin-top: 0.4rem">
          <div class="process" style="font-size: 0.3rem;display: flex;margin-top: 0.2rem">
            <span style="flex-basis: 35%">应投票人数</span>
            <span style="flex-basis: 35%">有效票数</span>
            <span style="flex-basis: 35%">投票百分比</span>
          </div>
          <div class="process" style="font-size: 0.3rem;display: flex">
            <span style="flex-basis: 35%">{{votingPercentageData.total || 0}}</span>
            <span style="flex-basis: 35%">{{votingPercentageData.numberVoters || 0}}</span>
            <span style="flex-basis: 35%">{{votingPercentageData.percentageVotes || 0}}</span>
          </div>
        </el-collapse-item>
        <el-collapse-item title="参投名单" name="6" style="margin-top: 0.4rem">
          <div class="process" style="font-size: 0.3rem;display: flex;margin-top: 0.2rem;flex-wrap: wrap">
            <span style="flex-basis: 20%" v-for="(item,index) in votingPercentageData.listOfVoters" :key="index">{{item}}</span>
          </div>
        </el-collapse-item>
      </el-collapse>
    </div>
    <div class="vote-submitt">
      <div class="vote-submit-button">
        <div class="vote-submit-button-vote"  @click="dialogTableVisible = true">投票</div>
      </div>
    </div>
    <div class="vote-modal" >
    <el-dialog title="投票" style="font-weight: 600" center :visible.sync="dialogTableVisible">
        <div class="vote-modal-title">
          审批意见
        </div>
        <div class="vote-radio">
          <el-radio v-model="radio" label="1">赞成</el-radio>
          <el-radio v-model="radio" label="2">不赞成</el-radio>
          <el-radio v-model="radio" label="3">弃权</el-radio>
        </div>
        <div class="vote-modal-title">
          审批意见
        </div>
        <div class="vote-modal-content">
          <el-input type="textarea"  placeholder="请输入审批意见" v-model="opinion"></el-input>
        </div>
        <div class="vote-modal-button" @click="onSubmit">
          提交
        </div>
    </el-dialog>
    </div>
  </div>
</template>

<script>
import {getDemandDetail, getSecretaryApproval, getVotingPercentage} from "@/common/api";
export default {
  data() {
    return {
      id: null,
      uuid: null,
      councilId:'',
      role:'',
      opinion:'',
      radio: '1',
      dialogTableVisible: false,
      activeName: ['1','2','3','4'],
      company: {
        character: '',
        companyName: '',
        address: '',
        mainBusiness: '',
        legalPerson: '',
        registeredFund: '',
        postalCode: ''
      },
      wxUserInfo: {
        nickName: '',
        sex: '',
        hometown: '',
        idCard: '',
        politicsStatus: '',
        employer: '',
        jobTitle: '',
        phone:'',
        faxNumber:'',
        email:'',
        remark:'',
        personalProfile:''
      },
      votingPercentageData:{}
    }
  },
  created() {
    this.uuid = this.getQueryString('uuid')
    getDemandDetail(this.getQueryString('id')).then(res=>{
      this.wxUserInfo = res.data.data.wxUserInfo;
      this.company = res.data.data.company;
      this.role = res.data.data.role.toString();
      this.councilId = res.data.data.councilId;
      this.wxUserInfo.sex = parseInt(res.data.data.wxUserInfo.sex) === 1?'男':'女'
    })
    getVotingPercentage({
      applicationId: this.getQueryString('id'),
      type: 2
    }).then(res=>{
      if(res.data.code === 200) {
        this.votingPercentageData = res.data.data
      }
    })
  },
  methods: {
    onSubmit() {
      getSecretaryApproval({
        aidit: parseInt(this.radio),
        councilId: this.councilId,
        opinion: this.opinion,
        stage: 1,
        uuid: this.uuid
      }).then(res=>{
        if(res.data.code === 200){
          this.$toast("提交成功", {
            durtaion: 200
          });
          this.dialogTableVisible = false;
        }else {
          this.$toast(res.data.msg||"提交失败", {
            durtaion: 200
          });
          this.dialogTableVisible = false;
        }
      })
    },
// 获取地址栏参数
    getQueryString(id) {
      var reg = new RegExp("(^|&)" + id + "=([^&]*)(&|$)", "i");
      var reg_rewrite = new RegExp("(^|/)" + id + "/([^/]*)(/|$)", "i");
      var r = window.location.search.substr(1).match(reg);
      var q = window.location.pathname.substr(1).match(reg_rewrite);
      if(r != null){
        return unescape(r[2]);
      }else if(q != null){
        return unescape(q[2]);
      }else{
        return null;
      }
    },
  }
}
</script>
<style scoped>
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #000000!important;
}
/deep/ .el-textarea__inner{
  min-height: 5rem!important;
}
.presidentVote-page {
  height: 100%;
  overflow: scroll;
}
.presidentVote-box {

}
.form{
  padding: 0 0.26rem;
}
.vote-submit-button {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 0.8rem;
  margin-top: 0.5rem;
}

.vote-submit-button-vote {
  width:6rem;
  height: 0.98rem;
  background: #DDB06C;
  border-radius: 1rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
}
.upload-box {
  padding: 0.26rem;
}
.enterprise {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
}
.enterprise-title {
  font-size: 0.28rem;
  margin-left: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.idImage {
  display: flex;
  flex-direction: column;
}
.line {
  height: 0.01rem;
  background-color: #E6E6E6;
}
.custom-class {
  width: 5.52rem !important;
  min-width: 5.52rem !important;
}
.vote-modal-title {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding-bottom: 0.1rem;
  padding-top: 0.15rem;

}
.vote-radio {
  margin-bottom: 0.1rem;
  margin-top: 0.1rem;
}
/deep/ .el-input__inner {
  border: 0;
  background-color: #F0F0F0;
}

/deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus {
  border: 0;
  outline: 0;
}
.textarea>>>.el-input__inner{
  background: #F0F0F0!important;
  font-size: 0.28rem!important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400!important;
  color: #333333!important;

}
.vote-modal-button {
  width: 4.6rem;
  height: 0.92rem;
  background: #DDB06C;
  border-radius: 0.08rem;
  margin-top: 0.5rem;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
}
/deep/.el-dialog{
  width: 5.6rem!important;
  margin:0!important;
  display:flex;
  flex-direction:column;
  max-height:calc(100% - 0.3rem);
  max-width:calc(100% - 0.3rem);
  border-radius: 0.16rem;
}
.el-dialog__body{
  transform: translate3d(0,0,0);
  overflow:auto;
}
.el-dialog__header {
  padding: 0 0 0 0!important;
  text-align: center;
  height: 0.8rem;
}
.el-dialog__footer {
  text-align: center!important;
  padding: 12px 20px 12px 20px!important;
  border-top: 1px solid #f4f4f4;
}

.el-dialog__wrapper {
  display:flex;
  justify-content: center;
  align-items:center;
}
.el-radio__input.is-checked .el-radio__inner {
  border-color: #DDB06C;
  background-color: #DDB06C;
}
.el-radio__inner:hover {
  border-color: #DDB06C;
}
.el-radio__input.is-checked + .el-radio__label {
  font-size: 0.28rem!important;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400!important;
  color: #333333!important;
}


</style>
