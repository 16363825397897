import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import VueRouter from 'vue-router'
import routers from './routers'
import axios from 'vue-axios'
import store from "@/common/store";
import VueScroller from "vue-scroller"
import {Alert, Confirm, Toast, Loading} from 'wc-messagebox'
import 'wc-messagebox/style.css'
import VueAMap from 'vue-amap';
import VueCropper from 'vue-cropper'
// 播放器插件
import VideoPlayer from 'vue-video-player'

require('video.js/dist/video-js.css')
require('vue-video-player/src/custom-theme.css')
VueAMap.initAMapApiLoader({
  key: '5491e71b6de4bb7b92d8865f4d508468',
  // 插件集合 （插件按需引入）
  plugin: [
    "AMap.Autocomplete", //输入提示插件
    "AMap.PlaceSearch", //POI搜索插件
    "AMap.Scale", //右下角缩略图插件 比例尺
    "AMap.OverView", //地图鹰眼插件
    "AMap.ToolBar", //地图工具条
    "AMap.MapType", //类别切换控件，实现默认图层与卫星图、实施交通图层之间切换的控制
    "AMap.PolyEditor", //编辑 折线多，边形
    "AMap.CircleEditor", //圆形编辑器插件
    "AMap.Geolocation" //定位控件，用来获取和展示用户主机所在的经纬度位置
  ],
  uiVersion: "1.0"
});
import Vant from 'vant';
import 'vant/lib/index.css';
Vue.use(Vant);

Vue.use(Alert)
Vue.use(Confirm)
Vue.use(Toast, 1000)
Vue.use(Loading)
Vue.use(VueCropper)
Vue.use(VueAMap);
Vue.use(ElementUI);
Vue.use(VueRouter);
Vue.use(VueScroller);
Vue.use(VideoPlayer)

const router = new VueRouter({
  mode: 'history',
  routes: routers
})

Vue.prototype.$axios = axios
router.beforeEach((to, from, next) => {
  if (to.name) {
    document.title = to.name
  }
    next()
})


Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
});


