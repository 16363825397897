<template>
  <div class="apply-page">
    <!-- <div class="head">
      <div class="apply-title">
        {{ wechatConfig.wxName }}章程
      </div>
    </div> -->
    <div class="goBottom">
      <a href="#bottom"><el-button type="primary" icon="el-icon-bottom" circle size="small"></el-button></a>
    </div>
    <div class="content">
      <div class="content-text">
        <div class="ql-editor" v-html="wechatConfig.bylaws"></div>
      </div>
    </div>
    <div class="submit-apply">
      <div class="submit-button-apply" @click="toApply">
        <div id="bottom">同意并继续申请</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getLogin, getWechatConfig} from "@/common/api";
import config from "@/common/config";

export default {
  data() {
    return {
      wechatConfig: '',
      wxConfigId: '',
      appid: '',
      isMember:false
    }
  },
  created() {
    this.initConfig();
    this.getConfig();
  },
  methods: {
    //判断是否登录
    getCode() {
      const code = this.getUrlCode().code // 截取code
      const local = config.redirecturl + 'apply?wxConfigId=' + this.wxConfigId + '&appid=' + this.appid; // 获取页面url ${encodeURIComponent(local)}
      const appid = this.appid;
      const wxConfigId = this.wxConfigId
      if (!code) {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        getLogin({
          code: code,
          wxConfigId: wxConfigId
        }).then(res => {
          this.isMember = res.data.code === 200;
        })
      }
    },
    getUrlCode() { // 截取url中的code方法
      const url = location.search;
      this.winUrl = url
      const theRequest = new Object();
      if (url.indexOf("?") !== -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    },
    /***
     * 获取上个页面传递的wxConfigId appid
     */
    toApply() {
      if(this.isMember === true){
        this.$toast('您已经是会员了，无需再次申请！')
      }else {
        this.$router.push({
          path: '/applyMsg'
        })
      }

    },
    getConfig() {
      getWechatConfig({
        wxConfigId: this.wxConfigId
      }).then(res => {
        if (res.data.code == 200) {
          this.wechatConfig = res.data.data.data
        }
      })
    },
    initConfig() {
      let wxId = this.getQueryString('wxConfigId')
      let appId = this.getQueryString('appid')
      localStorage.setItem('wxConfigId', wxId)
      localStorage.setItem('appid', appId)
      if (wxId) {
        this.wxConfigId = wxId
      }
      if (appId) {
        this.appid = appId
      }
      if (this.wxConfigId && this.appid) {
        this.getCode();
      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    }
  }
}
</script>

<style scoped>
.apply-page {
  width: 100%;
  height: 100vh;
  overflow: scroll;
}

.head {
  padding: 0.5rem;
}
.goBottom{
  position: fixed;
  top: 10px;
  right: 10px;

}
.apply-title {
  font-size: 0.3rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 700;
  color: #333333;
  text-align: center;
}

/* .content {
  padding: 0 0.1rem;
} */

.content-text {
  font-size: 0.34rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #333333;
}

.submit-apply {
  margin-bottom: 0.5rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.submit-button-apply {
  width: 5rem;
  height: 1rem;
  background: #DDB06C;
  border-radius: 0.1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
</style>
