<template>
  <div class="page">
    <div class="page-box">
      <el-collapse v-model="activeName" accordion>
        <el-collapse-item title="社团申请人企业资料" name="1">
          <div class="form">
            <el-form ref="form" :model="company" label-width="1.5rem">
<!--              <el-form-item label="会员类型">-->
<!--                <el-select v-model.number="character"  disabled placeholder="理事" style="width: 5.45rem">-->
<!--                  <el-option label="理事" :value="2"></el-option>-->
<!--                  <el-option label="监事" :value="3"></el-option>-->
<!--                  <el-option label="常务副会长" :value="4"></el-option>-->
<!--                  <el-option label="副会长" :value="5"></el-option>-->
<!--                  <el-option label="会长" :value="6"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="会员类别">-->
<!--                <el-select v-model="userInfo.category"  disabled  style="width: 5.45rem">-->
<!--                  <el-option label="独立法人" value="A"></el-option>-->
<!--                  <el-option label="合伙人" value="B"></el-option>-->
<!--                  <el-option label="在职人员" value="C"></el-option>-->
<!--                </el-select>-->
<!--              </el-form-item>-->
              <el-form-item label="企业名称">
                <el-input  v-model="company.companyName"></el-input>
              </el-form-item>
<!--              <el-form-item label="通讯地址">-->
<!--                <el-input  v-model="company.companyAddress"></el-input>-->
<!--              </el-form-item>-->
              <el-form-item label="主营业务">
                <el-input  v-model="company.mainBusiness"></el-input>
              </el-form-item>
<!--              <el-form-item label="企业法人">-->
<!--                <el-input  v-model="company.legalPerson"></el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="注册资金">-->
<!--                <el-input v-model="company.registeredCapital"></el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="邮政编码">-->
<!--                <el-input  v-model="company.postalCode"></el-input>-->
<!--              </el-form-item>-->
<!--              <el-form-item label="诚信记录">-->
<!--                <div>-->
<!--                  <div>-->
<!--                    企业及本人在三年内是否有不诚信行为或违法记录-->
<!--                  </div>-->
<!--                  <div class="vote-radio">-->
<!--                    <el-radio v-model="company.isNoSincerity" :label="1" >是</el-radio>-->
<!--                    <el-radio v-model="company.isNoSincerity" :label="0" >否</el-radio>-->
<!--                  </div>-->
<!--                </div>-->
<!--              </el-form-item>-->
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="个人资料" name="2">
          <div class="form">
            <el-form ref="form" :model="userInfo" label-width="1.5rem" >
              <el-form-item label="姓名" >
                <el-input disabled v-model="userInfo.nickName" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="性别">
                <el-input disabled v-model="userInfo.sex" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="籍贯">
                <el-input disabled v-model="userInfo.hometown" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="证件号码">
                <el-input disabled v-model="userInfo.idCard" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="政治面貌">
                <el-input disabled v-model="userInfo.politicalStatus" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="工作单位">
                <el-input disabled v-model="userInfo.employer" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="职务">
                <el-input disabled v-model="userInfo.jobTitle" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="个人简介">
                <el-input v-model="personalProfile" placeholder="必填" type="textarea"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="通讯资料" name="3">
          <div class="form">
            <el-form ref="form" :model="userInfo" label-width="1.5rem" >
              <el-form-item label="联系人" >
                <el-input disabled v-model="userInfo.nickName" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="手机">
                <el-input  v-model="userInfo.phone" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="传真号码">
                <el-input disabled v-model="userInfo.faxNumber" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="电子邮箱">
                <el-input v-model="userInfo.email" placeholder="请填写"></el-input>
              </el-form-item>
              <el-form-item label="其他方式">
                <el-input disabled v-model="userInfo.otherContact" placeholder="请填写"></el-input>
              </el-form-item>
            </el-form>
          </div>
        </el-collapse-item>
        <el-collapse-item title="证照资料" name="4">
          <div class="upload-box">
<!--            <div class="enterprise">-->
<!--              <div>-->
<!--                <img style="width: 2rem;height: 2rem" :src="company.businessLicense" />-->
<!--              </div>-->
<!--              <div class="enterprise-title">-->
<!--                企业营业执照复印件-->
<!--              </div>-->
<!--            </div>-->
            <div class="enterprise">
              <el-upload
                  class="avatar-uploader"
                  :action="uploadImgUrl"
                  :show-file-list="false"
                  :on-error="uploadError"
                  :on-success="handleUploadSuccess"
                  :before-upload="beforeAvatarUpload"
                  :on-preview="handlePictureCardPreview">
                <img  :src="company.businessLicense" style="width: 2rem;height: 2rem"/>
              </el-upload>
              <div class="enterprise-title">
                企业营业执照复印件
              </div>
            </div>
            <div class="line"></div>
            <div class="enterprise">
              <div class="idImage">
                <img style="width: 2rem;height: 2rem;margin-bottom: 0.1rem" :src="userInfo.idCardPhoto1" />

                <img style="width: 2rem;height: 2rem;margin-top: 0.1rem" :src="userInfo.idCardPhoto2" />
              </div>
              <div class="enterprise-title">
                申请人身份证复印件
              </div>
            </div>
            <div class="line"></div>
            <div class="enterprise">
              <div>
                <img style="width: 2rem;height: 2rem" :src="userInfo.photo" />
              </div>
              <div class="enterprise-title">
                申请人一寸证件照
              </div>
            </div>
          </div>
        </el-collapse-item>
      </el-collapse>
      <div class="button-apply">
        <div class="button-text-apply" @click="onSubmit">
          <div style="">
            提交
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getCouncil} from "@/common/api";
import {getUserInfo} from "@/common/api";
import uploadUrl from "@/common/uploadUrl";
export default {
  data() {
    return {
      personalProfile:'',
      uploadImgUrl: uploadUrl.uploadUrl,
      wxConfigId:'',
      wxUserId:'',
      character:2,
      activeName:'1',
      company: {
        companyName: '',
        companyAddress: '',
        mainBusiness: '',
        legalPerson: '',
        registeredCapital: '',
        postalCode: '',
        isNoSincerity:0
      },
      userInfo:{
        nickName:'',
        sex:'',
        hometown:'',
        idCard:'',
        politicsStatus:'',
        employer:'',
        jobTitle:'',
        phone:'',
        faxNumber:'',
        email:'',
        otherContact:'',
        category:''
      },
    }
  },
  created() {
      console.log()
    this.getUser();
  },
  methods: {

    getUser(){
      getUserInfo(
      ).then(res=>{
        if(res.data.code == 200){
          this.company = res.data.data.companyVo;
          this.userInfo = res.data.data.wxUserInfo;
          this.wxUserId = res.data.data.wxUserId;
          this.wxConfigId = res.data.data.wxConfigId;
          this.userInfo.sex = parseInt(res.data.data.wxUserInfo.sex) === 1?'男':'女';

        }
      })
    },
    onSubmit() {
      if(!this.personalProfile || this.personalProfile === ''){
        this.$toast("请填写个人简介")
        return;
      }
      getCouncil({
        role:parseInt(this.character),
        wxConfigId:this.wxConfigId,
        wxUserId:this.wxUserId,
        companyAddBo:this.company,
        phone:this.userInfo.phone,
        email:this.userInfo.email,
        personalProfile:this.personalProfile
      }).then(res=>{
        if(res.data.code == 200){
          this.$router.push({
            path:'/councilSuccess'
          })
        }else {
          this.$toast(res.data.msg || "申请失败", {
            durtaion: 200
          });
        }
      })
    },
    uploadError(){
      this.loading.close();
    },
    handleUploadSuccess(res) {
      this.$emit("input", res.data.url);
      this.companyImage = res.data.url;
      this.company.businessLicense = res.data.url;
      this.loading.close();
    },
    beforeAvatarUpload(file) {
      const isIMG = file.type.indexOf("image/") !== -1;
      const isLtM = file.size / 1024 / 1024 < 3;
      if (!isIMG) {
        this.$toast("只能上传图片文件", {
          durtaion: 200
        });
      }
      if (!isLtM) {
        this.$toast("上传图片大小不能超过 3MB", {
          durtaion: 200
        });
      }
      if (isLtM && isIMG) {
        this.loading = this.$loading({
          lock: true,
          text: '文件上传中，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      return isLtM && isIMG;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
  }
}
</script>

<style scoped>
.page {
  height:100vh;
  overflow:scroll;
}
.form {
  padding: 0.26rem;
}
.upload-box {
  padding: 0.26rem;
}
.enterprise {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
}
.enterprise-title {
  font-size: 0.28rem;
  margin-left: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
.idImage {
  display: flex;
  flex-direction: column;
}
.line {
  height: 0.01rem;
  background-color: #E6E6E6;
}
.button-apply {
  margin: 0.5rem 0;
  bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.button-text-apply {
  width: 6.5rem;
  height: 1rem;
  background-color: #DDB06C;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  border-radius: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
