<template>
  <active-list title="公益活动" :type="2" routeStr="benefitList"/>
</template>

<script>
import ActiveList from './components/ActiveList.vue';
export default {
  components: {
    ActiveList
  }
};
</script>
