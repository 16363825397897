import Activity from '../src/pages/activity/index'
import Home from '../src/pages/home'
import Center from '../src/pages/service/center/index'
import Apply from '../src/pages/service/apply/index'
import ApplyMsg from './pages/service/apply/member'
import Success from './pages/service/apply/councilSuccess'
import membersList from '../src/pages/service/directories/index'
import stamp from '../src/pages/service/apply/stamp'
import issue from '../src/pages/service/issue/index'
import issueSuccess from '../src/pages/service/issue/success'
import issueDetail from '@/pages/service/issue/detail'
import news from '../src/pages/news/index'
import leader from '../src/pages/situation/index'
import leaderDetail from '../src/pages/situation/detail'
import audit from './pages/message/audit'
import presidentVote from "@/pages/message/presidentVote";
import council from "@/pages/service/apply/council"
import partner from './pages/service/partner/index'
import activitySuccess from './pages/activity/success'
import issueList from "@/pages/service/issue/issueList"
import activityDetail from '@/pages/activity/detail'
import benefitList from "@/pages/activity/benefitList";
import farmerList from "@/pages/activity/farmerList";
import newestList from "@/pages/activity/newestList";
import guizhouList from "@/pages/activity/guizhouList";
import myActivity from "@/pages/activity/myActivity";
import myIssue from "@/pages/service/issue/myIssue";
import article from "@/pages/article/index";
import service from "@/pages/article/service";
import binding from "@/pages/service/center/binding";
import excellent from "@/pages/service/directories/excellent";
import memberDetail from '@/pages/service/directories/detail'
import contact from "@/pages/article/contact";
import downFile from "@/pages/service/apply/downFile"
import auditList from "@/pages/service/center/auditList";
import councilSuccess from "@/pages/service/apply/councilSuccess"
import map from "@/pages/service/map/map";
import mapA from '@/pages/service/dining/MapA'
import diningMapIndex from "@/pages/service/dining/mapIndex";
import diningList from '@/pages/service/dining/diningList'
import diningDetail from '@/pages/service/dining/diningDetail'
import mapSearch from '@/pages/service/dining/Search'
import settled from "@/pages/service/map/settled";
import settledSuccess from "./pages/service/map/success";
import memberIssue from "./pages/service/directories/memberIssue";
import examineCouncil from "@/pages/message/examineCouncil";
import proposal from "@/pages/message/proposal";
import answer from "@/pages/activity/instant/answer";
import instantActivity from "@/pages/activity/instant/index"
import rules from "@/pages/activity/instant/rules";
import reciprocal from "@/pages/activity/instant/reciprocal";

const routers = [
	{
		path: '/voteList',
		name: '理事投票',
		component: auditList
	},
	{
		path: '/proposalList',
		name: '提案投票',
		component: auditList
	},
	{
		path: '/auditList',
		name: '会员审核',
		component: auditList
	},
	{
		path: '/contact',
		name: '联系商会',
		component: contact
	},
	{
		path: '/memberDetail',
		name: '会员风采',
		component: memberDetail
	},
	{
		path: '/excellent',
		name: '会员风采',
		component: excellent
	},
	{
		path: '/binding',
		name: '绑定身份',
		component: binding
	},
	{
		path: '/article',
		name: '商会简介',
		component: article
	},
	{
		path: '/service',
		name: '商会服务',
		component: service
	},
	{
		path: '/newestList',
		name: '最新活动',
		component: newestList
	},
	{
		path: '/benefitList',
		name: '公益活动',
		component: benefitList
	},
	{
		path: '/farmerList',
		name: '活动',
		component: farmerList
	},
	{
		path: '/guizhouList',
		name: '贵人活动',
		component: guizhouList
	},
	{
		path: '/activityDetail',
		name: '报名链接',
		component: activityDetail
	},
	{
		path: '/activity',
		name: '活动报名',
		component: Activity
	},
	{
		path: '/activitySuccess',
		name: '报名成功',
		component: activitySuccess
	},
	{
		path: '/myActivity',
		name: '我的活动',
		component: myActivity
	},
	{
		path: '/',
		component: Home
	},
	{
		path: '/center',
		name: '会员中心',
		component: Center
	},
	{
		path: '/apply',
		name: '会员申请',
		component: Apply
	},
	{
		path: '/applyMsg',
		name: '会员申请资料',
		component: ApplyMsg
	},
	{
		path: '/success',
		name: '会员申请成功',
		component: Success
	},
	{
		path: '/councilSuccess',
		name: '理事申请成功',
		component: councilSuccess
	},
	{
		path: '/membersList',
		name: '会员名录',
		component: membersList
	},
	{
		path: '/stamp',
		name: '加盖公章',
		component: stamp
	}, {
		path: '/issue',
		name: '会员自助',
		component: issue
	}, {
		path: '/issueSuccess',
		name: '会员自助成功',
		component: issueSuccess
	}, {
		path: '/myIssue',
		name: '我发布的供需',
		component: myIssue
	}, {
		path: '/news',
		name: '新闻动态',
		component: news
	}, {
		path: '/issueDetail',
		name: '供需详情',
		component: issueDetail
	}, {
		path: '/leader',
		name: '理事监事',
		component: leader
	}, {
		path: '/leaderDetail',
		name: '详情简介',
		component: leaderDetail
	}, {
		path: '/audit',
		name: '会员申请审核',
		component: audit
	}, { // 理事审核
		path: '/presidentVote',
		name: '加入理事会投票',
		component: presidentVote
	}, {
		path: '/council',
		name: '加入理事会申请',
		component: council
	}, {
		path: '/partner',
		name: '友好商会',
		component: partner
	}, {
		path: '/issueList',
		name: '会员自助',
		component: issueList
	}, {
		path: '/downFile',
		name: '在浏览器打开',
		component: downFile
	}, {
		path: '/map',
		name: '商会服务地图',
		component: map
	},
	{
		path: '/diningMap',
		name: '餐饮地图',
		component: mapA,
		children: [
			{
				path: '/mapIndex',
				name: '餐饮地图',
				component: diningMapIndex
			}, {
				path: '/diningList',
				name: '地图服务列表',
				component: diningList
			}, {
				path: '/diningDetail',
				name: '地图服务详情',
				component: diningDetail
			}, {
				path: '/mapSearch',
				name: '地图服务搜索',
				component: mapSearch
			}
		]
	},
	{
		path: '/settled',
		name: '入驻申请',
		component: settled
	},
	{
		path: '/memberIssue',
		name: '会员自助',
		component: memberIssue
	},
	{
		path: '/settledSuccess',
		name: '提交成功',
		component: settledSuccess
	},
	{
		path: '/examineCouncil',
		name: '审核理事申请',
		component: examineCouncil
	},
	{
		path: '/proposal',
		name: '提案详情',
		component: proposal
	},
	{
		path: '/answer',
		name: '在线答题赢大奖',
		component: answer
	},
	{
		path: '/instant',
		name: '北京遵义企业商会2021年中秋晚会活动',
		component: instantActivity
	},
	{
		path: '/rules',
		name: '在线答题赢大奖',
		component: rules
	},
	{
		path: '/reciprocal',
		name: '晚会盛典',
		component: reciprocal
	}
]
export default routers
