<template>
	<div class="stamp-page">
		<div class="head-stamp">
			<div class="title-stamp">
				<div class="title-icon-stamp">
					<img style="width:0.4rem;height:0.44rem" src="../../../static/yinzhang.png"/>
				</div>
				<div class="title-text">
					<div>加盖公章</div>
				</div>
			</div>
			<div class="commit">
				<div>注：可下载“会员申请表”后加盖公章上传</div>
			</div>
			<div class="download">
				<div class="download-button" @click="load">
					<a> 点击下载</a>
				</div>
				<div class="download-div">
					<div>“会员申请表.pdf”</div>
				</div>
			</div>
		</div>
		<div class="line"></div>
		<div class="license-stamp">
			<div>
				<div class="enterprise-stamp">
					<el-upload
						class="avatar-uploader"
						:action="uploadImgUrl"
						:show-file-list="false"
						:on-error="uploadError"
						:on-success="photoUploadSuccess"
						:before-upload="beforeAvatarUpload">
						<img v-if="applicationForm" :src="applicationForm" style="width: 2rem;height: 2rem">
						<img v-else :src="imageUp" style="width: 2rem;height: 2rem"/>
					</el-upload>
					<div style="display: flex;margin-left: 0.3rem">
						<div style="color: red;">*</div>
						<div>会员申请表--盖章文件上传</div>
					</div>
				</div>
			</div>
		</div>
		<div class="license-stamp">
			<div>
				<img style="width: 2rem; height: 2rem; background-size: contain;" src="../../../static/ex.png"/>
			</div>
			<div style="margin-left: 0.3rem;display: flex;">
				<div>盖章样例</div>
			</div>
		</div>
		<div class="commit_div" style="padding: 0.32rem;">
			<div class="commit_text">
				操作说明：
			</div>
			<div class="commit_text">
				下载填写后的“会员申请表”，会转到手机自带浏览器，打印该“会员申请表”,
				<span style="font-weight: 600">该表需填写申请人签名，申请日期、并加盖企业公章</span>
				，完成后扫描并上传传至本页。
			</div>
			<div class="commit_text" style="margin-top: 0.3rem;font-size: 0.25rem">
				（注1：下载的“会员申请表”表会根据申请人自助填写的资料，自动生成填写好的表格）
			</div>
			<div class="commit_text" style="margin-top: 0.3rem;font-size: 0.25rem">
				（注2：无需担心因跳转浏览器而再次填写，之前填写的数据会保留。）
			</div>
		</div>
		<div class="submit-buttons">
			<div class="submit" @click="onSubmit">
				<div>提交申请</div>
			</div>
		</div>
	</div>
</template>

<script>
import {getMemberShip, getWechatConfig, downMemberShip} from "@/common/api";
import uploadUrl from '../../../common/uploadUrl.js'
import config from "@/common/config";

export default {

	data() {
		return {
			photo: '',
			uploadImgUrl: uploadUrl.uploadUrl,
			imageUp: require('../../../static/upload/3.png'),
			showUploadList: false,
			lists: [],
			applicationForm: '',
			character: 1,
			code: '',
			society: {},
			person: {},
			appid: '',
			wxConfigId: '',
			wechatConfig: {},
			loading: null,

		}
	},
	created() {
		this.initConfig()
		this.getCode()
		this.initData()
	},
	methods: {
		//判断是否登录
		getCode() {
			const code = this.getUrlCode().code // 截取code
			const local = config.redirecturl + 'stamp?wxConfigId=' + this.wxConfigId + '&appid=' + this.appid; // 获取页面url ${encodeURIComponent(local)}
			const appid = this.appid;
			if (!code || code === '') {
				window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
			} else {
				this.code = code
			}
		},
		initData() { // 加载数据
			let memberDataStr = localStorage.getItem('memberData');
			let applicationForm = localStorage.getItem('applicationForm');
			if (memberDataStr) {
				const memberData = JSON.parse(memberDataStr)
				this.character = memberData.character
				this.person = memberData.person
				this.society = memberData.society
			}
			if (applicationForm) {
				this.applicationForm = applicationForm
			}
		},
		initConfig() {
			let wxId = localStorage.getItem('wxConfigId')
			let appId = localStorage.getItem('appid')
			if (!wxId || !appId) {
				wxId = this.getQueryString('wxConfigId')
				appId = this.getQueryString('appid')
				localStorage.setItem('wxConfigId', wxId)
				localStorage.setItem('appid', appId)
				if (wxId) {
					this.wxConfigId = wxId
				}
				if (appId) {
					this.appid = appId
				}
			} else {
				this.wxConfigId = wxId
				this.appid = appId
			}
		},
		getQueryString(name) {
			let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
			let r = window.location.search.substr(1).match(reg);
			if (r != null) return unescape(r[2]);
			return null;
		},
		load() {
			// 获取数据
			const loading = this.$loading({
				lock: true,
				text: '文件生成中，请稍后',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			downMemberShip({
				character: this.character,
				companyAddBo: this.society,
				wxConfigId: this.wxConfigId,
				wxUserInfoAddBo: this.person
			}).then(res => {
				loading.close();
				if (res.data.code == 200) {
					window.location.href = `${config.redirecturl}downFile?fileurl=${res.data.data}`;
				} else {
					this.$toast("文件下载失败", {
						durtaion: 200
					})
				}
			}).catch(() => {
				loading.close();
			})
		},
		onSubmit() {
			let that = this;
			if (this.applicationForm == '' || !this.applicationForm) {
				// toast
				this.$toast("请上传会员申请表", {
					durtaion: 200,
					location: 'center' // 默认在中间
				});
				return;
			}
			const loading = this.$loading({
				lock: true,
				text: '正在提交，请稍后',
				spinner: 'el-icon-loading',
				background: 'rgba(0, 0, 0, 0.7)'
			});
			getMemberShip({
				applicationForm: this.applicationForm,
				character: this.character,
				code: this.code,
				companyAddBo: this.society,
				wxConfigId: this.wxConfigId,
				wxUserInfoAddBo: this.person
			}).then(res => {
				if (res.data.code == 200) {
					// 移除缓存
					// localStorage.removeItem('memberData')
					that.$router.push({path: '/success'});
					loading.close();
				} else {
					loading.close();
					this.$toast(res.data.msg || "申请失败", {
						durtaion: 200,
						location: 'center', // 默认在中间
					});
					window.location.href = config.redirecturl + 'stamp?wxConfigId=' + this.wxConfigId + '&appid=' + this.appid;
				}
			})
		},
		getConfig() {
			getWechatConfig({
				wxConfigId: this.wxConfigId
			}).then(res => {
				if (res.data.code == 200) {
					this.wechatConfig = res.data.data.data
				}
			})
		},
		photoUploadSuccess(res) {
			this.applicationForm = res.data.url;
			localStorage.setItem('applicationForm', res.data.url)
			this.loading.close();
		},
		uploadError() {
			this.loading.close();
		},
		beforeAvatarUpload(file) {
			const isIMG = file.type.indexOf("image/") !== -1;
			const isLtM = file.size / 1024 / 1024 < 3;
			if (!isIMG) {
				this.$toast("只能上传图片文件", {
					durtaion: 200
				});
			}
			if (!isLtM) {
				this.$toast("上传图片大小不能超过 3MB", {
					durtaion: 200
				});
			}
			if (isLtM && isIMG) {
				this.loading = this.$loading({
					lock: true,
					text: '文件上传中，请稍后',
					spinner: 'el-icon-loading',
					background: 'rgba(0, 0, 0, 0.7)'
				});
			}
			return isLtM && isIMG;
		},
		getUrlCode() { // 截取url中的code方法
			const url = location.search;
			this.winUrl = url
			const theRequest = {};
			if (url.indexOf("?") !== -1) {
				const str = url.substr(1);
				const strs = str.split("&");
				for (let i = 0; i < strs.length; i++) {
					theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
				}
			}
			return theRequest
		}
	}
}
</script>

<style scoped>
.head-stamp {
	padding: 0.32rem;
}

.title-stamp {
	display: flex;
	align-items: center;
}

.title-text {
	padding-left: 0.20rem;
	font-size: 0.34rem;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 700;
	color: #333333;
	margin-top: 0.3rem;
}

.commit {
	padding: 0.1rem 0;
	font-size: 0.3rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	text-align: left;
}

.commit_text {
	font-size: 0.3rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	text-align: left;
}

.enterprise-stamp {
	display: flex;
	align-items: center;
	margin-top: 0.3rem;
}

.download {
	display: flex;
}

.download-button {
	font-size: 0.3rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #CB924B;
}

.download-div {
	font-size: 0.3rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
}

.line {
	height: 0.01rem;
	background-color: #E6E6E6;
	margin: 0 0.32rem;
}

.license-stamp {
	display: flex;
	align-items: center;
	font-size: 0.28rem;
	font-family: PingFangSC-Regular, PingFang SC;
	font-weight: 400;
	color: #333333;
	padding: 0 0 0.3rem 0.32rem;
}

.submit-buttons {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-bottom: 0.3rem;
}

.submit {
	width: 6.46rem;
	height: 0.98rem;
	background: #DDB06C;
	border-radius: 1rem;
	font-size: 0.36rem;
	font-family: PingFangSC-Medium, PingFang SC;
	font-weight: 700;
	color: #FFFFFF;
	display: flex;
	justify-content: center;
	align-items: center;
}

</style>
