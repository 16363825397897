<template>
   <rich-text :headname="getHeadName" :text="chamberDetail.contact" :wxConfigId="wxConfigId" :appId="appid" :chamberDetail="chamberDetail"/>
</template>

<script>
import {getWechatConfig} from "@/common/api";
import RichText from './components/RichText.vue';

export default {
  components: { RichText },
  data() {
    return {
      chamberDetail: {},
      wxConfigId: null,
      appid: null
    }
  },
  created() {
    this.initConfig()
    this.getDetail();
  },
  computed:{
    getHeadName(){
      return this.chamberDetail.wxName ? '如何联系'+this.chamberDetail.wxName : '联系商会'
    }
  },
  methods: {
    getDetail() {
      this.getWxConfigId().then(res=>{
        getWechatConfig({
          wxConfigId:res
        }).then(ress=>{
          if(ress.data.code == 200){
            this.chamberDetail = ress.data.data.data
          }
        })
      })
    },
    initConfig() {
      let wxId = this.getQueryString('wxConfigId')
      let appId = this.getQueryString('appid')
      localStorage.setItem('wxConfigId', wxId)
      localStorage.setItem('appid', appId)
      if (wxId) {
        this.wxConfigId = wxId
      }
      if (appId) {
        this.appid = appId
      }
      // this.initWxJs()
    },
    //获取wxConfigId
    getWxConfigId(){
      return new Promise((resolve, reject) => {
        localStorage.setItem('wxConfigId',this.getQueryString('wxConfigId'))
        if (localStorage.getItem('wxConfigId')) {
          resolve(localStorage.getItem('wxConfigId'))
        } else {
          reject("WxConfigId获取失败")
        }
      })
    },
    getQueryString(name){
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if(r!=null)return unescape(r[2]); return null;
    },
  }
}
</script>

<style scoped>
.news-detail-page {
  height: 100vh;
  overflow: scroll;
}

.news-detail-head {
  padding: 0.36rem;
}

.news-detail-title {
  font-size: 0.52rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}
.news-detail-content-article {
  width: 100%;
  padding: 0.3rem;
}
.news-detail-author {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0.2rem 0;
}

.news-detail-banner {
  padding: 0 0.36rem;
}

.news-detail-body {
  padding: 0.36rem;
  margin-bottom: 1rem;
}

.news-detail-content {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>
