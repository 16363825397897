<template>
	<div class="leader-detail-page">
		<div class="leader-detail-card-box">
			<div class="leader-detail-card">
				<div class="leader-detail-image">
          <el-image
              v-if="leaderDetail.photo"
              style="width: 100%;height: 6.84rem;border-radius:0.2rem 0.2rem 0 0 "
              :src="leaderDetail.photo"
              fit="fill"></el-image>
				</div>
				<div class="leader-detail-user-info">
					<div class="leader-detail-name">
						{{leaderDetail.name}}
					</div>
            <el-tag color="#00000000" v-for="(tag, inx) in getTags(leaderDetail)" :key="inx" style="align-self:center;border-radius: 0.38rem;color: #FFFFFF;margin-left:2px"  size="small" border-color="#FFFFFF" shape="circle" >{{ tag }}</el-tag>
				</div>
        <div class="leader-detail-line"></div>
				<div class="leader-detail-company-info">
					<div class="leader-detail-company-name">
						{{leaderDetail.company}}
					</div>
					<div class="leader-detail-company-position">
						{{leaderDetail.companyPost}}
					</div>
				</div>
			</div>
		</div>
		<div class="leader-detail-detail-box">
			<div class="leader-detail-head-tag">
        <div class="leader-detail-tag">个人履历</div>
			</div>
			<div class="leader-detail-content">
        <div class="ql-editor"  v-html="leaderDetail.introduction"></div>
			</div>
<!--			<div class="leader-detail-head-tag">-->
<!--        <div class="leader-detail-tag">企业简介</div>-->
<!--			</div>-->
<!--			<div class="leader-detail-content">-->
<!--        <div class="ql-editor" v-html="leaderDetail.companyIntroduction"></div>-->
<!--			</div>-->
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {
			leaderDetail: { }
		}
	},
    created() {
      this.leaderDetail = this.$route.query.leaderDetail
    },
	computed:{
		getTags(){
		return (item) => {
			let arr = []
			if (item.post && item.post.indexOf(",") != -1) {
				return item.post.split(",")
			}else{
				arr.push(item.post)
			}
				return arr
			}
		}
	}
}
</script>

<style scoped>
/deep/ .el-textarea.is-disabled .el-textarea__inner {
  color: #000000!important;
}
/deep/ .el-textarea__inner{
  min-height: 5rem!important;
}
.leader-detail-page {
  height: 100%;
  overflow: scroll;
}
	.leader-detail-card-box {
		padding: 0.32rem;
	}
	.leader-detail-card {
		width: 100%;
		height: 9.21rem;
		background: linear-gradient(360deg, #CB9249 0%, #EBD3A3 100%);
		border-radius: 0.2rem;
	}
	.leader-detail-image {
		width: 100%;
		height: 6.84rem;
    border-radius: 0.2rem 0.2rem 0 0;
	}
	.leader-detail-user-info {
		display: flex;
		justify-content: center;
		margin-top: 0.1rem;
	}
	.leader-detail-name {
		font-size: 0.4rem;
		font-family: PingFangSC-Semibold, PingFang SC;
		font-weight: 700;
		color: #FFFFFF;
		letter-spacing: 0.05rem;
		padding: 0.1rem;
	}
	.leader-detail-identity {
    margin-top: -0.57rem;
	}
	.leader-detail-line {
		height: 0.01rem;
		border-bottom: 0.01rem dashed #F0F0F0;
		margin:0.27rem 0.27rem;
	}
	.leader-detail-company-info {
		display: flex;
		justify-content: center;
		font-size: 0.26rem;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #FFFFFF;
    letter-spacing: 0.02rem;
	}
	.leader-detail-company-name {
		padding: 0.1rem;
	}
	.leader-detail-company-position {
		padding: 0.1rem;
	}
	.leader-detail-detail-box {
		padding: 0.1rem 0.32rem;
	}
	.leader-detail-head-tag {
		width: 100%;
		display: flex;
		justify-content: center;
		padding: 0.1rem;
		margin-top: 0.2rem;
	}
	.leader-detail-tag {
		width: 1.88rem;
		height: 0.56rem;
		font-size: 0.32rem;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #FFFFFF;
    background-color: #DDB06C;
    letter-spacing: 0.05rem;
    display: flex;
    align-items: center;
    justify-content: center;
	}
	.leader-detail-content {
		margin-top: 0.2rem;
    font-size: 0.3rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    letter-spacing: 0.05rem;
	}
</style>
