<template>
  <div class="membersList-page">
    <scroller :on-infinite="infinite" ref="my_scroller">
      <div class="member-list" v-if="messageList.length > 0">
        <div class="member-list-item" v-for="(item,index) in messageList" :key="index" @click="jumpDetail(item.url)">
          <div class="user-info">
            <div class="name">
              {{ item.tips }}
            </div>
            <div class="user-info-item">
              {{ item.createTime.substring(0, 10) }}
            </div>
          </div>
          <div class="content">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="empty" v-else>
        暂时无需参与投票
      </div>
    </scroller>
  </div>
</template>

<script>
import {getMessageList} from "@/common/api";

export default {
  data() {
    return {
      noDataText: '',
      messageList: [],
      pageSize: 10,
      pageNum: 1,
      total: 0,
      listLength: 0,
      type: ''
    }
  },
  created() {
    this.type = this.$route.query.type
    this.getList();
  },
  methods: {
    infinite() {
      setTimeout(() => {
        if (this.listLength >= this.total) {
          this.$refs.my_scroller.noDataText = ""//更改上拉加载的文字
          this.$refs.my_scroller.finishInfinite(true);
        } else {
          this.pageNum = this.pageNum + 1;
          this.getList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 1500)
    },
    getList() {
      getMessageList({
        pageSize: this.pageSize,
        pageNum: this.pageNum,
        type: this.$route.query.type
      }).then(res => {
        if (res.data.code === 200) {
          const preList = this.messageList.concat(res.data.rows);
          for (let i = 0; i < preList.length; i++) {
            if(preList[i].type === 3){
              preList[i].tips = preList[i].title
            }else {
              preList[i].tips = preList[i].title.substring(0, 6)
              preList[i].name = preList[i].title.substring(7)
            }
          }
          console.log(preList, 'preList')
          this.messageList = preList
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;
        }
      })
    },
    jumpDetail(url) {
      window.location.href = url
    }
  }
}
</script>

<style scoped>
.membersList-page {
  height: 100vh;
  overflow: scroll;
}

.search {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  display: flex;
  align-items: center;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}

.member-list-item {
  display: flex;
  flex-direction: column;
  border-bottom: 0.01rem solid #E6E4E1;
  margin: 0.32rem 0.32rem;
  padding-bottom: 0.2rem;
}

/deep/ .el-input__inner { /*或者 .s2>>>.el-input__inner  */
  border: 0;
  background-color: #F0F0F0;
  border-radius: 1.35rem; /*输入框圆角值*/
}

/deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus {
  border: 0;
  outline: 0;
}

/deep/ .el-avatar > img {
  display: inline !important;
}

.user-detail-box {
  margin-left: 0.1rem;
}

.user-info {
  display: flex;
  justify-content: space-between;
  padding: 0 0.1rem;
  width: 100%;
}

.user-info-item {
  font-size: 0.2rem;
  display: flex;
  align-items: center;
}

.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}

.name {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #333333;
}

.identity {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 0.05rem;
  margin-left: 0.1rem;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}
.content {
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
  padding-top: 0.34rem;
  padding-left: .1rem;
}
</style>
