<template>
    <div class="news-detail-page">
        <div class="news-detail-head" v-if="chamberDetail" style="padding: 0.1rem 0.4rem">
        <div class="news-detail-title" style="font-size: 0.4rem">
            {{ headname }}
        </div>
        <div class="news-logo" style="margin-left: 0">
            <img :src="chamberDetail.logo" style="width: 0.5rem;height: 0.5rem;border-radius: 100rem"/>
            <a class="news-name" :href="chamberDetail.followUrl">{{chamberDetail.wxName}}</a>
        </div>
        </div>
        <div class="news-detail-body-article">
        <div class="news-detail-content-article" style="font-size: 0.3rem;padding: .1rem">
            <div class="ql-editor" v-html="text"></div>
        </div>
        </div>
    </div>
</template>

<script>
import {  getSignatureAll } from "@/common/api";
import wx from 'weixin-js-sdk'

export default {
  name: "RichText",
  props: {
    headname: {
      type:String,
      default: ''
    },
    text: String,
    chamberDetail: Object,
    wxConfigId: String,
    appId: String
  },
  mounted() {
    this.initWxJs()

    this.$nextTick(function(){
      window.tobaidumap  = this.openLocation
    })
    // this.$refs.ueditorbaidumap.addEventListener('click', (e) => {
    //   console.log(e)
    //   this.openLocation()
    // }, false);
  },
  methods: {
    openLocation(data) {
      let arr = data.split(",")
      let that = this
      wx.openLocation({
        latitude: Number(arr[1]), // 纬度，浮点数，范围为90 ~ -90
        longitude: Number(arr[0]), // 经度，浮点数，范围为180 ~ -180。
        name: arr[2], // 位置名
        address: arr[3], // 地址详情说明
        scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为最大
        success: function () {},
        fail: function () {
          that.$toast("您的微信版本暂不支持微信地图", {
            durtaion: 200
          });
        },
      });
    },
    initWxJs() { // 加载微信内置地图配置
      let signUrl = window.location.href.split('#')[0];
      getSignatureAll({
        url: signUrl,
        wxConfigId: this.wxConfigId,
        appId: this.appId
      }).then(res => {
        if (res.data.code === 200) {
          wx.config({
            appId: this.appId, // 必填，公众号的唯一标识
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature,// 必填，签名
            jsApiList: ["openLocation"] // 必填，需要使用的JS接口列表
          })
        } else if (res.data.code === 401) {
          sessionStorage.removeItem('Authorization');
          this.getCode(true);
        } else {
        }
      })
    }
  }
}
</script>


<style scoped>
.news-detail-page {
  height: 100vh;
  overflow: scroll;
}

.news-detail-head {
  padding: 0.36rem;
}

.news-detail-title {
  font-size: 0.52rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.news-detail-author {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0.2rem 0;
}

.news-detail-banner {
  padding: 0 0.36rem;
}

.news-detail-body {
  padding: 0.36rem;
  margin-bottom: 1rem;
}

.news-detail-content {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.news-logo {
  padding-top: 0.2rem;
  display: flex;
  height: 0.5rem;
  align-items: center;
  margin-left: 0.2rem;
}

.news-name {
  text-decoration:none;
  font-size: 0.3rem;
  color: #169BD5;
  margin-left: 0.2rem;
}

>>> p img{
  max-width: 100% !important;
}
</style>
