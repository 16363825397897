<template>
  <div class="news-page">
    <scroller :on-infinite="infinite" ref="my_scroller" :noDataText="''">
      <div class="search">
        <el-input
            @input="search"
            placeholder="请输入关键词搜索"
            v-model="searchValue">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
          <i slot="suffix" class=""></i>
        </el-input>
      </div>
      <div class="news-head">
        <div class="nav">
          <div @click="tabsChange(index,item.id)" :class="{active:current === index}" v-for="(item,index) in list"
               :key="index">
            {{ item.name }}
          </div>
        </div>
      </div>
      <div class="list-box-news" v-if="newsList.length > 0">
        <div class="list-item-news" v-for="(item,index) in newsList" :key="index" @click="jumpDetail(item.content)">
          <div class="item-news">
            <div class="image-news" style="width:1.75rem;height:1.5rem">
              <img  style="width:1.75rem;height:1.5rem;object-fit:cover" :src="item.image"/>
            </div>
            <div style="display: flex;flex-direction: column;justify-content: space-between;">
              <div class="title-news">
                <div>{{ item.title }}</div>
              </div>
              <div style="text-align: left;margin-left: 0.2rem;font-size: 0.3rem;color: #A9A8A8;letter-spacing: 0.02rem">{{ item.createTime.substring(0,4) }}年{{ item.createTime.substring(5,7) }}月{{ item.createTime.substring(8,10) }}日</div>
            </div>
          </div>
        </div>

      </div>
      <!--    <div class="empty" v-else>-->
      <!--      <div>-->
      <!--        暂无相关新闻-->
      <!--      </div>-->
      <!--    </div>-->
    </scroller>
  </div>
</template>

<script>
import {getNewsList, getWechatConfig} from "@/common/api";

export default {
  data() {
    return {
      chamberDetail: {},
      wxConfigId: '',
      appid: '',
      type: 0,
      searchValue: '',
      list: [{
        name: '商会要闻',
        id: 0
      }, {
        name: '党建工作',
        id: 1
      }, {
        name: '会员动态',
        id: 2
      }, {
        name: '贵州资讯',
        id: 3
      }, {
        name: '历史文章',
        id: 4
      }],
      // 因为内部的滑动机制限制，请将tabs组件和swiper组件的current用不同变量赋值
      current: 0, // tabs组件的current值，表示当前活动的tab选项
      swiperCurrent: 0, // swiper组件的current值，表示当前那个swiper-item是活动的
      newsList: [],
      pageSize: 8,
      pageNum: 1,
      total: 0,
      listLength: 0
    };
  },
  created() {
    this.initConfig();
    this.infinite();
    this.getNews();
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.getWxConfigId().then(res => {
        getWechatConfig({
          wxConfigId: res
        }).then(ress => {
          if (ress.data.code === 200) {
            this.chamberDetail = ress.data.data.data
          }
        })
      })
    },
    //获取wxConfigId
    getWxConfigId() {
      return new Promise((resolve) => {
        localStorage.setItem('wxConfigId', this.getQueryString('wxConfigId'))
        if (localStorage.getItem('wxConfigId')) {
          resolve(localStorage.getItem('wxConfigId'))
        } else {
          this.$toast("请在微信公众号内部访问", {
            durtaion: 200
          });
        }
      })
    },
    initConfig() {
      let wxId = localStorage.getItem('wxConfigId')
      let appId = localStorage.getItem('appid')
      if (!wxId || !appId) {
        wxId = this.getQueryString('wxConfigId')
        appId = this.getQueryString('appid')
        localStorage.setItem('wxConfigId', wxId)
        localStorage.setItem('appid', appId)
        if (wxId) {
          this.wxConfigId = wxId
        }
        if (appId) {
          this.appid = appId
        }
      } else {
        this.wxConfigId = wxId
        this.appid = appId
      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    infinite() {
      setTimeout(() => {
        if (this.listLength >= this.total) {
          this.$refs.my_scroller.noDataText = "没有更多数据了"//更改上拉加载的文字
          this.$refs.my_scroller.finishInfinite(true);
        } else {
          this.pageNum = this.pageNum + 1;
          this.getNews();
          this.$refs.my_scroller.finishInfinite(true);
        }
        // done();
      }, 400)
    },
    search() {
      // 搜索
      this.newsList = []
      this.pageSize = 8
      this.pageNum = 1
      this.total = 0
      this.listLength = 0
      this.getNews()
    },
    // tabs通知swiper切换
    tabsChange(index, id) {
      if (id === 4) {
        window.location.href = this.chamberDetail.followUrl
      }
      // 切换标签
      this.newsList = []
      this.pageSize = 8
      this.pageNum = 1
      this.total = 0
      this.listLength = 0
      // 调整类型
      switch (index){
        case 0:
          this.type = 0
          break
        case 1:
          this.type = 2
          break
        case 2:
          this.type = 1
          break
        case 3:
          this.type = 3
          break
      }
      this.current = index;
      this.getNews()
    },
    getNews() {
      this.infinite();
      getNewsList({
        type: this.type,
        wxConfigId: this.wxConfigId,
        title: this.searchValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if (res.data.code === 200) {
          this.newsList = this.newsList.concat(res.data.rows);
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;

        }
      });
    },
    jumpDetail(web) {
      window.location.href = web
    }
  }
};
</script>

<style scoped>

.news-page {
  height: 100vh;
  overflow: scroll;
}

.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}

.search {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  display: flex;
  align-items: center;
}

.news-head {
  padding: 0.32rem;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}

.nav {
  display: flex;
  justify-content: space-between;
  color: #333333;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;

}

.active {
  border-bottom: 0.05rem solid #FF5740;
  padding-bottom: 0.1rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #333333;
}

.list-item-news {
  margin: 0 0.32rem;
  border-bottom: 0.01rem solid #E6E4E1;
  padding: 0.2rem 0 0.15rem 0;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}

.item-news {
  display: flex;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

}
.image-news {

}
.title-news {
  text-align: left;
  margin-left: 0.2rem;
  line-height: 1.8;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 可以显示的行数，超出部分用...表示*/
  -webkit-box-orient: vertical;
}

/deep/ .el-input__inner { /*或者 .s2>>>.el-input__inner  */
  border: 0;
  background-color: #F0F0F0;
  border-radius: 1rem; /*输入框圆角值*/
}

/deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus {
  border: 0;
  outline: 0;
}

.scrolldiv {
  height: 70vh;
}
</style>
