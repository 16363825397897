<template>
    <div>
        <van-nav-bar>
            <template #left>
                <van-icon name="arrow-left" size="18" @click="goup" />
            </template>
            <template #title>
                <van-search v-model="value" placeholder="请输入搜索关键词" />
            </template>
            <template #right>
                <div @click="onSearch">搜索</div>
            </template>
        </van-nav-bar>
        <div class="search_content">
            <div class="conten_header">
              <span>  {{searchTitle}}</span>
                <span v-show="searchTitle=='历史搜索'" class="clone" @click="alldel">全部清除</span>
            </div>

            <div class="search_list" v-if="searchTitle=='搜索结果'">
                <div class="item" v-if="searchList.length!=0">
                    <van-card :desc="item.address" :title="item.name" :thumb="item.logo" v-for="item in searchList"
                        :key="item.id" @click="detail(item.id)">
                        <template #tags>
                            <span>距离我12.0KM</span>
                        </template>
                    </van-card>
                </div>
                <div v-else>
                    暂无结果，您可以换一个试试。
                </div>
            </div>
            <div class="search_history" v-else>
                <div class="history_list">
                    <div class="list_item" v-for="(item,index) in historyList" :key="item.id" @click="upvalue(item)">
                        <span>{{item}}</span>
                        <van-icon name="cross" @click="del(index)"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { del } from 'vue'
import { searchList } from '@/common/mapApi/data'
export default {
    data() {
        return {
            value: '',
            searchList: [],
            searchTitle:'历史搜索',
            
        }
    },
    methods: {
        onSearch() {
            this.searchTitle='搜索结果'
            searchList(this.value).then(res => {
                console.log(res);
                this.searchList=res.data.rows
                this.$store.commit('upHistory',this.value)
            })
        },
        upvalue(item){
            this.searchTitle='搜索结果'
            this.value=item
            searchList(this.value).then(res => {
                console.log(res);
                this.searchList=res.data.rows
                this.$store.commit('upHistory',this.value)
            })
        },
        goup() {
            this.$router.go(-1)
        },
        alldel(){
            this.$store.commit('alldelHistory', )
        },
        detail(id){
            this.$router.push({
            path: '/mapdetail',
            query: {
                // url的参数, 类似get请求的传参
                id:id
            },
        })
        },
        del(index){
            this.$store.commit('del',index )
        }
    },
    created() {
        this.$store.commit('upData', { type: 'title', value: '搜索' })
    },
    mounted() {

    },
    components: {

    },
    computed: {
        historyList(){
            return this.$store.state.historyList
        }
    },
    watch: {
        value:function(newValue,oldValue){
            console.log(newValue);
        if(newValue==''){
            this.searchTitle='历史搜索'
        }
        }

    },
}
</script>

<style  scoped>
.search_content{
    width: 100%;
}

.conten_header{
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  
    background-color: #f9f9f9;
    color:#8b8b8b

}
.conten_header span{
    padding: 0 20px;
    font-size: 12px;
}
.conten_header .clone{
    color:red;
}

.history_list{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.list_item{
    width: 80%;
    border-bottom: 1px solid #ccc;
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    margin: 10px 10%;
    
}
</style>
