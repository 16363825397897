<template>
  <div class="news-page">
<!--    <scroller :on-infinite="infinite" ref="my_scroller" :noDataText="noDataText">-->
      <div class="search">
        <el-input
            @input="searchList"
            placeholder="请输入关键词搜索"
            v-model="searchValue">
          <i slot="prefix" class="el-input__icon el-icon-search"></i>
          <i slot="suffix" class="">
          </i>
        </el-input>
      </div>
      <div class="list-box-news" v-if="issueList.length > 0">
        <div class="list-item-news" v-for="(item,index) in issueList" :key="index"
             @click="jumpDetail(item.supplyDemandId)">
          <div class="item-news">
            <div class="image-news">
              <img style="width:1.88rem;height:1.5rem" :src="getSplit(item.image)"/>
            </div>
            <div class="title-news">
              <div class="title-text">
                {{ item.type === 0 ? '【供应】' + item.title : '【需求】' + item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--    <div class="empty" v-else>-->
      <!--      <div>-->
      <!--        暂无相关信息-->
      <!--      </div>-->
      <!--    </div>-->
<!--    </scroller>-->
    <div class="release" v-if="isLogin" @click="jumpRelease">
      <img src="../../../static/issue.png" style="width: 0.98rem;height: 0.98rem"/>
      <div style="font-size:0.28rem;color: #333333;font-weight: 400;margin-top: 0.18rem">我要发布</div>
    </div>
  </div>
</template>

<script scoped>
import {getDemandList, getLogin} from "@/common/api";
import config from "@/common/config";

export default {
  data() {
    return {
      noDataText: "没有更多数据了",
      searchValue: '',
      // 因为内部的滑动机制限制，请将tabs组件和swiper组件的current用不同变量赋值
      current: 0, // tabs组件的current值，表示当前活动的tab选项
      swiperCurrent: 0, // swiper组件的current值，表示当前那个swiper-item是活动的
      issueList: [],
      pageSize: 8,
      pageNum: 1,
      total: '',
      listLength: 0,
      wxConfigId: '',
      appid: '',
      isLogin: false
    };
  },
  created() {
    this.initConfig();
    this.checkLogin();
  },
  methods: {
    infinite() {
      setTimeout(() => {
        if (this.listLength >= this.total) {
          this.$refs.my_scroller.noDataText = "没有更多数据了"//更改上拉加载的文字
          this.$refs.my_scroller.finishInfinite(true);
        } else {
          this.pageNum = this.pageNum + 1;
          this.getList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 1500)
    },
    //逗号分隔转数组
    getSplit(data) {
      let image = data.split(',');
      return image[0]
    },
    initConfig() {

      let wxId = this.getQueryString('wxConfigId')
      let appId = this.getQueryString('appid')
      localStorage.setItem('wxConfigId', wxId)
      localStorage.setItem('appid', appId)
      if (wxId) {
        this.wxConfigId = wxId
      }
      if (appId) {
        this.appid = appId
      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    searchList() {
      this.issueList = []
      this.pageSize = 8
      this.pageNum = 1
      this.total = ''
      this.listLength = 0
      this.getList()
    },
    getList() {
      getDemandList({
        wxConfigId: this.wxConfigId,
        title: this.searchValue,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if (res.data.code === 200) {
          this.issueList = this.issueList.concat(res.data.rows);
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;
        }
      })
    },
    jumpDetail(id) {
      this.$router.push({
        path: '/issueDetail', query: {
          id: id
        }
      })
    },
    jumpRelease() {
      this.$router.push({
        path: '/issue'
      })
    },
    checkLogin() {
      this.getCode(false);
    },
    //判断是否登录
    getCode(isRefresh) {
      const code = this.getUrlCode().code // 截取code
      const local = config.redirecturl + 'memberIssue?wxConfigId=' + this.wxConfigId + '&appid=' + this.appid; // 获取页面url ${encodeURIComponent(local)}
      const appid = this.appid;
      const wxConfigId = this.wxConfigId
      if (!code || code === '') {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        if (isRefresh) {
          sessionStorage.removeItem('Authorization');
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
        } else {
          getLogin({
            code: code,
            wxConfigId: wxConfigId
          }).then(res => {
            if (res.data.code === 200) {
              sessionStorage.setItem('Authorization', res.data.data);
              this.isLogin = true
              this.getList();
            }
          })
        }
      }
    },
    getUrlCode() { // 截取url中的code方法
      const url = location.search;
      this.winUrl = url
      const theRequest = {};
      if (url.indexOf("?") !== -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    }
  }
};
</script>

<style scoped>
.news-page {
  height: 100vh;
  overflow: scroll;
}

.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}

.search {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  display: flex;
  align-items: center;
}

/deep/ .el-input__inner { /*或者 .s2>>>.el-input__inner  */
  border: 0;
  background-color: #F0F0F0;
  border-radius: 1.35rem; /*输入框圆角值*/
}

/deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus {
  border: 0;
  outline: 0;
}

.list-item-news {
  margin: 0 0.32rem;
  border-bottom: 0.01rem solid #E6E4E1;
  padding: 0.2rem 0 0.15rem 0;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}

.item-news {
  display: flex;
  align-items: center;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;

}

.title-news {
  margin-left: 0.1rem;
  padding: 0.18rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.title-text {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.release {
  position: fixed;
  bottom: 0.42rem;
  right: 0.42rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.scrolldiv {
  height: 70vh;
}
</style>
