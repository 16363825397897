import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);
let store = new Vuex.Store({
    // 1. state
    state: {
        wxConfigId: '',
        appid: '',
        title: '',
        address: '北京市',
        locations: '',
        historyList: []
    },
    // // 2. getters
    getters: {
        // 参数列表state指的是state数据
        getCityFn(state) {
            return state.city;
        }
    },
    // 4. mutations
    mutations: {
        // state指的是state的数据
        // name传递过来的数据
        setCity(state, name) {
            state.city = name;//将传参设置给state的city
        },
        upData(state, obj) {
            if (obj.type === 'title') {
                state.title = obj.value
            } else if (obj.type === 'address') {
                state.address = obj.value
            } else if (obj.type === 'locations') {
                state.locations = obj.value
            }
        },
        upHistory(state, obj) {
            state.historyList.push(obj)
        },
        alldelHistory(state) {
            state.historyList = []
        },
        del(state, index) {
            state.historyList.splice(index, 1)
        }
    }
});

export default store;
