<template>
  <active-list title="双招双引" :type="3" routeStr="farmerList"/>
</template>

<script>
import ActiveList from './components/ActiveList.vue';
export default {
  components: {
    ActiveList
  }
};
</script>
