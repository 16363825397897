<template>
  <div class="myActivity-page">
    <scroller  :on-infinite="infinite" ref="my_scroller" :noDataText="noDataText">
    <div class="myActivity-box">
      <div class="myActivity-item" v-for="(item,index) in activityList" :key="index">
        <div class="myActivity-item-title">
          <div class="myActivity-item-title-time">
            报名时间：{{ item.createTime }}
          </div>
          <div class="myActivity-item-title-status" v-if="item.audit == 0">
            待审核
          </div>
          <div class="myActivity-item-title-status" v-if="item.audit == 1" style="color: #4ECE5B">
            报名成功！
          </div>
          <div class="myActivity-item-title-status" v-if="item.audit == 2" style="color: red">
            审核未通过
          </div>
        </div>
        <div class="myActivity-item-detail">
          <div class="myActivity-detail-companyinfo">
            <div class="myActivity-detail-companyName">
              企业名称：{{ item.companyName }}
            </div>
            <div class="myActivity-detail-userName">
              联系人：{{ item.contactPerson }}
            </div>
            <div class="myActivity-detail-phone">
              联系电话：{{ item.phone }}
            </div>
          </div>
          <div class="myActivity-detail-remark" style="margin-top: 0.1rem">
            备注：{{ item.remark }}
          </div>
        </div>
        <div class="myActivity-item-activityDetail">
          <div class="myActivity-item-activityDetail-title">
            {{ item.wxActivityVo.title }}
          </div>
          <div class="myActivity-item-activityDetail-image">
            <img :src="item.wxActivityVo.image" style="width: 1rem;height: 1rem"/>
          </div>
        </div>
      </div>

      <div class="release" v-if="activityList.length <= 0" @click="jumpActivity">
        <img src="../../static/issue.png" style="width: 0.98rem;height: 0.98rem"/>
        <div style="font-size:0.28rem;color: #333333;font-weight: 400;margin-top: 0.18rem">我要报名</div>
      </div>
    </div>
    </scroller>
  </div>
</template>

<script>

import {getUserActivityList} from "@/common/api";

export default {
  data() {
    return {
      noDataText: '没有更多数据了',
      activityList: [],
      pageSize:10,
      pageNum: 1,
      total: '',
      listLength: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    jumpActivity(){
      this.$router.push({
        path: '/newestList'
      })
    },
    infinite() {
      setTimeout(() => {
        if(this.listLength >= this.total){
          this.$refs.my_scroller.finishInfinite(true);
        }else{
          this.pageNum = this.pageNum + 1;
          this.getList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 1500)
    },
    getList() {
      getUserActivityList({
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if (res.data.code === 200) {
          this.activityList = this.activityList.concat(res.data.rows);
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;
          if(this.listLength == 0){
            this.$toast("您暂时还没有报名活动。您可以点击下方按钮去进行活动报名")
          }
        }else (
            this.$toast("您暂时还没有报名活动。您可以点击下方按钮去进行活动报名")
        )
      });
    }
  }
};
</script>

<style scoped>
.myActivity-page {
  height: 100%;
  overflow: scroll;
}
.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}
.myActivity-box {
  padding: 0.2rem;
}

.myActivity-item {
  display: flex;
  flex-direction: column;
  border-bottom: 0.1rem solid #F2F2F2;
}

.myActivity-item-title {
  display: flex;
  font-size: 0.2rem;
  justify-content: space-between;
  border-bottom: 0.01rem solid #F2F2F2;
  border-top: 0.01rem solid #F2F2F2;
  padding: 0.2rem 0;
  color: gray;
}

.myActivity-item-detail {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  font-size: 0.2rem;
  border-bottom: 0.01rem solid #F2F2F2;
  padding: 0.2rem 0;
  color: gray;

}

.myActivity-detail-companyinfo {
  display: flex;
  justify-content: space-between;
}

.myActivity-item-activityDetail {
  display: flex;
  font-size: 0.3rem;
  padding: 0.2rem 0;
  align-items: center;
  justify-content: space-between;
}

.myActivity-item-activityDetail-title {
  width: 60%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.release {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

</style>
