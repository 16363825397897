<template>
  <div class="page">
    <div class="page-box">
      <div class="form">
        <el-form ref="userInfo" :model="userInfo" :rules="rules" label-width="1.9rem" :hide-required-asterisk="false">
          <el-form-item label="商家名称" prop="merchantName">
            <el-input v-model="userInfo.merchantName" placeholder="请填写商家名称"></el-input>
          </el-form-item>
          <el-form-item label="主营服务" prop="mainBusiness">
            <el-input v-model="userInfo.mainBusiness" placeholder="请填写0-10个字"></el-input>
          </el-form-item>
          <el-form-item label="人均消费">
            <el-input v-model="userInfo.perCapita" placeholder="选填"></el-input>
          </el-form-item>
          <el-form-item label="会员证">
            <div style="display: flex;align-items: center">
              <el-input type="number" v-model="userInfo.discount" placeholder="持会员证享受折扣，0表示不打折"></el-input>
              <span style="padding-left: 0.1rem">折</span>
            </div>
          </el-form-item>
          <el-form-item label="营业时间">
            <div style="display: flex">
              <el-time-picker
                  v-model="userInfo.startTime"
                  placeholder="开始时间">
              </el-time-picker>
              <div style="padding: 0 0.1rem">
                至
              </div>
              <el-time-picker
                  v-model="userInfo.endTime"
                  placeholder="休息时间">
              </el-time-picker>
            </div>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input v-model="userInfo.phono" placeholder="选填"></el-input>
          </el-form-item>
          <el-form-item label="省市区" prop="county">
            <el-cascader
                size="large"
                :options="options"
                v-model="selectedOptions"
                @change="handleChange"
                style="width: 4.55rem"
            >
            </el-cascader>
          </el-form-item>
          <el-form-item label="详细地址">
            <el-input v-model="detaillAddress" placeholder="请选择坐标"></el-input>
          </el-form-item>
          <el-form-item label="选择坐标">
            <div style="font-size: 0.24rem" @click="dialogMap = true">
              {{ detailLocation ? detailLocation : '请选择' }}
            </div>
          </el-form-item>
          <!--              <el-form-item label="详细地址">-->
          <!--                <el-input placeholder="必填" type="textarea"></el-input>-->
          <!--              </el-form-item>-->
        </el-form>
      </div>
      <div class="upload-box">
        <div class="enterprise">
          <div class="enterprise-title">
            企业照片
          </div>
          <el-upload
              class="avatar-uploader"
              :action="uploadImgUrl"
              :show-file-list="false"
              :on-error="uploadError"
              :on-success="handleUploadSuccess"
              :before-upload="beforeAvatarUpload"
              :on-preview="handlePictureCardPreview">
            <img :src="fillImage" style="width: 1.9rem;height: 1.9rem;margin-left: 0.1rem"/>
          </el-upload>
        </div>
      </div>
      <div style="display: flex;align-items: center">
        <div style="display: flex;flex-direction: column;margin-left: 0.3rem">
          <span style="font-size: 0.28rem;color:#333333;">是否为</span>
          <span style="color: #666666;font-size: 0.24rem">会员基地</span>
        </div>
        <div class="vote-radio" style="display: flex;margin-left: 0.3rem">
          <el-radio v-model="userInfo.isVipBase" :label="1">是</el-radio>
          <el-radio v-model="userInfo.isVipBase" :label="0">否</el-radio>
        </div>
        <div class="question" style="display: flex;margin-left: 0.52rem" @click="dialogVisible = true">
          <img src="../../../assets/ques.png" style="width: 0.32rem;height: 0.32rem"/>
        </div>
      </div>

      <el-dialog
          style="height: 3rem"
          :visible.sync="dialogVisible"
          width="80%"
          :before-close="handleClose">
        <div style="font-size: 0.28rem;color: #333333;">
          会员基地单位：是商会授牌的可以为会员提供线下会议、活动等地点的会员企业
        </div>
      </el-dialog>

      <el-dialog
          :visible.sync="dialogMap"
          width="100%"
          :before-close="handleMapClose">
        <div class="dialog_map">
          <div>
            <div style="display: flex;justify-content: space-between;padding-bottom: 0.1rem">
              <el-button @click="startSearch" size="mini">搜索</el-button>
              <el-button @click="handleMapClose" size="mini">确定</el-button>
            </div>
            <div class="amap-page-container">
              <input v-if="toSearch" v-model="addressName" type="text" class="search-input" id="search">
              <el-amap
                  vid="amap"
                  :zoom="zoom"
                  class="amap-demo"
                  :center="center"
                  :amapManager="amapManager"
                  :events="events"
              >
                <el-amap-circle
                    vid="circle"
                    :center="center"
                    :radius="radius"
                    fill-opacity="0.2"
                    strokeColor="#38f"
                    strokeOpacity="0.8"
                    strokeWeight="1"
                    fillColor="#38f"
                >
                </el-amap-circle>
              </el-amap>
            </div>
            <ul>
              <li v-for="item in result" :key="item.id">{{item.name}}</li>
            </ul>
          </div>
          <!--          <div class="button-apply">-->
          <!--            <div class="button-text-apply" @click="handleMapClose">-->
          <!--              <div style="">-->
          <!--                确定-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
        </div>
        <ul>
          <li v-for="item in result" :key="item.id">{{ item.name }}</li>
        </ul>
      </el-dialog>
      <div class="button-apply">
        <div class="button-text-apply" @click="societyForm">
          <div style="">
            提交
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import uploadUrl from "@/common/uploadUrl";
import {regionData, CodeToText} from "element-china-area-data";
import {getMerchantAdd} from "../../../common/api";
import {AMapManager} from "vue-amap"
let amapManager=new AMapManager();

export default {
  data() {
    let vm = this;
    return {
      detailLocation:'',
      zoom:16,
      center: [121.329402,31.228667],
      result:[],
      address:"",
      radius:100,
      toSearch:false,
      amapManager,
      map:null,
      events:{
        init(o){
          vm.map=o;
        }
      },
      addressName: '',
      detaillAddress:'',
      location: '',
      dialogMap: false,
      dialogVisible: false,
      options: regionData,
      selectedOptions: [],
      uploadImgUrl: uploadUrl.uploadUrl,
      fillImage: require('@/assets/upload.png'),
      wxConfigId: '',
      wxUserId: '',
      userInfo: {
        merchantName: '',
        mainBusiness: '',
        perCapita: '',
        discount: 0,
        startTime: '',
        endTime: '',
        phone: '',
        county: '',
        isVipBase: 0,
        corporatePhotos: '',
        location: '',
        detaillAddress: ''
      },
      rules: {
        merchantName: [
          {required: true, message: '请输入商会名称', trigger: 'blur'},
        ],
        mainBusiness: [
          {required: true, message: '请输入主营业务', trigger: 'blur'}
        ],
        county: [
          {required: true, message: '请选择省市区', trigger: 'blur'},
        ],
        detaillAddress: [
          {required: true, message: '请填写详细地址', trigger: 'blur'},
        ]
      },
    }
  },
  created() {

  },
  watch: {
    map: function () {
      if (this.map != null) {
        this.startDrag();
      }
    }
  },
  methods: {
    societyForm() {
      this.$refs['userInfo'].validate((valid) => {
        if (valid) {
          this.onSubmit();
        } else {
          this.$toast("资料填写不完整", {
            durtaion: 200
          });
          return false;
        }
      });
    },
    handleClose() {
      this.dialogVisible = false
    },
    handleMapClose() {
      this.dialogMap = false
    },
    handleChange() {
      var loc = "";
      for (let i = 0; i < this.selectedOptions.length; i++) {
        loc += CodeToText[this.selectedOptions[i]];
      }
      this.userInfo.county = loc
    },
    onSubmit() {
      if (this.location === '' || !this.location) {
        this.$toast('请选择会员单位坐标地址', {
          duration: 200
        })
        return
      }
      if (this.userInfo.corporatePhotos === '' || !this.userInfo.corporatePhotos) {
        this.$toast('请上传企业照片', {
          duration: 200
        })
        return
      }
      if (this.userInfo.startTime === '' || !this.userInfo.startTime) {
        this.$toast('请选择开始时间', {
          duration: 200
        })
        return
      }
      if (this.userInfo.endTime === '' || !this.userInfo.endTime) {
        this.$toast('请选择休息时间', {
          duration: 200
        })
        return
      }
      getMerchantAdd({
        businessHours: this.userInfo.startTime.toString().substring(16, 21) + '-' + this.userInfo.startTime.toString().substring(16, 21),
        corporatePhotos: this.userInfo.corporatePhotos,
        county: this.userInfo.county,
        discount: this.userInfo.discount,
        isVipBase: this.userInfo.isVipBase,
        location: this.location,
        mainBusiness: this.userInfo.mainBusiness,
        merchantName: this.userInfo.merchantName,
        perCapita: this.userInfo.perCapita,
        phono: this.userInfo.phone,
        addressName: this.addressName,
        detailedAddress: this.detaillAddress
      }).then(res => {
        if (res.data.code === 200) {
          this.$router.push('/settledSuccess')
        } else {
          this.$toast(res.data.msg, {
            duration: 200
          })
        }
      })
    },
    uploadError() {
      this.loading.close();
    },
    handleUploadSuccess(res) {
      this.$emit("input", res.data.url);
      this.fillImage = res.data.url;
      this.userInfo.corporatePhotos = res.data.url;
      this.loading.close();
    },
    beforeAvatarUpload(file) {
      const isIMG = file.type.indexOf("image/") !== -1;
      const isLtM = file.size / 1024 / 1024 < 3;
      if (!isIMG) {
        this.$toast("只能上传图片文件", {
          durtaion: 200
        });
      }
      if (!isLtM) {
        this.$toast("上传图片大小不能超过 3MB", {
          durtaion: 200
        });
      }
      if (isLtM && isIMG) {
        this.loading = this.$loading({
          lock: true,
          text: '文件上传中，请稍后',
          spinner: 'el-icon-loading',
          background: 'rgba(0, 0, 0, 0.7)'
        });
      }
      return isLtM && isIMG;
    },
    handlePictureCardPreview(file) {
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    startDrag(){//方法二
      let vm=this;
      let map=this.amapManager.getMap();
      AMapUI.loadUI(['misc/PositionPicker'], function(PositionPicker) {
        var positionPicker = new PositionPicker({
          mode: 'dragMap',
          map: map
        });
        //定位
        let geolocation;
        map.plugin('AMap.Geolocation', function () {
          geolocation=new AMap.Geolocation({
            showButton: true,        //显示定位按钮，默认：true
            showMarker: false,        //定位成功后在定位到的位置显示点标记，默认：true
            extensions:'all'
          })
          map.addControl(geolocation);
          geolocation.getCurrentPosition();
          AMap.event.addListener(geolocation, 'complete', function(data){
            positionPicker.start();
          });//返回定位信息
        })
        positionPicker.on('success', function(positionResult){
          vm.result=positionResult.regeocode.pois;
          vm.address=positionResult.regeocode.formattedAddress;
          console.log(positionResult.regeocode.formattedAddress,"address")
          vm.center=[positionResult.position.lng,positionResult.position.lat]
        })
        positionPicker.on('fail', function(failResult){
          console.log(failResult)
        })
        // positionPicker.start();
      })
    },
    addRadius(){
      this.radius+=10;
    },
    startSearch() {
      let vm=this;
      let map=this.amapManager.getMap();
      this.toSearch=true;
      AMapUI.loadUI(['misc/PoiPicker'], function(PoiPicker) {
        var poiPicker = new PoiPicker({
          input: 'search', //输入框id

        });
        //监听poi选中信息
        poiPicker.on('poiPicked', function(poiResult) {
          //用户选中的poi点信息
          console.log(poiResult,'poiResult')
          vm.addressName = poiResult.item.name
          vm.center=[poiResult.item.location.lng,poiResult.item.location.lat]
          vm.location = poiResult.item.location.lng + ',' + poiResult.item.location.lat
          if(poiResult.item.district){
            vm.detaillAddress = poiResult.item.district  + poiResult.item.address + poiResult.item.name
            vm.detailLocation = poiResult.item.district  + poiResult.item.address + poiResult.item.name
          }else {
            vm.detaillAddress = poiResult.item.cityname + poiResult.item.adname + poiResult.item.address + poiResult.item.name
            vm.detailLocation = poiResult.item.cityname + poiResult.item.adname + poiResult.item.address + poiResult.item.name
          }
        });
      });
    },
  }
}
</script>

<style scoped>


.page {
  height: 100vh;
  overflow: scroll;
}

.page-box {
  padding: 0.26rem;
}

.enterprise {
  margin: 0.3rem 0;
  display: flex;
  align-items: center;
}

.enterprise-title {
  font-size: 0.28rem;
  margin-left: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.map {
  height: 3.5rem;
  overflow: auto;
  width: 6.5rem;
}

.custom-class {
  height: 100%;
}

.button-apply {
  margin: 0.5rem 0;
  bottom: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
}

.button-text-apply {
  width: 6.5rem;
  height: 1rem;
  background-color: #DDB06C;
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  border-radius: 1rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.amap-page-container {
  height: 300px;
  position: relative;
}
.search-input{
  border: 1px solid  #ccc;
  position: absolute;
  z-index: 5;
  width: 80%;
  left: 10%;
  padding: 5px;
  border-radius: .1rem;
  height: 0.4rem;
}
.toolbar{
  margin-top: 15px;
}
hr{
  border-color: red;
  border-style: dashed;
}
</style>
