<template>
  <div class="membersList-page">
    <div class="back_top" @click="toTop">
      <span class="el-icon-arrow-up" style="color: #666666;">
      </span>
      <span style="font-size: 0.2rem;color: #666666;">顶部</span>
    </div>
    <div class="search" id="scrollTop">
      <el-input
          @input="searchMemberList"
          placeholder="请输入关键词搜索"
          v-model="nickName">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
        <i slot="suffix" class=""></i>
      </el-input>
    </div>
      <div class="member-list">
        <div class="member-list-item" v-for="(item,index) in memberList" :key="index">
          <div class="user-avatar" style="display: flex;align-items: center;justify-content: center">
            <el-image style="width: 1rem; height: 1rem;border-radius: 100%" :src="item.portrait" lazy></el-image>
          </div>
          <div class="user-detail-box">
            <div class="user-info">
              <div class="name">
                {{item.nickName}}
              </div>
              <div class="identity">
                {{ item.identity }}
              </div>
              <div class="gender" style="width:0.34rem;height:0.34rem">
                <img v-if="item.sex === 1"  style="width:100%;height:100%" src="../../../static/male.png"/>
                <img v-if="item.sex === 2" style="width:100%;height:100%" src="../../../static/female.png"/>
              </div>
              <div class="province" v-if="item.province">
                <div>{{ item.province }}</div>
              </div>
              <div class="city" v-if="item.city">
                <div>{{ item.city }}</div>
              </div>
            </div>
            <div class="company-info">
              <div class="company-name">
                <div>{{ item.companyName }}</div>
              </div>
              <div class="company-position">
                <div>{{ item.jobTitle }}</div>
              </div>
            </div>
            <div class="tag">
              <div>
                <el-tag class="tag-item" size="mini" v-for="(tag,i) in item.tag" :key="i"  type="info" bg-color="#FFFFFF">{{tag}}</el-tag>
              </div>
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script >
import { getMemberList, getWxUserInfo } from "@/common/api";
export default {
  data() {
    return {
      noDataText: '没有更多数据了',
      avatarSize: 60,
      nickName: '',
      memberList: [],
      pageSize:1000,
      pageNum: 1,
      total: '',
      listLength: 0,
      wxConfigId: '',
      userInfo: {
        number: '',
        wxCharacter: '',
        wxUserInfo: {
          nickName: '',
          jobTitle: '',
          scale: '',
          photo: ''
        },
        companyVo: {
          companyName: ''
        }
      }
    }
  },
  computed:{
    filterMemberList() {
      let arr = []
      for (let index = 0; index < this.memberList.length; index++) {
        const element = this.memberList[index];
        if(Number(element.wxCharacter) <= Number(this.userInfo.wxCharacter)) {
          arr.push(element)
        }
      }
      return arr;
    }
  },
  created() {
    this.getWxConfigId();
  },
  methods: {
    // 跳到顶端
    toTop() {
      window.location.href = "#scrollTop"
    },
    infinite() {
      setTimeout(() => {
        if(this.listLength >= this.total){
          this.$refs.my_scroller.noDataText="没有更多数据了"//更改上拉加载的文字
          this.$refs.my_scroller.finishInfinite(true);
        }else{
          this.pageNum = this.pageNum + 1;
          this.getMemberList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 500)
    },
    getUserInfo() {
      getWxUserInfo().then(res => {
        if (res.data.code === 200) {
          this.userInfo = res.data.data;
        } else if (res.data.code === 401) {
          sessionStorage.removeItem('Authorization');
        } else {
          this.$toast(res.data.msg || "信息加载失败", {
            durtaion: 200
          });
        }
      })
    },
    //获取wxConfigId
    getWxConfigId(){
      let configId = this.getQueryString('wxConfigId') || localStorage.getItem('wxConfigId');
      if(configId){
        sessionStorage.setItem('wxConfigId', configId);
        this.wxConfigId = configId
        this.getMemberList();
      }
    },
    getQueryString(name){
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if(r!=null)return unescape(r[2]); return null;
    },
    searchMemberList(){
      this.memberList = []
      this.pageSize = 10
      this.pageNum = 1
      this.total = 0
      this.listLength = 0
      this.getMemberList()
    },
    getMemberList() {
      getMemberList({
        wxConfigId: this.wxConfigId,
        nickName:this.nickName,
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if (res.data.code === 200){
          const arr = res.data.rows;
          for (let i = 0; i < arr.length; i++) {
            if(arr[i].isProvince){
              arr[i].isProvince = arr[i].isProvince.split(",");
              arr[i].province = arr[i].isProvince[0];
              arr[i].city = arr[i].isProvince[1];
            }
          }
          for (let x = 0; x < arr.length; x++) {
            if(arr[x].label){
              arr[x].labels = arr[x].label.split(",");
              arr[x].tag = arr[x].labels
            }
          }
          let z;
          for(z = 0; z<arr.length; z++){
            switch (arr[z].wxCharacter) {
              case "0" :
                arr[z].identity = "普通用户"
                break;  //停止执行，跳出switch
              case "1" :
                arr[z].identity = "会员"
                break;  //停止执行，跳出switch
              case "2" :
                arr[z].identity = "理事"
                break;  //停止执行，跳出switch
              case "3" :
                arr[z].identity = "监事"
                break;  //停止执行，跳出switch
              case "4" :
                arr[z].identity = "常务副会长"
                break;  //停止执行，跳出switch
              case "5" :
                arr[z].identity = "副会长"
                break;  //停止执行，跳出switch
              case "6" :
                arr[z].identity = "会长"
                break;  //停止执行，跳出switch
              case "7" :
                arr[z].identity = "监事长"
                break;  //停止执行，跳出switch
              case "8" :
                arr[z].identity = "秘书长"
                break;  //停止执行，跳出switch
              case "9" :
                arr[z].identity = "常务秘书长"
                break;  //停止执行，跳出switch
              case "10" :
                arr[z].identity = "副秘书长"
                break;  //停止执行，跳出switch
              case "11" :
                arr[z].identity = "秘书"
                break;  //停止执行，跳出switch
              default :  //上述条件都不满足时，默认执行的代码

            }
          }
          this.memberList = this.memberList.concat(arr);
          this.total = res.data.total;
          this.listLength = this.listLength + res.data.rows.length;
        }
      })
    },
  }
}
</script>

<style scoped>
.back_top{
  position: fixed;
  bottom: 2rem;
  right: 1rem;
  width: 0.8rem;
  height: 0.8rem;
  border-radius: 100%;
  border: 0.02rem solid #666666;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 999;
  background: #FFFFFF;
  font-size: 0.3rem;
}
.membersList-page {
  height: 100vh;
  overflow: scroll;
}
.search {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  display: flex;
  align-items: center;
}
.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}
.member-list-item {
  display: flex;
  border-bottom: 0.01rem solid #E6E4E1;
  margin: 0.32rem 0.32rem;
  padding-bottom: 0.2rem;
}
/deep/ .el-input__inner { /*或者 .s2>>>.el-input__inner  */
  border: 0;
  background-color: #F0F0F0;
  border-radius: 1.35rem; /*输入框圆角值*/
}

/deep/ .el-input.is-active .el-input__inner, .el-input__inner:focus {
  border: 0;
  outline: 0;
}

/deep/ .el-avatar>img{
  display: inline !important;
}

.user-detail-box {
  margin-top: 0.3rem;
  margin-left: 0.1rem;
}
.user-info {
  display: flex;
  margin-left: 0.1rem;
}
.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}
.name {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 700;
  color: #333333;
}

.identity {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  padding: 0.05rem;
  margin-left: 0.1rem;
}
.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}
.gender {
  margin-top: -0.64rem;
}

.province {
  margin-left: 0.1rem;
  height: 0.3rem;
  background: #DDB06C;
  border-radius: 1rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
}

.city {
  margin-left: 0.1rem;
  height: 0.3rem;
  background: #DDB06C;
  border-radius: 1rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.1rem;
}

.company-info {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.1rem;
  padding: 0.1rem 0;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}

.company-position {
  margin-left: 0.1rem;
}

.tag {
  padding: 0.1rem 0;
  display: flex;
  justify-content: space-between;
  /* margin-left: 0.1rem; */
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
}

.tag-item {
  margin-left: 0.1rem;
}
</style>
