<template>
<div class="myIssue-page">
   <scroller  :on-infinite="infinite" ref="my_scroller" :noDataText="noDataText">
  <div class="myIssue-box">
    <div class="myIssue-item" v-for="(item,index) in demandMeList" :key="index">
        <img :src="demandMeList[index].images[0]" style="width: 1.5rem;height: 1.3rem"/>
      <div class="myIssue-item-detail">
        <div class="myIssue-item-title">
          {{item.title}}
        </div>
        <div class="myIssue-item-status">
            <div class="myIssue-item-time">
            {{item.createTime}}
            </div>
          <div class="myIssue-item-pass" v-if="item.status == 0">
            待审核
          </div>
          <div class="myIssue-item-pass" v-if="item.status == 1">
            审核驳回
          </div>
          <div class="myIssue-item-pass" v-if="item.status == 2" style="color:#4ECE5B ">
            上线
          </div>
           <el-button v-if="item.status == 2" size="mini" style="margin-top: -0.2rem;" @click="demandOffline(item.supplyDemandId)">下线</el-button>
          <el-button v-if="item.status == 3" size="mini" style="margin-top: -0.2rem;" disabled>已下线</el-button>
        </div>
      </div>
    </div>
    <div class="release" @click="jumpRelease" v-if="demandMeList.length <= 0">
      <img src="../../../static/issue.png" style="width: 0.98rem;height: 0.98rem"/>
      <div style="font-size:0.28rem;color: #333333;font-weight: 400;margin-top: 0.18rem">我要发布</div>
    </div>
    <div class="empty" v-if="demandMeList.length <= 0">
      暂未发布供需
    </div>
  </div>
  </scroller>
</div>
</template>

<script>

import {getDemandMeList,getDemandOffline} from "@/common/api";

export default {
  data() {
    return {
      demandMeList:[],
      noDataText: '没有更多数据了',
      pageSize:10,
      pageNum: 1,
      total: '',
      listLength: 0
    };
  },
  created() {
    this.getList();
  },
  methods: {
    infinite() {
      setTimeout(() => {
        if(this.listLength >= this.total){
          this.$refs.my_scroller.finishInfinite(true);
        }else{
          this.pageNum = this.pageNum + 1;
          this.getList();
          this.$refs.my_scroller.finishInfinite(true);
        }
      }, 1500)
    },
    getList(){
      getDemandMeList({
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if(res.data.code == 200){
          const list = res.data.rows;
          for(let i=0;i<list.length;i++){
            list[i].images = list[i].image.split( ',' )
          }
          this.demandMeList = this.demandMeList.concat(list);
          this.total = res.data.total;
          this.listLength = this.listLength + list.length;
          if(this.listLength == 0){
            this.$toast(res.data.msg || "您暂时还没有发布供需。您可以点击下方按钮去进行对您的需求发布", {
              durtaion: 200
            });
          }
        }else {
          this.$toast(res.data.msg || "您暂时还没有发布供需。您可以点击下方按钮去进行对您的需求发布", {
              durtaion: 200
          })
        }
      });
    },
    refreshList(){
      this.pageNum = 1
      getDemandMeList({
        pageSize: this.pageSize,
        pageNum: this.pageNum
      }).then(res => {
        if(res.data.code == 200){
          const list = res.data.rows;
          for(let i=0;i<list.length;i++){
            list[i].images = list[i].image.split( ',' )
          }
          this.demandMeList = list;
          this.total = res.data.total;
          this.listLength = this.listLength + list.length;
        }
      });
    },
    demandOffline(id){
      getDemandOffline({
        supplyDemandId:id,
        status:3
      }).then(res=>{
        if(res.data.code == 200){
          this.refreshList();
        }else {
          this.$toast(res.data.msg || "下线失败", {
            durtaion: 200
          });
        }
      })
    },
    jumpRelease() {
      this.$router.push({
        path: '/issue'
      })
    },
  }
};
</script>
<style scoped>
.empty {
  font-size: 0.3rem;
  margin-top: 2rem;
  color: #c3c3c3;
  width: 100%;
  text-align: center;
}
.myIssue-page {
  height: 100vh;
  min-height: 100vh;
  overflow: scroll;
}
.myIssue-item {
  display: flex;
  border-bottom: 0.01rem solid #F2F2F2;
  padding: 0.2rem;
}
.myIssue-item-image {
  padding: 0.2rem;
}
.myIssue-item-detail {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 .3rem;
  margin-right: 0.4rem;
  width: 100%;
  justify-content: space-between;
}
.myIssue-item-title {
  font-size: 0.3rem;
}
.myIssue-item-time {
  font-size: 0.25rem;
}
.myIssue-item-pass {
  font-size: 0.27rem;
  padding-left: 0.3rem;
  margin-top: -.02rem;
}
.myIssue-item-status {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.myIssue-item-button {
}
.release {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
