<template>
  <div class="center-page" v-if="hasInfo">
    <div class="page-head" :style="{height : pageHeadBgHeight}">
      <div class="page-head-bg" :style="{height : pageHeadBgHeight}">
        <img style="width: 100%;height: 100%" :src="bgImage" alt="member"/>
      </div>
      <div class="head-title">
        <div style="z-index: 9;">会员中心</div>
      </div>
      <div class="head-icon" v-if="userInfo.wxConfigs">
        <img class="icon" :src="userInfo.wxConfigs[0].logo" alt="icon" v-if="userInfo.wxConfigs.length > 0"/>
        <img class="icon" :src="userInfo.wxConfigs[1].logo" alt="icon" v-if="userInfo.wxConfigs.length > 1"/>
      </div>
      <div class="head-user-info">
        <div class="user-info-detail">
          <div class="info-name">
            <div>{{ userInfo.wxUserInfo.nickName }}</div>
            <div class="identity-tag" v-if="userInfo.wxCharacter">
              <div style="font-size: 0.25rem;letter-spacing: 2px;padding: 0 0.1rem">{{ formatRoleStr }}</div>
            </div>
          </div>
          <div class="info-company">
            <div class="company-name">
              {{ userInfo.companyVo.companyName }}
            </div>
            <div class="company-position">
              {{ userInfo.wxUserInfo.jobTitle }}
            </div>
          </div>
          <div class="info-scale" v-if="userInfo.wxConfigs">
            <div v-if="userInfo.wxConfigs.length > 0">{{ userInfo.wxConfigs[0].scale }}</div>
            <div v-if="userInfo.wxConfigs.length > 1" style="padding: 0 0.1rem">||</div>
            <div v-if="userInfo.wxConfigs.length > 1">{{ userInfo.wxConfigs[1].scale }}</div>
          </div>
          <div class="vip-card-title" v-if="userInfo.number">
            <div>VIP 电子会员证</div>
          </div>
          <div class="vip-card-number" v-if="userInfo.number">
            <div>NO·{{ idNumber }}</div>
          </div>
        </div>
        <div class="user-info-image" v-if="userInfo.wxUserInfo.photo">
          <img
              style="width: 2.3rem;height:3.33rem;background-size: contain;margin-right: 0.7rem;border-radius: 0.05rem;margin-top: 0.1rem"
              :src="userInfo.wxUserInfo.photo"
          />
        </div>
      </div>
      <!--      <div class="page-head-qrcode">-->
      <!--        <div class="qrcode">-->
      <!--          <div class="qrcode-text">我的二维码</div>-->
      <!--          <div style="display: flex">-->
      <!--            <img style="width: 0.3rem;height: 0.3rem;margin-top: 0.05rem" src="../../../static/member/ico.png"/>-->
      <!--            <div class="right"> ></div>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <div class="page-body" >
      <div class="partic">
        <div class="partic-title">
          <div>我的参与</div>
        </div>
        <div class="partic-list">
          <div class="partic-list-item" style="padding: 0.1rem 0 0 0.4rem" @click="toActivity">
            <div>
              <img style="width: 0.4rem; height:0.4rem; background-size: contain;"
                   src="../../../static/member/active.png"/>
            </div>
            <div style="margin-top: 0.1rem">
              <div>报名活动</div>
            </div>
          </div>
          <div class="partic-list-item" style="padding: 0.1rem 0 0 0.6rem" @click="toIssue">
            <div>
              <img style="width: 0.4rem; height: 0.4rem; background-size: contain;"
                   src="../../../static/member/list.png"/>
            </div>
            <div style="margin-top: 0.1rem">
              <div>发布供需</div>
            </div>
          </div>
        </div>
      </div>

      <div class="partic">
        <div class="partic-title">
          <div>其他服务</div>
        </div>
        <div class="partic-list">
          <div class="partic-list-item" @click="scanSign">
            <div>
              <img style="width: 0.4rem; height:0.4rem; background-size: contain;"
                   src="../../../static/member/qrcode.png"/>
            </div>
            <div style="margin-top: 0.1rem">
              <div>扫码签到</div>
            </div>
          </div>
          <div class="partic-list-item" @click="toCouncil"
               v-if="userInfo.wxCharacter === 1">
            <div>
              <img style="width: 0.4rem; height: 0.4rem; background-size: contain;"
                   src="../../../static/member/join.png"/>
            </div>
            <div style="margin-top: 0.1rem">
              <div>加入理事</div>
            </div>
          </div>
          <div class="partic-list-item" @click="toMembersList">
            <div>
              <img style="width: 0.4rem; height: 0.4rem; background-size: contain;"
                   src="../../../assets/order.png"/>
            </div>
            <div style="margin-top: 0.1rem">
              <div>会员名录</div>
            </div>
          </div>
          <div class="partic-list-item" @click="toApply"
               v-if="userInfo.isSecretary === 1">
            <div>
              <img style="width: 0.4rem; height: 0.4rem; background-size: contain;"
                   src="../../../static/member/shenpi.png"/>
            </div>
            <div style="margin-top: 0.1rem">
              <div>会员审核</div>
            </div>
          </div>
          <div class="partic-list-item" @click="toVote"
               v-if="userInfo.wxCharacter===6 || userInfo.wxCharacter===5 || userInfo.wxCharacter===4 || userInfo.wxCharacter===2 || userInfo.wxCharacter===8">
            <div>
              <img style="width: 0.4rem; height: 0.4rem; background-size: contain;"
                   src="../../../static/member/toupiao.png"/>
            </div>
            <div style="margin-top: 0.1rem">
              <div>理事投票</div>
            </div>
          </div>
          <div class="partic-list-item" @click="toProposal"
               v-if="userInfo.wxCharacter===6 || userInfo.wxCharacter===5 || userInfo.wxCharacter===4 || userInfo.wxCharacter===2">
            <div>
              <img style="width: 0.4rem; height: 0.4rem; background-size: contain;"
                   src="../../../static/member/toupiao.png"/>
            </div>
            <div style="margin-top: 0.1rem">
              <div>提案投票</div>
            </div>
          </div>
        </div>
      </div>

      <div class="partic" v-if="voteProgressList.length > 0">
        <div class="partic-title">
          <div>投票进度</div>
        </div>
        <div class="process">
          <div class="process_head" id="process_head">
            <span style="flex-basis: 25%">申请人</span>
            <span style="flex-basis: 25%">投案事由</span>
            <span style="flex-basis: 25%">截至时间</span>
            <span style="flex-basis: 25%">是否投票</span>
          </div>
          <div class="process_head" v-for="(item,index) in voteProgressList" :key="index">
            <span style="flex-basis: 25%">{{ item.nickName }}</span>
            <span style="flex-basis: 25%">{{ item.type === 2 ? '理事投票' : '提案投票' }}</span>
            <span :style="{flexBasis:(item.remainingTime.length>5 ?'32.5%':'25%')}">{{ item.remainingTime }}</span>
            <span
                :style="{flexBasis:(item.remainingTime.length>5 ?'10%':'25%')}">{{
                item.status === 0 ? '未投' : '已投'
              }}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import bgImage from "../../../static/member/memberbg.png";
import iconL from "../../../static/member/left.png";
import iconR from "../../../static/member/right.png";
import {
  getWxUserInfo,
  getLogin,
  getSignature,
  activitySignIn,
  getWechatConfig,
  getVipNumber,
  getVotingProgress
} from "@/common/api";
import config from "@/common/config";
import wx from 'weixin-js-sdk'

export default {
  data() {
    return {
      idNumber: '',
      dialogVisible: false,
      wxConfigs: [],
      wxConfigId: '',
      appid: '',
      bgImage: bgImage,
      iconL: iconL,
      iconR: iconR,
      userInfo: {
        number: '',
        wxCharacter: '',
        wxUserInfo: {
          nickName: '',
          jobTitle: '',
          scale: '',
          photo: ''
        },
        companyVo: {
          companyName: ''
        }
      },
      hasInfo: false,
      voteProgressList: [],
      pageHeadBgHeight: '6rem',
      pageBodyHeight: `calc(100vh - ${this.pageHeadBgHeight})`
    }
  },
  created() {
    // 每次进入会员中心，需要重新登录避免token过期
    this.initConfig();


  },
  computed: {
    formatRoleStr() {
      switch (this.userInfo.wxCharacter) {
        case 0:
          return "普通用户";
        case 1:
          return "会员"
        case 2 :
          return "理事"
        case 3 :
          return "监事";
        case 4 :
          return "常务副会长";
        case 5 :
          return "副会长";
        case 6 :
          return "会长";
        case 7 :
          return "监事长";
        case 8 :
          return "秘书长";
        case 9 :
          return "常务秘书长";
        case 10 :
          return "副秘书长";
        case 11 :
          return "秘书";
        default :  //上述条件都不满足时，默认执行的代码
          return "会员";
      }
    }
  },
  mounted() {

    // document.getElementsByClassName('page-head-bg')[0].style.height = h
    //
    // document.getElementsByClassName('page-body')[0].style.marginTop = h


    // const query = wx.createSelectorQuery().in(this)
    // query.select('#process_head').boundingClientRect(function(res){
    //   console.log(res)
    // })
    // if(this.$refs['process_head'].style.font)
  },
  methods: {
    initConfig() {
      if (this.$route.query.wxConfigId && this.$route.query.appid) {
        let wxId = this.$route.query.wxConfigId
        let appId = this.$route.query.appid
        localStorage.setItem('wxConfigId', wxId)
        localStorage.setItem('appid', appId)
        if (wxId) {
          this.wxConfigId = wxId
        }
        if (appId) {
          this.appid = appId
        }
        if (this.wxConfigId && this.appid) {
          this.checkLogin();
        }
      } else {
        let wxId = this.getQueryString('wxConfigId')
        let appId = this.getQueryString('appid')
        localStorage.setItem('wxConfigId', wxId)
        localStorage.setItem('appid', appId)
        if (wxId) {
          this.wxConfigId = wxId
        }
        if (appId) {
          this.appid = appId
        }
        if (this.wxConfigId && this.appid) {
          this.checkLogin();
        }
      }

    },
    checkLogin() {
      let token = sessionStorage.getItem('Authorization')
      if (!token) {
        this.getCode(false);
      } else {
        //加载扫码配置信息
        this.initWxJs()
        this.getUserInfo();
        this.getVoteProgress();
      }
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    toActivity() {
      this.$router.push({
        path: '/myActivity'
      })
    },
    toIssue() {
      this.$router.push({
        path: '/myIssue'
      })
    },
    toCouncil() {
      this.$router.push({
        path: '/council'
      })
    },
    toMembersList() {
      this.$router.push({
        path: '/membersList',
      })
    },
    scanSign() {
      const that = this
      wx.scanQRCode({
        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
          activitySignIn(
              result
          ).then(re => {
            if (re.data.code == 200) {
              that.$toast("签到成功", {
                durtaion: 200
              });
            } else {
              that.$toast(re.data.msg || "签到失败", {
                durtaion: 200
              });
            }
          })
        },
        fail: function () {
          that.$toast("您的微信版本暂不支持扫码", {
            durtaion: 200
          });
        }
      });
    },
    toApply() {
      this.$router.push({
        path: '/auditList', query: {
          type: 1
        }
      })
    },
    toVote() {
      this.$router.push({
        path: '/voteList', query: {
          type: 2
        }
      })
    },
    toProposal() {
      this.$router.push({
        path: '/proposalList', query: {
          type: 3,
        }
      })
    },
    //判断是否登录
    getCode(isRefresh) {
      const code = this.getUrlCode().code // 截取code
      const local = config.redirecturl + 'center?wxConfigId=' + this.wxConfigId + '&appid=' + this.appid; // 获取页面url ${encodeURIComponent(local)}
      const appid = this.appid;
      const wxConfigId = this.wxConfigId
      if (!code) {
        sessionStorage.removeItem('Authorization');
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        if (isRefresh) {
          sessionStorage.removeItem('Authorization');
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
        } else {
          getLogin({
            code: code,
            wxConfigId: wxConfigId
          }).then(res => {
            if (res.data.code === 200) {
              sessionStorage.setItem('Authorization', res.data.data);
              //加载扫码配置信息
              this.initWxJs()
              this.getUserInfo();
              this.getVoteProgress();
            } else {
              sessionStorage.removeItem('Authorization');
              this.$router.push({
                path: '/binding', query: {
                  wxConfigId: wxConfigId,
                  appid: appid,
                }
              })
            }
          })
        }
      }
    },
    getUrlCode() { // 截取url中的code方法
      const url = location.search;
      this.winUrl = url
      const theRequest = new Object();
      if (url.indexOf("?") !== -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    },
    getVoteProgress() {
      getVotingProgress().then(res => {
        if (res.data.code === 200) {
          // this.voteProgressList = res.data.data
          let list = res.data.data
          let arr = []
          list.forEach((item) => {
            if (item.remainingTime.substring(0, 1) !== '-') {
              arr.push(item)
            }
          })
          this.voteProgressList = arr

          console.log(document)
          console.log(window)

          // 默认50
          // 当前大小
          let defaultSize = 50
          let fontSize = document.getElementsByTagName('html')[0].style.fontSize.replace('px', '');
          console.log('fontSize', fontSize)
          // vip-card-number 0.25rem


          //TODO 待优化
          //page-head-bg height 6
          if (fontSize > defaultSize) {
            this.pageHeadBgHeight = Number(fontSize / defaultSize + 6).toFixed(2) + 'rem'

          }
          document.getElementsByTagName('html')[0].style.backgroundColor = '#f0f1f2'
          // this.pageBodyHeight = `calc(100vh - ${this.pageHeadBgHeight})`
        }
      })
    },
    getUserInfo() {
      getWxUserInfo().then(res => {
        if (res.data.code === 200) {
          console.log(this, 'this')
          getVipNumber(this.wxConfigId).then(ress => {
            if (ress.data.code === 200) {
              console.log(ress.data.data, "商会配置")
              this.idNumber = ress.data.data[0].period + (res.data.data.number < 10 ? '000' : res.data.data.number < 100 ? '00' : res.data.data.number < 1000 ? '0' : '') + res.data.data.number + res.data.data.wxUserInfo.category
            }
          })
          for (let i = 0; i < res.data.data.wxConfigs.length; i++) {
            if (res.data.data.wxConfigs[i].isProvince === 0) {
              res.data.data.wxConfigs[i].scale = '地级市商会'
            } else {
              res.data.data.wxConfigs[i].scale = '省商会'
            }
          }
          this.userInfo = res.data.data;
          this.hasInfo = true;
          localStorage.removeItem('memberData');
          localStorage.removeItem('applicationForm');
        } else if (res.data.code === 401
        ) {
          sessionStorage.removeItem('Authorization');
          this.getCode(true);
        } else {
          this.$toast(res.data.msg || "信息加载失败", {
            durtaion: 200
          });
        }
      })
    },
    initWxJs() {
      let signUrl = window.location.href.split('#')[0];
      getSignature({
        url: signUrl
      }).then(res => {
        if (res.data.code === 200) {
          wx.config({
            appId: this.appid, // 必填，公众号的唯一标识
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature,// 必填，签名
            jsApiList: ["scanQRCode"] // 必填，需要使用的JS接口列表
          })
        } else if (res.data.code === 401) {
          sessionStorage.removeItem('Authorization');
          this.getCode(true);
        } else {
          this.$toast(res.data.msg || "扫码配置信息错误", {
            durtaion: 200
          });
        }
      })
    }
  }
}
</script>

<style scoped>
.center-page {
  width: 100%;
  height: 100%;
  overflow: scroll;
  background-color: #F0F1F2;
}

.page-head {
  /*margin-top: 10px;*/
}

.page-head-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 6rem;
}

.search >>> .el-input__suffix {
  right: 0px !important;
  transition: all .3s;
}

.bg-image {
  width: 100%;
  height: 27rem;
  z-index: 9;
}

.head-title {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 0.4rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  letter-spacing: 1.5px;
  padding-top: 0.2rem;
  top: 0;
}

.head-icon {
  position: absolute;
  display: flex;
  margin-left: 0.25rem;
  z-index: 99;
  top: 0.2rem;
}

.icon {
  width: 0.8rem;
  height: 0.8rem;
  padding: 5px;
  margin-top: 0.5rem;
  border-radius: 1rem;
}

.head-user-info {
  position: absolute;
  display: flex;
  width: 100%;
  /*margin-left: 0.2rem;*/
  /*padding: 5px;*/
  justify-content: space-between;
  z-index: 999;
  top: 1.8rem;
}

.info-name {
  display: flex;
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0B0013;
  padding: 5px;
}

.identity-tag {
  min-width: 0.8rem;
  height: 0.4rem;
  background: linear-gradient(140deg, #4E3A1F 0%, #0D110C 100%);
  border-radius: 0.2rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFEECE;
  text-align: center;
  margin: 5px;
}

.info-company {
  display: flex;
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #0B0013;
  margin-left: 0.1rem;
  flex-wrap: wrap;
}

.company-position {
  margin-left: 0.2rem;
}

.info-scale {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 500;
  color: #0B0013;
  margin: 0.1rem 0.1rem;
  display: flex;
}

.vip-card-title {
  font-size: 0.4rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #0B0013;
  margin: 0.85rem 0.1rem 0.1rem 0.1rem;
}

.vip-card-number {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC, serif;
  font-weight: 400;
  color: #0B0013;
  margin-left: 0.1rem;
}

.user-info-detail {
  display: flex;
  flex-direction: column;
  z-index: 9;
  padding: 0 5px 5px 0.25rem;
}

.page-head-qrcode {
  position: absolute;
  top: 5.6rem;
  width: 100%;
  height: 0.8rem;
  display: flex;
  justify-content: center;

}

.qrcode {
  width: 90%;
  background-color: #FFFFFF;
  border-radius: 0.1rem;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.qrcode-text {
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #0B0013;
  margin-left: 0.2rem;
}

.right {
  font-size: 0.3rem;
  color: #999999;
  font-weight: 600;
  padding-right: 0.2rem;
  padding-left: 0.1rem;
}

.page-body {
  width: 100%;
  /*height: 200vh;*/
  display: flex;
  flex-direction: column;
  align-items: center;
  /*margin-top: 6rem;*/
}

.partic {
  /*position: absolute;*/
  margin-top: 0.3rem;
  top: 6.2rem;
  width: 90%;
  background-color: #FFFFFF;
  border-radius: 0.1rem;
  z-index: 999;
  padding-bottom: 0.2rem;
}

.partic-title {
  padding: 0.3rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 600;
  color: #0B0013;
}

.partic-list {
  display: flex;
  flex-wrap: wrap;
}

.partic-list-item {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0B0013;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.1rem 0.3rem;
}

.process_head {
  font-size: .25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #0b0013;
  display: flex;
}
</style>
