<template>
  <div class="news-detail-page">
    <div class="news-detail-head">
      <div class="news-detail-title">
        {{ demandDetail.title }}
      </div>
      <div class="news-detail-author">
        {{ demandDetail.companyName }} {{ demandDetail.createTime }}
      </div>
    </div>
    <div class="news-detail-banner">
      <div class="news-detail-image">
        <el-carousel trigger="click" indicator-position="none" height="3.78rem">
          <el-carousel-item v-for="(item,index) in demandDetail.banner" :key="index">
            <el-image
                style="width: 6.86rem; height: 3.78rem"
                :src="item"
                fit="contain"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
    </div>
    <div class="news-detail-body">
      <div class="news-detail-content">
        <div >{{ demandDetail.description }}</div>
      </div>
      <div class="news-detail-content" style="margin-top: 0.2rem">
        <div>联系电话：{{ demandDetail.phone }}</div>
      </div>
      <div class="news-detail-content" style="margin-top: 0.2rem">
        <div>企业地址：{{demandDetail.address}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {getSupplyDemand} from "@/common/api";

export default {
  data() {
    return {
      keyword: '',
      demandDetail: {}
    }
  },
  created: function () {
    console.log(this.$route.query)
    this.getDetail(this.$route.query.id)
  },
  methods: {
    getDetail(id) {
      getSupplyDemand(
          id
      ).then(res => {
        if(res.data.code == 200){
          this.demandDetail = res.data.data;
          this.demandDetail.banner = res.data.data.image.split(',')
        }
      });
    },
  }
}
</script>

<style scoped>
.news-detail-page {
  height: 100vh;
  overflow: scroll;
}

.news-detail-head {
  padding: 0.36rem;
}

.news-detail-title {
  font-size: 0.52rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #333333;
}

.news-detail-author {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #999999;
  margin: 0.2rem 0;
}

.news-detail-banner {
  padding: 0 0.36rem;
}

.news-detail-body {
  padding: 0.36rem;
}

.news-detail-content {
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #333333;
}
</style>
