var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stamp-page"},[_c('div',{staticClass:"head-stamp"},[_vm._m(0),_vm._m(1),_c('div',{staticClass:"download"},[_c('div',{staticClass:"download-button",on:{"click":_vm.load}},[_c('a',[_vm._v(" 点击下载")])]),_vm._m(2)])]),_c('div',{staticClass:"line"}),_c('div',{staticClass:"license-stamp"},[_c('div',[_c('div',{staticClass:"enterprise-stamp"},[_c('el-upload',{staticClass:"avatar-uploader",attrs:{"action":_vm.uploadImgUrl,"show-file-list":false,"on-error":_vm.uploadError,"on-success":_vm.photoUploadSuccess,"before-upload":_vm.beforeAvatarUpload}},[(_vm.applicationForm)?_c('img',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"src":_vm.applicationForm}}):_c('img',{staticStyle:{"width":"2rem","height":"2rem"},attrs:{"src":_vm.imageUp}})]),_vm._m(3)],1)])]),_vm._m(4),_vm._m(5),_c('div',{staticClass:"submit-buttons"},[_c('div',{staticClass:"submit",on:{"click":_vm.onSubmit}},[_c('div',[_vm._v("提交申请")])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"title-stamp"},[_c('div',{staticClass:"title-icon-stamp"},[_c('img',{staticStyle:{"width":"0.4rem","height":"0.44rem"},attrs:{"src":require("../../../static/yinzhang.png")}})]),_c('div',{staticClass:"title-text"},[_c('div',[_vm._v("加盖公章")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"commit"},[_c('div',[_vm._v("注：可下载“会员申请表”后加盖公章上传")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"download-div"},[_c('div',[_vm._v("“会员申请表.pdf”")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"display":"flex","margin-left":"0.3rem"}},[_c('div',{staticStyle:{"color":"red"}},[_vm._v("*")]),_c('div',[_vm._v("会员申请表--盖章文件上传")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"license-stamp"},[_c('div',[_c('img',{staticStyle:{"width":"2rem","height":"2rem","background-size":"contain"},attrs:{"src":require("../../../static/ex.png")}})]),_c('div',{staticStyle:{"margin-left":"0.3rem","display":"flex"}},[_c('div',[_vm._v("盖章样例")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"commit_div",staticStyle:{"padding":"0.32rem"}},[_c('div',{staticClass:"commit_text"},[_vm._v(" 操作说明： ")]),_c('div',{staticClass:"commit_text"},[_vm._v(" 下载填写后的“会员申请表”，会转到手机自带浏览器，打印该“会员申请表”, "),_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("该表需填写申请人签名，申请日期、并加盖企业公章")]),_vm._v(" ，完成后扫描并上传传至本页。 ")]),_c('div',{staticClass:"commit_text",staticStyle:{"margin-top":"0.3rem","font-size":"0.25rem"}},[_vm._v(" （注1：下载的“会员申请表”表会根据申请人自助填写的资料，自动生成填写好的表格） ")]),_c('div',{staticClass:"commit_text",staticStyle:{"margin-top":"0.3rem","font-size":"0.25rem"}},[_vm._v(" （注2：无需担心因跳转浏览器而再次填写，之前填写的数据会保留。） ")])])
}]

export { render, staticRenderFns }