<template>
  <div class="container">
    <div class="search">
      <el-input class="search-input" placeholder="搜索商会服务" v-model="keycodes" :clearable="true" @input="searchData">
        <i slot="prefix"
           style="display: flex;align-content: center;justify-content: center">
          <img
              style="width:0.40rem;height:0.40rem"
              src="../../../assets/search.png"
              alt
          />
        </i>
      </el-input>
    </div>
    <div class="fixed-member">
      <div class="fixed-card" @click="toCenter">
        <img src="../../../assets/card.png" style="width: 0.44rem;height: 0.44rem"/>
        <span style="color: #333333;font-size: 0.2rem">会员证</span>
      </div>
      <div class="fixed-apply" @click="toSettled">
        <img src="../../../assets/apply.png" style="width: 0.44rem;height: 0.44rem"/>
        <span style="color: #333333;font-size: 0.2rem">会员单位申请</span>
      </div>
    </div>
    <div class="map">
      <el-amap class="amap-box" vid="map"
               :zoom="zoom"
               :center="center"
               :plugin="plugin">
        <el-amap-marker
          v-for="(marker, index) in markers"
          :key="index"
          :extData="index"
          :icon="marker.icon"
          :position="marker.position"
          :events="marker.events"
          :visible="marker.visible"
          :draggable="marker.draggable"
          :vid="index">
        </el-amap-marker>
        <el-amap-info-window
            v-if="currentWindow"
            :position="currentWindow.position"
            :visible="currentWindow.visible"
            :content="currentWindow.content"
            :offset="currentWindow.offset"
        ></el-amap-info-window>
      </el-amap>
    </div>
    <div class="table">
      <div class="table-box">
        <div class="head">
          <div class="head-switch">
            <div class="switch-all" @click="refreshData('other')" :class="[activityIndex === 0?'activity':'switch-all']">
              全部商会
            </div>
            <div class="province" @click="refreshData('selt')" :class="[activityIndex === 1?'activity':'switch-all']">
              本商会
            </div>
          </div>
        </div>
        <el-scrollbar class="item-box">
          <div class="el-scrollbar__view1" v-infinite-scroll="load" style="overflow:auto">
            <div class="item" v-for="(item,index) in list" :key="index" @click="setCenter(item.location)">
              <div class="item-image">
                <img :src="item.corporatePhotos" style="width: 1.48rem;height: 1.48rem;border-radius: 14px"/>
              </div>
              <div class="item-detail">
                <div class="detail-title">
                  <span id="info" class="detail-title-text">{{ item.merchantName }}</span>
                  <div class="name-tag" v-if="item.isVipBase === 1">
                    <span style="padding: 0.05rem 0.1rem;letter-spacing: 0.05rem">会员活动基地</span>
                  </div>
                </div>
                <div style="display: flex;justify-content: space-between;align-items: center;width: 100%">
                  <div class="detail-center">
                    <div class="detail-tag">
                      <span style="padding: 0.05rem">{{ item.mainBusiness }}</span>
                    </div>
                    <div class="detail-tag">
                      <span style="padding: 0.05rem">{{ item.wxConfigName }}</span>
                    </div>
                  </div>
                  <div class="phone" style="display: flex;align-items: center;height: 0.5rem;">
                    <div class="btn_box" @click="openLocation(item)">
                       <img class="btn_icon" src="../../../static/daohang.png"/>
                       <a class="phone-text" >导航</a>
                    </div>
                  <div class="btn_box">
                   <img  class="btn_icon2" src="../../../assets/phone.png"/>
                      <a :href="'tel:' + item.phono" class="phone-text">咨询</a>
                  </div>

                  </div>
                </div>
                <div style="display: flex;">
                  <div class="detail-info">
                    ￥{{ item.perCapita }}/人
                  </div>
                  <div class="detail-info">
                    会员证{{ item.discount }}折
                  </div>
                  <div class="detail-info" style="margin-right:0">
                    营业时间: {{ item.businessHours }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>
<script>
import { getMerchantList, getLogin, getSignatureAll } from "../../../common/api";
import config from "@/common/config";
import wx from 'weixin-js-sdk'

export default {
  name: "map",
  components: {},
  data() {
    let vm = this;
    return {
      wxConfigId: null,
      appid: null,
      windows:[], // 窗体
      currentWindow: null,
      markers: [], // 坐标点
      keycodes: '',
      tables: ['全部商会', '省商会'],
      activityIndex: 0,
      list: [],
      pageNum: 1,
      pageSize: 10,
      total: 0,
      zoom: 16,
      center: [120.5273285, 31.21515044],
      isLogin: false,
      // 用于打开微信内置地图
      plugin: [{
          pName: 'Scale'
        },{
          pName: 'Geolocation',//定位插件
          showMarker: false,
          events: {
            init(o) {
              //定位成功 自动将marker和circle移到定位点
              o.getCurrentPosition((status, result) => {
                // vm.test = result
                vm.center = [result.position.lng, result.position.lat]
              });
            }
          }
        }
      ]
    }
  },
  created() {
    this.initConfig();
    this.checkLogin();
    this.getList(true);
  },
  methods: {
    getList(refreshCenter){
      getMerchantList({
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        searchValue: this.keycodes,
        wxConfigId: this.activityIndex == 0 ? '' : this.wxConfigId
      }).then(res => {
        if(res.data.code === 200 && res.data.rows.length > 0){
          let records = res.data.rows // 分页数据
          let that = this
          for (let index = 0; index < records.length; index++) {
            const record = records[index];
            let locationArr = record.location.split(',') // 获取坐标, 使用`,`进行切割
            var marker = {} // 格式化marker
            marker.position = [locationArr[0], locationArr[1]]
            marker.index = index
            marker.icon = record.isVipBase === 0 ? require('@/assets/marker.svg') : require('@/assets/vip.svg')
            marker.events = {
              click: (e) => {
                that.windows.forEach(window => {
                  window.visible = false; //关闭窗体
                });
                that.currentWindow = that.windows[e.target.getExtData()]; // 获取当前点击的窗口
                that.$nextTick(() => {
                  that.currentWindow.visible = true;//点击点坐标，出现信息窗体
                });
              },
            };
            marker.visible = true;
            marker.draggable = false;
            this.markers.push(marker) // 直接向前面的数据追加
            this.windows.push({  // 直接向前面的数据追加
              position: marker.position,
              content:
                  "<div style='width: 200px;'><div class=\"map_information\"> <div class=\"info_title\" style=\"font-size: 0.32rem;font-weight: 600;color: #333333;\">"+(record.merchantName|| '暂无')+"</div></div>"+
                  "<div class=\"info_detail\" style=\"font-size: 0.28rem;font-weight: 400;color: #666666;margin-top: 0.2rem\"> <div  class=\"info_address\">" + (record.detailedAddress|| '暂无') + "</div>" +
                  "<div class=\"info_type\" style=\"font-size: 0.28rem;font-weight: 400;color: #666666;margin-top: 0.1rem\">" + (record.mainBusiness|| '暂无') + "</div>" +
                  "<div class=\"info_phone\" style=\"font-size: 0.28rem;font-weight: 400;color: #DDB06C;margin-top: 0.1rem\">" + (record.phono || '暂无') +"</div>" + "</div> </div> </div>",
              offset:[5,-40],//窗体偏移
              visible: true//初始是否显示
            })
          }
          this.total = res.data.total
          if(refreshCenter){ // 默认选中第一个
            this.center = this.markers[0].position
          }
          this.list = this.list.concat(records)
        }
      })
    },
    refreshData(active){
      // 刷新数据
      if(active){
        this.activityIndex = active == 'selt' ? 1 : 0
      }
      this.searchData()
    },
    searchData(){
      // 重置数据
      this.markers = []
      this.windows = []
      this.list = []
      this.currentWindow = null // 初始化弹窗，并设置成不显示
      this.pageNum = 1
      this.pageSize = 10
      this.total = 0
      // 获取数据
      this.getList(true)
    },
    load() { // 下拉加载数据
      if (this.list.length < this.total) {
        this.pageNum = this.pageNum + 1
        this.getList()
      }
    },
    setCenter(loc) { // 点击图标设置对应位置
      let jwd = loc.split(',')
      this.center = [jwd[0],jwd[1]]
    },
    toSettled() { // 跳转至商户申请页面
      if (!this.isLogin) {
        this.$router.push({
          path: '/binding', query: {
            wxConfigId: this.wxConfigId,
            appid: this.appid,
          }
        })
      } else {
        this.$router.push('/settled')
      }
    },
    toCenter() { // 跳转至个人中心
      this.$router.push({
        path:'/center',
        query:{
          wxConfigId:this.wxConfigId,
          appid:this.appid
        }
      })
    },
    openLocation(item) {
      let that = this
      let location = item.location.split(',')
      wx.openLocation({
        latitude: Number(location[1]), // 纬度，浮点数，范围为90 ~ -90
        longitude: Number(location[0]), // 经度，浮点数，范围为180 ~ -180。
        name: item.addressName, // 位置名
        address: item.detailedAddress, // 地址详情说明
        scale: 16, // 地图缩放级别,整形值,范围从1~28。默认为最大
        success: function () {},
        fail: function () {
          that.$toast("您的微信版本暂不支持微信地图", {
            durtaion: 200
          });
        },
      });
    },
    initConfig() {
      let wxId = this.getQueryString('wxConfigId')
      let appId = this.getQueryString('appid')
      localStorage.setItem('wxConfigId', wxId)
      localStorage.setItem('appid', appId)
      if (wxId) {
        this.wxConfigId = wxId
      }
      if (appId) {
        this.appid = appId
      }
      this.initWxJs()
    },
    getQueryString(name) {
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if (r != null) return unescape(r[2]);
      return null;
    },
    checkLogin() {
      if(sessionStorage.getItem("Authorization")){
        this.isLogin = true
      }
      this.getCode(false);
    },
    //判断是否登录
    getCode(isRefresh) {
      const code = this.getUrlCode().code // 截取code
      const local = config.redirecturl + 'map?wxConfigId=' + this.wxConfigId + '&appid=' + this.appid; // 获取页面url ${encodeURIComponent(local)}
      const appid = this.appid;
      const wxConfigId = this.wxConfigId
      if (!code || code === '') {
        window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
      } else {
        if (isRefresh) {
          sessionStorage.removeItem('Authorization');
          window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${encodeURIComponent(local)}&response_type=code&scope=snsapi_base&state=123#wechat_redirect`
        } else {
          getLogin({
            code: code,
            wxConfigId: wxConfigId
          }).then(res => {
            if (res.data.code === 200) {
              sessionStorage.setItem('Authorization', res.data.data);
              this.isLogin = true
              // this.initWxJs()
            }
          })
        }
      }
    },
    getUrlCode() { // 截取url中的code方法
      const url = location.search;
      this.winUrl = url
      const theRequest = {};
      if (url.indexOf("?") !== -1) {
        const str = url.substr(1);
        const strs = str.split("&");
        for (let i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = (strs[i].split("=")[1])
        }
      }
      return theRequest
    },
    initWxJs() { // 加载微信内置地图配置
      let signUrl = window.location.href.split('#')[0];
      getSignatureAll({
        url: signUrl,
        wxConfigId: this.wxConfigId,
        appId: this.appid
      }).then(res => {
        if (res.data.code === 200) {
          wx.config({
            appId: this.appid, // 必填，公众号的唯一标识
            timestamp: res.data.data.timestamp, // 必填，生成签名的时间戳
            nonceStr: res.data.data.nonceStr, // 必填，生成签名的随机串
            signature: res.data.data.signature,// 必填，签名
            jsApiList: ["openLocation"] // 必填，需要使用的JS接口列表
          })
        } else if (res.data.code === 401) {
          sessionStorage.removeItem('Authorization');
          this.getCode(true);
        } else {
          this.$toast(res.data.msg || "扫码配置信息错误", {
            durtaion: 200
          });
        }
      })
    }
  }
}
</script>

<style scoped>
.search-input >>> .el-input__inner {
  border-radius: 0.5rem;
  padding-left: 40px!important;
}
.search-input >>> .el-input__prefix {
  left: 11px!important;
  transition: all .3s!important;
  display: flex;
  align-items: center;
}
a:link {
  color: #666666;
  text-decoration: none;
}

.container {
  width: 100%;
  height: 100vh;
  position: relative;
}
.fixed-member {
  position: absolute;
  right: 0.2rem;
  top: 1.46rem;
  z-index: 99;
  width: 0.8rem;
  height: 2.12rem;
  background: #FFFFFF;
  border-radius: 0.12rem;
  display: flex;
  flex-direction: column;
}

.fixed-card {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 0.16rem;
}

.fixed-apply {
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 0.1rem;
  margin-top: 0.2rem;
}

.search {
  padding: 0.3rem 0.3rem 0.1rem 0.3rem;
  display: flex;
  width: 90%;
  align-items: center;
  position: absolute;
  z-index: 99;
}

.head-switch {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  display: flex;
  margin-top: 0.3rem;
}

.switch-all {
  width: 1.76rem;
  height: 0.6rem;
  border-radius: 1rem 0px 0px 1rem;
  border: 0.01rem solid #CCCCCC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.province {
  width: 1.76rem;
  height: 0.6rem;
  border-radius: 0px 1rem 1rem 0px;
  border: 0.01rem solid #CCCCCC;
  display: flex;
  justify-content: center;
  align-items: center;
}

.head {
  display: flex;
  justify-content: center;
  margin: 0 0.1rem;
}

.table {
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 99;
  background-color: #FFFFFF;
  height: 5.25rem;
  border-radius: 0.2rem 0.2rem 0px 0px;

}

.activity {
  background-color: #DDB06C;
  border: 0.01rem solid #DDB06C;
  color: #FFFFFF;
}

.detail-title {
  font-size: 0.36rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
}

.detail-title-text {
  max-width: 4rem;
  white-space: nowrap;
  overflow: scroll;
  font-family: PingFangSC-Medium, PingFang SC;
}

.name-tag {
  height: 0.32rem;
  background: #DDB06C;
  border-radius: 0.08rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  margin-left: 0.12rem;
}
.detail-center{
 width: 60%;
 overflow: hidden;
 display: flex;
}
.phone{
  width: 40%;
  overflow: hidden;
}
.detail-tag {
    display: flex;
    margin-top: 0.16rem;
    /* max-width: 2.11rem; */
    border-radius: 0.08rem;
    border: 1px solid #CCCCCC;
    font-size: 0.22rem;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
    justify-content: center;
    align-items: center;
    letter-spacing: 0.02rem;
    margin-right: 0.08rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.item {
  display: flex;
  padding: 0rem 0.2rem;
  margin-top: 0.4rem;
  border-bottom: 0.01rem solid #F7F7F7;
  height: 1.5rem;
}

.item-box {
  overflow-y: hidden;
  height: 100%;
}

.el-scrollbar__view1 {
  height: 4.0rem;
}

.detail-info {
  font-size: 0.24rem;
  margin-top: 0.12rem;
  margin-right: 0.12rem;
  color: #666666;
}

.item-service {
  font-size: 0.3rem;
  margin-left: 0.5rem;
  margin-top: 0.3rem;
  color: #1890ff;
}

.item-detail {
  margin-left: 0.2rem;
  width: 100%;
}

.map {
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: 9;
}
.btn_box{
  height: .5rem;
  margin-left: .1rem;
  line-height: .5rem;
}
.btn_icon{
    width: 0.33rem;
    height: 0.33rem;
    overflow: hidden;
    display: block;
    float: left;
    margin-top: .1rem;
}
.btn_icon2{
    width: 0.32rem;
    height: 0.32rem;
    overflow: hidden;
    display: block;
    float: left;
    margin-top: .1rem;
}
.phone-text{
  font-size: 0.24rem;
    margin-left: .1rem;
    display: block;
    overflow: hidden;
    float: left;
    color: #666666;
}
</style>
