<template>
  <div class="container">
    <div class="logo">
      <img :src="backgrondImage" style="width: 100%; height: 5.94rem"/>
    </div>

    <!-- 五个按钮 -->
    <div class="button" v-for="(item, index) in list" :key="index">
      <div class="party">
        <a href="#party">
          <img :src="item.party" style="width: 0.88rem; height: 0.88rem"
          /></a>
      </div>
      <div class="program">
        <a href="#program">
          <img :src="item.program" style="width: 0.88rem; height: 0.88rem"
          /></a>
      </div>
      <div class="problem">
        <a href="#problem">
          <img :src="item.problem" style="width: 0.88rem; height: 0.88rem"
          /></a>
      </div>
      <div class="list">
        <a href="#list">
          <img :src="item.list" style="width: 0.88rem; height: 0.88rem"
          /></a>
      </div>
      <div class="sponsor">
        <a href="#sponsor">
          <img :src="item.sponsor" style="width: 0.88rem; height: 0.88rem"
          /></a>
      </div>
    </div>

    <!-- 按钮下面的文字 -->
    <div class="button-text">
      <div class="icon-text"><a href="#party">晚会流程</a></div>
      <div class="icon-text"><a href="#program">节目单</a></div>
      <div class="icon-text"><a href="#problem">答题赢奖</a></div>
      <div class="icon-text"><a href="#list">中奖名单</a></div>
      <div class="icon-text"><a href="#sponsor">赞助商</a></div>
    </div>

    <!-- 直播 -->
    <div class="lives">
      <div v-if="isLive === false" @click="getLiveAddress">
        <img src="../../../assets/instant/live.png" style="width: 6.62rem;height: 4rem;"/>
      </div>
      <div class="lives-subject" v-else>
        <section>
          <video class="video-player vjs-custom-skin" ref="video" :src="liveLink" controls></video>
        </section>
        <!--        <video class="video-player vjs-custom-skin"-->
        <!--               controls="controls" autoplay="autoplay"-->
        <!--               x-webkit-airplay="true" x5-video-player-fullscreen="true"-->
        <!--               preload="auto" playsinline="true" webkit-playsinline-->
        <!--               x5-video-player-typ="h5">-->
        <!--          <source type="application/x-mpegURL" :src="liveLink">-->
        <!--        </video>-->

      </div>
      <div class="lives-text">
        <div class="text-left"></div>
        <div class="text-middle">直播时间：{{ liveTime }}</div>
        <div class="text-right"></div>
      </div>
    </div>
    <div class="sign-box" @click="showDialog"><span>签到</span></div>
    <!-- 签到弹窗 -->
    <el-dialog
        class="dialog"
        title="签到"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="80%"
        :before-close="dialogClose"
    >
      <el-form
          :model="ruleForm"
          :rules="rules"
          ref="ruleForm"
          class="demo-ruleForm"
      >
        <el-form-item prop="name">
          <el-input v-model="ruleForm.name" placeholder="请输入您的姓名"></el-input>
        </el-form-item>
<!--        <el-form-item prop="phone">-->
<!--          <el-input v-model="ruleForm.phone" placeholder="请输入您的手机号码"></el-input>-->
<!--        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="danger" class="el-button" @click="getSign">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 问题赢奖 -->
    <div class="answer-box">
      <div class="answer-button"><a name="problem">问题赢奖</a></div>
      <div class="box-text-title">抽奖礼品</div>
      <div class="box-text-text">礼品最终以实物图为准</div>
      <div class="answer-box-img"></div>
      <div class="answer-box-lasttext">20000元开发券、香烟、酒、茶</div>
      <div class="answer-box-lastbutton" @click="gettopic">我要答题赢奖</div>
    </div>

    <!-- 晚会流程 -->
    <div class="process">
      <div class="process_name" style="width: 100%;display: flex;justify-content: center">
        <div class="answer-button"><a name="party">晚会流程</a></div>
      </div>
      <div class="process_box">
        <div class="process_item" v-for="(item, index) in partyList" :key="index">
          <div class="process_time">
            <span style="text-align: right">{{ item.period }}</span>
          </div>
          <div class="process_right" style="display: flex;margin-left: 0.2rem">
            <div class="process_dot">
              <div class="inner-circular">
                <span class="inner-small-circular"></span>
              </div>
              <div class="inner-vertical" v-if="index < partyList.length - 1"></div>
            </div>
            <div class="process_detail">
              <div class="process_title">
                {{ item.title }}
              </div>
              <div class="process_content">
                <div style="margin-bottom: 0.14rem" v-for="(items, indexn) in item.content" :key="indexn">{{ items }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--      <div-->
      <!--          class="answer-inner-box"-->
      <!--          v-for="(item, index) in partyList"-->
      <!--          :key="index"-->
      <!--      >-->
      <!--        <div class="inner-box-left">{{ item.period }}</div>-->
      <!--        <div class="inner-box-vertical">-->
      <!--          <div class="inner-circular">-->
      <!--            <span class="inner-small-circular"></span>-->
      <!--          </div>-->
      <!--          <div class="inner-vertical"></div>-->
      <!--        </div>-->
      <!--        <div class="inner-box-right">-->
      <!--          <div class="box-right-title">{{ item.title }}</div>-->
      <!--          <div-->
      <!--              class="box-right-text"-->
      <!--              v-for="(items, indexn) in item.content"-->
      <!--              :key="indexn"-->
      <!--          >-->
      <!--            {{ items }}-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->
    </div>

    <!-- 节目单 -->
    <div class="answer-box">
      <div class="answer-button"><a name="program">节目单</a></div>
      <div
          class="program-box"
          v-for="(item, index) in program.programme"
          :key="index"
      >
        <div class="program-left">{{ item.id }}</div>
        <div class="program-right">
          <div class="right-title">{{ item.title }}</div>
          <div class="right-text">{{ item.period }}</div>
        </div>
      </div>
    </div>

    <!-- 赞助商 -->
    <div class="answer-box">
      <div class="answer-button">
        <a
            name="sponsor"
            style="top: -0.05rem; left: 1.1rem; position: absolute"
        >赞助商</a
        ><span>（排名不分先后）</span>
      </div>
      <div class="sponsor-box">
        <div
            class="sponsor-item"
            v-for="(item, index) in sponsorList"
            :key="index"
        >
          <div class="sponsor-img-box">
            <div class="sponsor-img">
              <img :src="item.image" style="width: 1.5rem;height: 1.5rem;"/>
            </div>
          </div>
          <div>
            <div class="sponsor-text">{{ item.add }}</div>
            <div class="sponsor-name">{{ item.name }}</div>
          </div>
        </div>
      </div>
    </div>

    <!-- 中奖名单 -->
    <div class="answer-box">
      <div class="answer-button"><a name="list">中奖名单</a></div>
      <!-- 特等奖 -->
      <div class="first-class">
        <div class="label-button">特等奖</div>
        <div class="lable-inner-box">
          <div
              class="inner-box-item"
              v-for="(item, index) in zeroList"
              :key="index"
          >
            <div class="zero-inner-box-img" style="font-weight: 600;letter-spacing: 0.03rem;">
              {{ item.userName }}
              <!-- <img src="../../../assets/instant/head.jpeg" alt="" style="height:1.56rem;width:1.56rem;border-radius:100%"> -->
            </div>
            <div class="zero-inner-box-text">
<!--              {{ desensitization(item.phone) }}-->
            </div>
          </div>
        </div>
      </div>
      <!-- 一等奖 -->
      <div class="first-class">
        <div class="label-button">一等奖</div>
        <div class="lable-inner-box">
          <div
              class="inner-box-item"
              v-for="(item, index) in firstList"
              :key="index"
          >
            <div class="first-inner-box-img" style="font-weight: 600;letter-spacing: 0.03rem;">
              {{ item.userName }}
              <!-- <img src="../../../assets/instant/head.jpeg" alt="" style="height:1.56rem;width:1.56rem;border-radius:100%"> -->
            </div>
            <div class="first-inner-box-text">
<!--              {{ desensitization(item.phone) }}-->
            </div>
          </div>
        </div>
      </div>

      <!-- 二等奖 -->
      <div class="first-class">
        <div class="label-button">二等奖</div>
        <div class="lable-inner-box">
          <div
              class="inner-box-item"
              v-for="(item, index) in innersecondList"
              :key="index"
          >
            <div class="inner-box-img" style="font-weight: 600;letter-spacing: 0.03rem;">
              {{ item.userName }}
              <!-- <img src="../../../assets/instant/head.jpeg" alt="" style="height:0.9rem;width:0.9rem;border-radius:100%"> -->
            </div>
<!--            <div class="inner-box-text">{{ desensitization(item.phone) }}</div>-->
          </div>
        </div>
      </div>

      <!-- 三等奖 -->
      <div class="first-class">
        <div class="label-button">三等奖</div>

        <div
            class="three-box-item"
            v-for="(item, index) in threeList"
            :key="index"
        >
          <div class="box-item-img" style="font-weight: 600;">
            {{ item.userName }}
            <!-- <img src="../../../assets/instant/head.jpeg" alt="" style="height:0.52rem;width:0.52rem;border-radius:100%"> -->
          </div>
<!--          <div class="box-item-title">{{ desensitization(item.phone) }}</div>-->
          <div class="box-item-name" style="font-weight: 600;letter-spacing: 0.03rem;">{{ item.userName }}</div>
        </div>
      </div>
    </div>
    <!-- 商会logo -->
    <div class="bottom-logo">
      <img
          :src="merchant"
          style="height: 0.87rem; background-size: 100%"
      />
    </div>
  </div>
</template>

<script>
import {getOnlineActivity} from "@/common/api";
import {getReward} from "@/common/api";
import {getSign} from "@/common/api";
// import Avatar from 'vue-avatar'
let Hls = require('hls.js');
export default {
  name: "index",
  // components: { Avatar },
  data() {
    return {
      isLive: true,
      hls: '',
      // 播放器数据
      playerOptions: {
        playbackRates: [0.5, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, //如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: "zh-CN",
        aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        // 直播地址
        sources: [
          {
            type: "", //这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
            src: "", //url地址
          },
        ],
        notSupportedMessage: "暂未直播", //允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true, //全屏按钮
        },
      },
      backgrondImage: require("@/assets/instant/background.png"),
      // 按钮的数组
      list: [
        {
          party: require("@/assets/instant/party.png"),
          program: require("@/assets/instant/program.png"),
          problem: require("@/assets/instant/problem.png"),
          list: require("@/assets/instant/list.png"),
          sponsor: require("@/assets/instant/sponsor.png"),
        },
      ],
      merchant: require("@/assets/instant/merchant.png"),
      // 直播地址
      liveLink: "",
      // 直播时间
      liveTime: "",
      // 表单
      ruleForm: {
        name: "",
      },
      rules: {
        name: [{required: true, message: "请填写您的姓名", trigger: "blur"}]
      },
      // 晚会流程数组
      partyList: [],
      // 节目单数组
      program: {},
      // 特等奖数组
      zeroList: [],
      // 一等奖名字
      rewardFirst: "",
      // 一等奖数组
      firstList: [],
      // 二等奖名字
      rewardSecond: "",
      // 二等奖数组
      innersecondList: [],
      // 三等奖名字
      rewardThree: "",
      // 三等奖数组
      threeList: [],
      // 赞助商数组
      sponsorList: [],
      // 控制签到弹窗
      dialogVisible: false,
    };
  },
  created() {
    this.getLiveTime();
    this.getProcess();
    this.getProGramme();
    this.getSponsor();
    this.getLiveAddress();
    this.getZero();
    this.getReward();
    this.getrewardSecond();
    this.getrewardThree();
    this.getInfo()
  },
  methods: {
    getInfo() {
      if (!localStorage.getItem('name')) {
        this.isSign = true;
      }
    },
    showDialog() {
      if (localStorage.getItem('name')) {
        this.$toast('您已签到，无须重复签到')
        return
      }
      this.dialogVisible = true
    },
    dialogClose() {
      this.dialogVisible = false
      this.ruleForm.name = ''
      // this.ruleForm.phone = ''
    },
    // 手机号脱敏处理
    desensitization(num) {
      const len = num.length;
      if (len && len > 4) {
        return num.substring(0, 3) + "****" + num.substring(len - 4, len);
      }
    },
    // 直播地址
    getLiveAddress() {
      getOnlineActivity("liveaddress").then((response) => {
        if (response.data.code === 200) {
          this.liveLink = response.data.data;
          this.getStream(this.liveLink);
          console.log(this.c, "this.liveUrl");
          // this.liveUrl = {
          //   type: "application/x-mpegURL",
          //   src: this.liveLink, // url地址
          // };
        }
      });
    },
    // 直播时间
    getLiveTime() {
      getOnlineActivity("livetime").then((response) => {
        if (response.data.code === 200) {
          this.liveTime = response.data.data;
          console.log(this.liveTime, "this.liveTime");
        }
      });
    },
    // 查询签到
    getSign() {
      if (!this.ruleForm.name) {
        this.$toast("请填写姓名！")
        return
      }
      getSign({
        contactPerson: this.ruleForm.name,
      }).then((response) => {
        if (response.data.code === 200) {
          console.log(response);
          localStorage.setItem("name", this.ruleForm.name)  //将数据放入缓存
          this.$toast(response.data.msg);
          this.dialogClose()
        } else {
          this.$toast(response.data.msg);
        }

      });
    },
    // 判断能否答题
    gettopic() {
      getOnlineActivity("answer").then((response) => {
        if (response.data.code === 200 && response.data.data === "1") {
          if (!localStorage.getItem('name')) {
            this.$toast('请先签到！')
            return
          }
          this.$router.push('/answer')
        }
        if (response.data.code === 200 && response.data.data === "0") {
          this.$toast("答题活动暂未开始");
        }
      })
    },
    // 查询晚会流程
    getProcess() {
      getOnlineActivity("process").then((response) => {
        if (response.data.code === 200) {
          const preList = JSON.parse(response.data.data);
          this.partyList = preList.data;
          console.log(this.partyList, "this.partyList");
        }
      });
    },
    // 查询节目单
    getProGramme() {
      getOnlineActivity("programme").then((response) => {
        if (response.data.code === 200) {
          const preProgram = JSON.parse(response.data.data);
          this.program = preProgram;
        }
      });
    },
    // 查询特等奖
    getZero() {
      getReward({type: 1, rewardLevel: 0}).then((response) => {
        if (response.data.code === 200) {
          this.zeroList = response.data.rows;
        }
      });
    },
    // 查询一等奖
    getReward() {
      getReward({type: 1, rewardLevel: 1}).then((response) => {
        // if (response.data.code === 200) {
        //   let a = response.data.rows
        //   for (let index = 0; index < a.length; index++) {
        //     if (a[index].rewardLevel == 1) {
        //       this.firstList.push(a[index])
        //     }
        //     if (a[index].rewardLevel == 2) {
        //       this.innersecondList.push(a[index])
        //     }
        //     if (a[index].rewardLevel == 3) {
        //       this.threeList.push(a[index])
        //     }
        //   }
        // }
        if (response.data.code === 200) {
          // this.rewardFirst = response.data.rows[0].reward;
          this.firstList = response.data.rows;
          // console.log( response.data.rows[0].reward, "this.rewardFirst");
        }
      });
    },
    // 查询二等奖
    getrewardSecond() {
      getReward({type: 1, rewardLevel: 2}).then((response) => {
        if (response.data.code === 200) {
          // this.rewardSecond = response.data.rows[0].reward;
          this.innersecondList = response.data.rows;
          // console.log( response.data.rows[0].reward, "this.rewardsecond");
        }
      });
    },
    // 查询三等奖
    getrewardThree() {
      getReward({type: 1, rewardLevel: 3}).then((response) => {
        if (response.data.code === 200) {
          this.threeList = response.data.rows;
          // this.rewardThree = response.data.rows[0].reward;
          // console.log( response.data.rows[0].reward, "this.rewardThree");
        }
      });
    },
    // 查询赞助商
    getSponsor() {
      getOnlineActivity("sponsor").then((response) => {
        if (response.data.code === 200) {
          //  console.log(JSON.parse( response.data.data))
          this.sponsorList = JSON.parse(response.data.data).Sponsor;
          // console.log(this.sponsorList)
        }
      });
    },
    videoPause() {
      if (this.hls) {
        this.$refs.video.pause();
        this.hls.destroy();
        this.hls = null;
      }
    },
    getStream(source) {
      if (Hls.isSupported()) {
        this.hls = new Hls();
        this.hls.loadSource(source);
        this.hls.attachMedia(this.$refs.video);
        this.hls.on(Hls.Events.ERROR, (event, data) => {
          // console.log(event, data);
          // 监听出错事件
          console.log('加载失败');
          this.isLive = false
          this.$toast('直播暂未开始')
        });
        this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
          console.log('加载成功');
          this.isLive = true
          this.$refs.video.play();
        });
      }
    },
    beforeDestroy() {
      this.videoPause();
    }
    // submitForm(formName) {
    //     this.$refs[formName].validate((valid) => {
    //       if (valid) {
    //         alert('submit!');
    //       } else {
    //         console.log('error submit!!');
    //         return false;
    //       }
    //     });
    //   },
  },
};
</script>

<style scoped>
.container {
  width: 100%;
  height: 100%;
  background: #b20100;
  overflow: scroll;
}

.button {
  display: flex;
  justify-content: space-around;
  height: 1.08rem;
}

.button-text {
  display: flex;
  justify-content: space-around;
}

.icon-text {
  width: 0.96rem;
  height: 0.33rem;
  font-size: 0.24rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffe7c5;
  line-height: 0.33rem;
  text-align: center;
}

.lives {
  width: 7.02rem;
  /* height: 4.72rem; */
  background: #d81223;
  border-radius: 20px;
  border: 4px solid #ffe7c5;
  margin-top: 0.42rem;
  margin-left: 0.18rem;
  padding-top: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lives-subject {
  width: 6.62rem;
  /* height: 4rem; */
  /* border: 1px solid #dbdbdb; */
}

.lives-text {
  width: 6.62rem;
  height: 0.45rem;
  margin-top: 0.13rem;
  margin-bottom: 0.14rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.text-left {
  width: 1.2rem;
  height: 0.14rem;
  background: url("../../../assets/instant/transverse.png") no-repeat;
  background-size: cover;
}

.text-middle {
  width: 3.65rem;
  display: block;
  overflow-wrap: break-word;
  color: rgba(255, 255, 255, 1);
  font-size: 0.32rem;
  font-family: PingFangSC-Regular;
  white-space: nowrap;
  line-height: 0.45rem;
  text-align: center;
}

.text-right {
  width: 1.14rem;
  height: 0.44rem;
  background: url("../../../assets/instant/vertical.png") no-repeat;
  background-size: 100% 100%;
  z-index: 666;
}

.sign-box {
  height: 1.24rem;
  margin-top: 0.42rem;
  background: url("../../../assets/instant/sign.png") no-repeat;
  background-size: 98%;
  margin-left: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sign-box span {
  /*width: 0.84rem;*/
  height: 0.59rem;
  text-align: center;
  font-size: 0.42rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #bd1314;
  line-height: 0.59rem;
}

.answer-box {
  width: 7.02rem;
  background: #d81223;
  border-radius: 0.2rem;
  border: 0.04rem solid #ffe7c5;
  margin-left: 0.18rem;
  margin-top: 0.82rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 0.68rem;
}

.process {
  width: 7.02rem;
  background: #d81223;
  border-radius: 0.2rem;
  border: 0.04rem solid #ffe7c5;
  margin-left: 0.18rem;
  margin-top: 0.82rem;
}

.answer-box-img {
  height: 4.98rem;
  width: 100%;
  background: url("../../../assets/instant/prize.png") no-repeat;
  background-size: 83%;
}

.box-text-title {
  /*width: 1.68rem;*/
  height: 0.59rem;
  font-size: 0.42rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffe7c5;
  line-height: 0.59rem;
  text-align: center;
}

.box-text-text {
  /*width: 2.8rem;*/
  height: 0.4rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffe7c5;
  line-height: 0.4rem;
  text-align: center;
}

.answer-button {
  background: url("../../../assets/instant/answerbutton.png") no-repeat;
  background-size: 100%;
  height: 0.78rem;
  width: 3.34rem;
  position: relative;
  text-align: center;
  top: -0.4rem;
  font-size: 0.42rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #bd1314;
  line-height: 0.59rem;
}

.answer-box-lasttext {
  width: 3.92rem;
  height: 0.4rem;
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 0.4rem;
}

.answer-box-lastbutton {
  text-align: center;
  font-size: 0.36rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #bd1314;
  line-height: 0.8rem;
  background: url("../../../assets/instant/sign.png") no-repeat;
  background-size: 100%;
  width: 4.37rem;
  height: 1rem;
  margin-top: 0.82rem;
}

.bottom-logo {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.36rem;
  margin-bottom: 0.36rem;
}

.first-class {
  width: 6.1rem;
  background: #ffe7c5;
  border-radius: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.06rem;
}

.label-button {
  background: url("../../../assets/instant/label.png") no-repeat;
  background-size: 100%;
  height: 0.68rem;
  width: 4rem;
  position: relative;
  text-align: center;
  top: -0.3rem;
  font-size: 0.42rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #bd1314;
  line-height: 0.59rem;
}

.portrait-text {
  width: 1.92rem;
  height: 0.45rem;
  font-size: 0.32rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cd0114;
  line-height: 0.45rem;
  margin-bottom: 0.35rem;
  margin-top: 0.28rem;
}

.portrait-img {
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 100%;
}

.sponsor-img-box {
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPnga00706ab5aec700c2ad3eae40d99b54a3867c6999b0fe2c66e898bc73969ec70) no-repeat;
  background-size: 100% 100%;
  padding: 0.06rem;
}

/*.sponsor-img-box:before {*/
/*  content: '';*/
/*  display: block;*/
/*  position: absolute;*/
/*  width: 0;*/
/*  height: 0;*/
/*  right:50px;*/
/*  border-width: 43.3px 25px;*/
/*  border-style: solid;*/
/*  border-color: transparent red transparent transparent;*/
/*}*/
/*.sponsor-img-box:after {*/
/*  content: '';*/
/*  display: block;*/
/*  position: absolute;*/
/*  width: 0;*/
/*  height: 0;*/
/*  left:50px;*/
/*  border-width: 43.3px 25px;*/
/*  border-style: solid;*/
/*  border-color: transparent transparent transparent red;*/
/*  top:0;*/
/*}*/
.sponsor-img {
  width: 1.75rem;
  height: 2.0rem;
  display: flex;
  background-color: #FFFFFF;
  align-items: center;
  justify-content: center;
  -webkit-clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  clip-path: polygon(50% 0, 100% 25%, 100% 75%, 50% 100%, 0 75%, 0 25%);
  overflow: hidden;
}

.sponsor-item {
  height: 3rem;
  width: 2rem;
  margin-right: 0.2rem;
  margin-bottom: 0.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.sponsor-text {
  /* height: 0.7rem; */
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffe7c5;
  text-align: center;
}

.sponsor-name {
  width: 2rem;
  height: 0.6rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffe7c5;
  line-height: 0.3rem;
  text-align: center;
}

.program-box {
  display: flex;
  width: 6rem;
  margin-top: 0.32rem;
}

.right-title {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffe7c5;
}

.right-text {
  font-size: 0.25rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffe7c5;
  margin-bottom: 0.32rem;
}

.program-right {
  text-align: left;
  border-bottom: 0.01rem solid #ffe7c5;
  width: 5.33rem;
}

.program-left {
  width: 0.45rem;
  height: 0.45rem;
  line-height: 0.45rem;
  text-align: center;
  font-size: 0.26rem;
  margin-top: 0.23rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #cd0114;
  background: url("../../../assets/instant/number.png") no-repeat;
  background-size: 100%;
  margin-right: 0.31rem;
}

.inner-box-text {
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cd0114;
}

.zero-inner-box-text {
  width: 1.72rem;
  height: 0.45rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cd0114;
  line-height: 0.45rem;
}

.zero-inner-box-img {
  width: 1.26rem;
  height: 1.26rem;
  font-size: 0.68rem;
  display: flex;
  background: #D81223;
  font-size: 0.3rem;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* background: url("../../../assets/instant/head.jpeg") no-repeat;
  background-size: 100%; */
  border-radius: 100%;
}

.first-inner-box-text {
  width: 1.72rem;
  height: 0.45rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cd0114;
  line-height: 0.45rem;
}

.first-inner-box-img {
  width: 1.26rem;
  height: 1.26rem;
  font-size: 0.3rem;
  display: flex;
  background: #2780FF;
  align-items: center;
  justify-content: center;
  color: #fff;
  /* background: url("../../../assets/instant/head.jpeg") no-repeat;
  background-size: 100%; */
  border-radius: 100%;
}

.inner-box-img {
  width: 1.26rem;
  height: 1.26rem;
  margin: 0 auto;
  font-size: 0.3rem;
  display: flex;
  justify-content: center;
  color: #fff;
  border-radius: 100%;
  background: #CD3FEA;
  align-items: center;
  /* background: url("../../../assets/instant/head.jpeg") no-repeat;
  background-size: 100%; */
}

.inner-box-item {
  /* height: 1.43rem; */
  width: 1.56rem;
  margin-left: 0.32rem;
  margin-bottom: 0.35rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lable-inner-box {
  display: flex;
  flex-wrap: wrap;
}

.three-box-item {
  display: flex;
  width: 4.8rem;
  align-items: center;
  margin-bottom: 0.32rem;
  justify-content: space-between;
}

.box-item-img {
  width: 0.62rem;
  height: 0.62rem;
  font-size: 0.16rem;
  display: flex;
  justify-content: center;
  color: #fff;
  background: #0654A5;
  align-items: center;
  /* background: url("../../../assets/instant/head.jpeg") no-repeat;
  background-size: 100%; */
  border-radius: 100%;
}

.box-item-title {
  /* width: 3.12rem; */
  height: 0.37rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cd0114;
  line-height: 0.37rem;
}

.box-item-name {
  width: 1rem;
  height: 0.37rem;
  font-size: 0.26rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #cd0114;
  line-height: 0.37rem;
}

.answer-inner-box {
  width: 6rem;
  display: flex;
  flex-direction: row;
}

.answer-inner-box:last-child .inner-box-vertical .inner-vertical {
  display: none;
}

.inner-box-left {
  height: 0.4rem;
  font-size: 0.22rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffe7c5;
  line-height: 0.4rem;
  margin-top: -0.1rem;
}

.inner-box-right {
  margin-top: -0.15rem;
}

.box-right-title {
  max-width: 3.28rem;
  height: 0.45rem;
  font-size: 0.3rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffe7c5;
}

.inner-circular {
  min-width: 0.35rem;
  min-height: 0.35rem;
  max-height: 0.35rem;
  max-width: 0.35rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  background-image: linear-gradient(90deg, #ffe6c2, #f19725);
}

.inner-small-circular {
  width: 0.15rem;
  height: 0.15rem;
  border-radius: 100%;
  background: #cd0114;
}

.inner-vertical {
  width: 0.02rem;
  min-height: 1.04rem;
  height: 100%;
  background: #ffe7c5;
}

.innner-vertical :last-child {
  display: none;
}

.inner-box-vertical {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 0.22rem;
}

.box-right-text {
  width: 3.28rem;
  /*height: 0.66rem;*/
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #ffe7c5;
  margin-bottom: 0.3rem;
  line-height: 0.33rem;
}

a {
  text-decoration: none;
  color: inherit;
}

.answer-button span {
  display: block;
  height: 0.22rem;
  font-size: 0.16rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bd1314;
  line-height: 0.22rem;
  position: absolute;
  top: 0.48rem;
  left: 1.07rem;
}

.sponsor-box {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0.31rem;
}

.video-js .vjs-icon-placeholder {
  width: 100%;
  height: 100%;
  display: block;
}

.video-player {
  width: 100%;
  height: 4rem;
  object-fit: fill;
}

.video-player >>> .vjs-poster {
  background-size: cover !important;
}

.video-player >>> .vjs-error .vjs-error-display:before {
  display: none;
}

.dialog >>> .el-dialog {
  height: 5.55rem !important;
}

.dialog >>> .el-dialog__body {
  overflow: hidden !important;
}

.dialog >>> .el-input__inner {
  border-radius: 20px !important;
}

.dialog >>> .el-input.is-active .el-input__inner, .el-input__inner:focus {
  border-color: #DCDFE6 !important;
  outline: 0;
}

.el-button {
  background: #D60100 !important;
  border-radius: 22px !important;
  width: 100% !important;
  font-size: 0.36rem !important;
}

.dialog >>> .el-dialog__footer {
  border-top: 0px;
}

.el-form-item {
  margin-bottom: 30px;
}

.dialog >>> .el-dialog__title {
  font-size: 0.42rem;
  font-weight: 600;
}

.dialog >>> .el-dialog__header {
  line-height: 0.8rem !important;
}

.process_box {
  padding-left: 0.32rem;
  padding-top: 0.32rem;
  display: flex;
  flex-direction: column;
  margin-top: -0.05rem;
}

.process_item {
  display: flex;
}

.process_time {
  font-size: 0.28rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFE7C5;
  min-width: 1.8rem;
}

.process_dot {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.process_dot_center {
  width: 0.2rem;
  height: 0.2rem;
  background: #D81223;
  border-radius: 100%;
}
.process_detail {
  margin-left: 0.24rem;
  display: flex;
  flex-direction: column;
  margin-top: -0.05rem;
}
.process_title {
  font-size: 0.32rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFE7C5;
}
.process_content {
  font-size: 0.24rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFE7C5;
  margin-top: 0.14rem;
  padding-bottom: 0.9rem;
  padding-right: 0.14rem;
}
</style>
