<!-- 商会简介 -->
<template>
  <rich-text :headname="getHeadName" :text="chamberDetail.introduction" :wxConfigId="wxConfigId" :appId="appid" :chamberDetail="chamberDetail"/>
</template>

<script>
import {getWechatConfig} from "@/common/api";
import RichText from './components/RichText.vue';

export default {
  components: { RichText },
  data() {
    return {
      chamberDetail: {},
      wxConfigId: null,
      appid: null
    }
  },
  computed:{
    getHeadName(){
      return this.chamberDetail.wxName ? this.chamberDetail.wxName+'简介' : '商会简介'
    }
  },
  created() {
    this.initConfig()
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.getWxConfigId().then(res=>{
        getWechatConfig({
          wxConfigId:res
        }).then(ress=>{
          if(ress.data.code === 200){
            this.chamberDetail = ress.data.data.data
          }
        })
      })
    },
    initConfig() {
      let wxId = this.getQueryString('wxConfigId')
      let appId = this.getQueryString('appid')
      localStorage.setItem('wxConfigId', wxId)
      localStorage.setItem('appid', appId)
      if (wxId) {
        this.wxConfigId = wxId
      }
      if (appId) {
        this.appid = appId
      }
      // this.initWxJs()
    },
    //获取wxConfigId
    getWxConfigId(){
      return new Promise((resolve) => {
        localStorage.setItem('wxConfigId',this.getQueryString('wxConfigId'))
        if (localStorage.getItem('wxConfigId')) {
          resolve(localStorage.getItem('wxConfigId'))
        } else {
          this.$toast("请在微信公众号内部访问", {
            durtaion: 200
          });
        }
      })
    },
    getQueryString(name){
      let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
      let r = window.location.search.substr(1).match(reg);
      if(r!=null)return unescape(r[2]); return null;
    }
  }
}
</script>
