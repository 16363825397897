<template>
	<div class="container">
		<van-search class="search" placeholder="请输入搜索关键词" label="搜索" left-icon="search" @click="goSearch"/>
		<div id="myMap">

		</div>
	</div>
</template>

<script>
import MapLoader from '@/common/AMap'
import {getList} from "@/common/mapApi/data";

export default {
	data() {
		return {
			map: '',
			city: '',
			locations: {
				lat: '39.909851',
				lng: '116.404438'
			},
			markers: []
		}
	},
	methods: {
		goSearch() {
			this.$router.push('/mapSearch')
		},
		init() {
			this.$store.commit('upData', {type: 'locations', value: `${this.locations.lng},${this.locations.lat}`})
			MapLoader().then(AMap => {
				console.log('地图加载成功');
				this.map = new AMap.Map('myMap', {
					center: [this.locations.lng, this.locations.lat],
					zoom: 11
				})
				getList({
					location: `${this.locations.lng},${this.locations.lat}`,
					pageSize: 500
				}).then(res => {
					this.map.remove(this.markers)
					this.markers = []
					res.data.rows.forEach(ele => {
						if (ele.logo !== '' && ele.location) {
							ele.location = ele.location.split(',')
							const marker = new AMap.Marker({
								position: new AMap.LngLat(ele.location[0], ele.location[1]),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
								offset: new AMap.Pixel(-10, -10),
								icon: new AMap.Icon({
									size: new AMap.Size(30, 30),    // 图标尺寸
									image: ele.logo,  // Icon的图
									imageSize: new AMap.Size(30, 30)   // 根据所设置的大小拉伸或压缩图片
								}), // 添加 Icon 实例
								zoom: 11
							});
							// 设置label标签
							// label默认蓝框白底左上角显示，样式className为：amap-marker-label
							marker.setLabel({
								direction: 'right',
								offset: new AMap.Pixel(10, 0),  //设置文本标注偏移量
								content: `<div class="info">${ele.name.startsWith('北京') ? ele.name.substring(2, ele.name.length) : ele.name}</div>`, //设置文本标注内容
							});
							marker.on('click', () => {
								this.$router.push({
									path: '/diningDetail',
									query: {
										id: ele.id
									}
								})
							})
							this.markers.push(marker)
						}
					})
					this.map.add(this.markers)
				})
			}, e => {
				console.log('加载失败');
			})
		},
	},
	created() {

		this.$store.commit('upData', {type: 'title', value: '餐饮版图'})

		this.init()

	},
	mounted() {


	},
	components: {},
	computed: {},
	watch: {},

}
</script>

<style scoped>
.container {
	height: calc(100vh - 46px);
}

.search {

}

#myMap {
	width: 100%;
	height: calc(100% - 54px);
}

#myMap /deep/ .amap-icon {
	border-radius: 50%;
	overflow: hidden;
}

::v-deep .amap-marker-label {
	border: 0;
	background-color: transparent;
}

::v-deep .info {
	position: relative;
	margin: 0;
	top: 0;
	right: 0;
	min-width: 0;
	padding: 3px 3px;
	border-radius: .25rem;
	background-color: white;
	//width: auto;
	border-width: 0;
	box-shadow: 0 2px 6px 0 rgba(114, 124, 245, .5);
	font-size: 10px;
}

</style>
