<template>

	<div>
		<van-list
			v-model="loading"
			:finished="finished"
			finished-text="没有更多了"
			@load="onLoad"
		>
			<van-card
				:desc="item.address"
				:title="item.name"
				:thumb="item.logo"
				v-for="item in list"
				:key="item.id"
				@click="detail(item.id)"
			>
				<template #tags>
					<span>{{ item.distance }}</span>
				</template>
			</van-card>
		</van-list>

	</div>
</template>

<script>
import {getList} from '@/common/mapApi/data'

export default {
	data() {
		return {
			list: [],
			loading: false,
			finished: false,
		}
	},
	methods: {
		onLoad() {
			this.loading = false;
		},
		detail(id) {
			this.$router.push({
				path: '/diningDetail',
				query: {
					// url的参数, 类似get请求的传参
					id: id
				},
			})
		}

	},
	created() {
		this.$store.commit('upData', {type: 'title', value: '餐厅列表'})
		getList({
			location: this.$store.state.locations,
			pageSize: 50
		}).then(res => {
			console.log(res);
			this.list = res.data.rows
		})
	},
	mounted() {

	},
	components: {},
	computed: {},
	watch: {},
}
</script>

<style lang="scss" scoped>
</style>
