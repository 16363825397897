<template>
    <div class="fxTis">
        <img src="@/static/fx.jpg">
    </div>
</template>

<script>
export default {
  created() {
    if(!this.isWeixin()){
        window.location.href = decodeURIComponent(this.GetRequest().fileurl);
    }
  },
  methods: {
    //从浏览器url地址中获取参数对应的值
    GetRequest() {
        var url = location.search;//获取url中"?"符后的字符串
        var theRequest = new Object();
        if (url.indexOf("?")!=-1){  //存在？ 则
            var str = url.substr(1);
            const strs = str.split("&");  //字符串分割
            for(var i=0;i<strs.length;i++){
                theRequest[strs[i].split("=")[0]]=(strs[i].split("=")[1]);
            }
        }
        return theRequest;
    },
    //判断是否是微信浏览器
    isWeixin() {
        var ua = window.navigator.userAgent.toLowerCase(); 
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { 
            return true;
        } else { 
            return false;
        } 
    }
  }
}
</script>

<style scoped>
body{
    background-color: #333333 !important;
}
.fxTis img{
    width: 100%;
    display: inline-block;
}
.fxTis{
    text-align: center;
}
.fxTis button{
    border: 0;
    padding: 6px 14px;
    background-color: #ffffff;
    font-size: 18px;
    border-radius: 4px;
}
</style>
